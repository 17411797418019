import { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const CustomEditorMCE = (props) => {
  const editorRef = useRef(null);
  const {
    editorState,
    height,
    extendToolbar,
    width,
    setEditorState,
    placeholder,
    disabled,
  } = props;

  const [renderEditor, setRenderEditor] = useState(false);

  useEffect(() => {
    setRenderEditor(true);
  }, []);

  return (
    <>
      {renderEditor && (
        <Editor
          value={editorState}
          disabled={disabled}
          // apiKey="s3e2sfphye2z96z3a87gnvpruerys9eqiu83inf2ztvtf2dk"
          apiKey="86aqhgdzhend8s9rfug37837uiaxnyp6f95ubyt1ruwoh3ft"
          //apiKey="cctyfhhe6l6w2ivd8umvbihq4hu6gqe3xkycfsnp0r4tr2hn" //dont delete this key
          tagName="div"
          init={{
            //height: height || 250,
            height: 500,
            width: width || '100%',
            fontFamily: 'Times New Roman, sans-serif',
            menubar: true,
            placeholder: placeholder || '',
            branding: false,
            // ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],

            toolbar: `undo redo | fontsizeselect | bold italic forecolor backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | link | image | media ${
              extendToolbar ? `|| ${extendToolbar}` : ''
            }`,
            extended_valid_elements:
              'iframe[src|frameborder|style|scrolling|class|width|height|name|align]',
            content_style: '.mce-preview-object { width: 100%; border: 0; }',
            directionality: 'ltr',
            images_upload_credentials: true,
            paste_data_images: true,
            convert_urls: false,
            images_upload_handler: function (blobInfo, success, failure) {
              const xhr = new XMLHttpRequest();
              xhr.withCredentials = false;
              //  xhr.open('POST', `${API_BASE_URL}/users-file?type=editor`);
              //  const token = USER_TOKEN.get();
              //  const role = USER_VIEW_MODE.get();

              //  xhr.setRequestHeader('Authorization', token);
              //  xhr.setRequestHeader('Role', role);

              xhr.onload = function () {
                if (xhr.status !== 200) {
                  failure(`HTTP Error: ${xhr.status}`);
                  return;
                }

                const json = JSON.parse(xhr.responseText);
                if (!json || typeof json.location !== 'string') {
                  failure(`Invalid JSON: ${xhr.responseText}`);
                  return;
                }

                success(json.location);
              };

              const formData = new FormData();
              formData.append(
                'users-file',
                blobInfo.blob(),
                blobInfo.filename(),
              );

              xhr.send(formData);
            },
          }}
          onEditorChange={setEditorState}
        />
      )}
    </>
  );
};
export default CustomEditorMCE;
