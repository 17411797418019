import React from 'react';
import ShowStatus from '../../ReusableComponents/ShowStatus';

const ViewEmailHistory = ({ viewData }) => {
  //
  return (
    <div className="mt-3 px-2">
      <div className="d-flex justify-content-between align-items-start ">
        <div className="">
          <label className="fw-bold">From:</label>
          <p>{viewData?.from || '--'}</p>
        </div>
        <div className="">
          <div className="mb-1">{viewData?.createdAt || '--'}</div>
          <div className="text-end">
            <ShowStatus status={viewData['status'] ? 'Sent' : 'Unsent'} />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="fw-bold">To:</label>
        <p>{(viewData?.to || []).join(', ') || '--'}</p>
      </div>
      <div className="form-group">
        <label className="fw-bold">CC:</label>
        <p>{(viewData?.cc || []).join(', ') || '--'}</p>
      </div>
      <div className="form-group">
        <label className="fw-bold">BCC:</label>
        <p>{(viewData?.bcc || []).join(', ') || '--'}</p>
      </div>
      <div className="form-group">
        <label className="fw-bold">Subject:</label>
        <p>{viewData?.subject || '--'}</p>
      </div>
      <div className="form-group">
        <label className="fw-bold">Content:</label>
        <p>
          <div
            className="emailtable"
            dangerouslySetInnerHTML={{ __html: viewData?.content || '--' }}
          ></div>
        </p>
      </div>
    </div>
  );
};

export default ViewEmailHistory;
