import React, { useMemo } from 'react';
import profilePhoto from '../../Assets/profile.jpg';
import stylee from '../../Styles/TimeSheet.module.css';
import { ALPHABET_COLORS } from '../../constant/constant';

const TmTableImage = ({ itm, profilePicture }) => {
  return (
    <>
      <span className="d-flex align-items-center ">
        {profilePicture ? (
          <img
            src={profilePicture}
            className={`rounded-circle ${stylee.imageCircleShape}`}
            alt="profilePhoto"
          />
        ) : (
          <span
            style={{ backgroundColor: ALPHABET_COLORS[itm[0].toLowerCase()] }}
            className={`${stylee.words}`}
          >
            <span className="fw-semibold">{itm[0].toUpperCase()}</span>
          </span>
        )}
        <span className="ms-1 d-block tmTextOverflow "> {itm || '--'}</span>
      </span>
    </>
  );
};

export default TmTableImage;
