import { Row, Col } from 'react-bootstrap';
import { Button, Select, Form } from 'antd';
import style from '../../Styles/Report.module.css';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { Card } from 'reactstrap';
import { YEARS, MONTHS } from '../../constant/constant';

import AntdModalComp from '../ReusableComponents/AntdModalComp';
import SampleModal from '../Reports/SampleModal';
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  Tooltip,
  elements,
  LinearScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { getVariance } from '../../store/reducers/actionPermission';
import { useNavigate } from 'react-router-dom';
import { MdAdb } from 'react-icons/md';
import { FaXmark } from 'react-icons/fa6';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
  elements,
  BarElement,
);

let modalHeader;
let modalData = {};

const EffortVarianceComp = () => {
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();

  const permissionData = useSelector(getVariance);

  const handleButtonPermission = () => {
    if (!permissionData?.checked) {
      navigate('/');
    }
  };

  useEffect(() => {
    if (permissionData) {
      handleButtonPermission();
    }
  }, [permissionData]);

  const [userData, setUserData] = useState([
    { id: 1, value: 'SARAN', label: 'Saran' },
    { id: 2, value: 'GOPAL', label: 'Gopal' },
    { id: 3, value: 'KARTHICK', label: 'Karthick' },
    { id: 4, value: 'SAFIC', label: 'Safic' },
    { id: 5, value: 'AJITH KUMAR', label: 'Ajith Kumar' },
    { id: 6, value: 'ARUN KUMAR', label: 'Arun Kumar' },
  ]);

  const handleChange = (e) => {
    let labelToRemove = e.currentTarget.getAttribute('removehandler');
    setUserData(userData.filter((item) => item.label !== labelToRemove));
  };
  const UserData = [
    {
      name: 'Santhosh',
      estimated: 245,
      actual: 50,
      underEstimation: 280,
      overEstimation: 0,
    },
    {
      name: 'Karthick',
      estimated: 210,
      actual: 280,
      underEstimation: 0,
      overEstimation: 0,
    },
    {
      name: 'Gopal',
      estimated: 225,
      actual: 100,
      underEstimation: 200,
      overEstimation: 0,
    },
    {
      name: 'Safic',
      estimated: 210,
      actual: 280,
      underEstimation: 0,
      overEstimation: 30,
    },
    {
      name: 'Shahin',
      estimated: 218,
      actual: 250,
      underEstimation: 40,
      overEstimation: 0,
    },
    {
      name: 'Arun Kumar',
      estimated: 210,
      actual: 240,
      underEstimation: 40,
      overEstimation: 0,
    },
    {
      name: 'Ajith Kumar',
      estimated: 260,
      actual: 280,
      underEstimation: 30,
      overEstimation: 0,
    },
  ];

  // Prepare data for the chart
  const data = {
    labels: UserData.map((item) => item.name),
    datasets: [
      {
        label: 'Actual',
        data: UserData.map((item) => item.actual),
        backgroundColor: '#4B7E69',
        barThickness: 45,
      },
      {
        label: 'Under Estimation',
        data: UserData.map((item) => item.underEstimation),
        backgroundColor: '#134780', // blue
        barThickness: 45,
      },
      {
        label: 'Over Estimation',
        data: UserData.map((item) => item.overEstimation),
        backgroundColor: 'rgba(255, 26, 104, 1)', // red
        position: 'top',
        borderWidth: 1,
        barThickness: 45,
      },
      {
        label: 'Estimated',
        data: UserData.map((item) => item.estimated),
        backgroundColor: '#F8A013', // orange
        barThickness: 60,
        yAxisID: 'percentage',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      layout: {
        padding: {
          top: 0,
          bottom: 0,
          // left: "200px", // Increase left padding
          // right: "200px", // Increase right padding
        },
        
      },
      datalabels: {
        display: false,
        weight: 'bold',
        color: '#fff',
        font: {
          size: 25,
        },
        formatter: (value) => {
          return value;
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        beginAtZero: false,
        grid: {
          display: false, // Hide grid lines on the x-axis
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: 400,
        title: {
          text: 'Hours',
          align: 'center',
          display: true,
        },
        ticks: {
          stepSize: 50,
          callback: function (value) {
            return value + 'hrs';
          },
        },
      },
      percentage: {
        display: false,
        labels: [''],
        yAxisID: 'percentage',
        position: 'left',
        grid: {
          display: true, // Hide grid lines on the percentage yAxis
        },
      },
    },
  };
  const { Option } = Select;
  const toggleModal = (type, value) => {
    if (value && type === 'Edit') {
      modalData = {
        title: 'new',
        id: value.id,
        status: value.status,
      };
    } else if (type === '') {
      modalData = {};
    }
    modalHeader = <span className="text-nowrap">{`${type} `}</span>;
    setModal(!modal);
  };

  return (
    <div>
      <Row>
        <Col>
          <h4>Effort Variance</h4>
        </Col>
        <Col sm={12}>
          <p className="mb-1">Results</p>
        </Col>
        <Col sm={12}>
          <div className={`d-flex align-items-center py-0 `}>
            {userData.map((items, idx) => (
              <div
                key={idx}
                className="d-flex align-items-center justify-content-start border rounded-pill px-2 me-1"
              >
                <span className="">{items.label}</span>
                <button
                  className="btn p-0 m-0 ps-1"
                  removehandler={items.label}
                  onClick={handleChange}
                >
                  <FaXmark size={15} />
                </button>
              </div>
            ))}

            <div className="ms-auto">
              <Button icon={<UsergroupAddOutlined />} type="dashed">
                Choose Employee
              </Button>
            </div>
          </div>
        </Col>
        <Col sm={12} className='mt-3'>
          <Card>
            <div className="w-100 text-center">
              <Bar
                className=" text-center  "
                data={data}
                options={options}
              ></Bar>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EffortVarianceComp;
