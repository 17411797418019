import axiosClient from './axiosClient';
export const Leave = {
  yearsDropDown: () => {
    const url = `/api/`;
    return axiosClient.post(url, {});
  },
  getHolidayList: (data) => {
    const url = `/api/holiday/get`;
    return axiosClient.post(url, data);
  },
  createHoliday: (data) => {
    const url = `/api/holiday/create`;
    return axiosClient.post(url, data);
  },
  updateHoliday: (data) => {
    const url = `/api/holiday/edit`;
    return axiosClient.post(url, data);
  },
  deleteHoliday: (id) => {
    const url = `/api/holiday/delete`;
    return axiosClient.post(url, { id });
  },

  getPublicHolidayList: (data) => {
    const url = `/api/holiday/getHolidays`;
    return axiosClient.post(url, data);
  },
};
