import React from 'react';
import SettingsComp from '../../Components/Settings/SettingsComp';

const Settings = () => {
  return (
    <div>
      <SettingsComp />
    </div>
  );
};

export default Settings;
