import React, { Fragment } from 'react';
import { GoDot } from 'react-icons/go';
import style from '../../../Styles/ViewReport.module.css';
import { ImInsertTemplate } from 'react-icons/im';

const listActivity = {
  locked_activity: [
    {
      name: '',
      name: '',
    },
  ],
  missed_activity: [],
};

const ViewActivity = ({ viewData }) => {
  return (
    <Fragment>
      {viewData.rejectedArray.length <= 0 &&
        viewData.missedArray.length <= 0 &&
        viewData.lockedArray.length <= 0 && (
          <div className="d-flex justify-content-center align-items-center">
            No Records Found
          </div>
        )}
      <section>
        {/* <span className="fw-semibold">Rejected History</span>
        {viewData.rejectedArray.length <= 0 && (
          <div className="mt-3 d-flex justify-content-center">
            No Rejected History
          </div>
        )} */}
        {viewData.rejectedArray.length > 0 && (
          <span className="fw-semibold">Rejected History</span>
        )}

        {viewData.rejectedArray.length > 0 &&
          viewData.rejectedArray.map((item) => (
            <div className="ps-3 pb-3 ">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ minHeight: 80 }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ width: '35%' }}
                >
                  <span className="me-1">
                    <GoDot color="#ffc27a" size={22} />
                  </span>
                  {item.createdAt}
                </div>

                <div
                  className={`border rounded p-1 ps-2 ${style.card}`}
                  style={{ width: '60%' }}
                >
                  {item.logContent}
                </div>
              </div>
            </div>
          ))}
      </section>
      <section>
        {/* {viewData.missedArray.length <= 0 && (
          <div className="mt-3 d-flex justify-content-center">
            No Missed History
          </div>
        )} */}
        {viewData.missedArray.length > 0 && (
          <span className="fw-semibold">Missed History</span>
        )}
        {viewData.missedArray.length > 0 &&
          viewData.missedArray.map((item) => (
            <div className="ps-3 pb-3 ">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ minHeight: 80 }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ width: '35%' }}
                >
                  <span className="me-1">
                    <GoDot color="#ffc27a" size={22} />
                  </span>
                  {item.createdAt}
                </div>

                <div
                  className={`border rounded p-1 ps-2 ${style.card}`}
                  style={{ width: '60%' }}
                >
                  {item.logContent}
                </div>
              </div>
            </div>
          ))}
      </section>
      <section>
        {/* {viewData.lockedArray.length <= 0 && (
          <div className="mt-3 d-flex justify-content-center">
            No Locked History
          </div>
        )} */}
        {viewData.lockedArray.length > 0 && (
          <span className="fw-semibold">Locked History</span>
        )}
        {viewData.lockedArray.length > 0 &&
          viewData.lockedArray.map((item) => (
            <div className="ps-3 pb-3 ">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ minHeight: 80 }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ width: '35%' }}
                >
                  <span className="me-1">
                    <GoDot color="#ffc27a" size={22} />
                  </span>
                  {item.createdAt}
                </div>

                <div
                  className={`border rounded p-1 ps-2 ${style.card}`}
                  style={{ width: '60%' }}
                >
                  {item.logContent}
                </div>
              </div>
            </div>
          ))}
      </section>
    </Fragment>
  );
};

export default ViewActivity;
