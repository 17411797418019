import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastError } from '../../Components/Toast/ToastComponent';
function Page404() {
  const navigate = useNavigate();

  useEffect(() => {
    toastError('Page Not found');
    navigate('/');
  }, []);
}

export default Page404;
