import { Button, DatePicker, Form, Select } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import style from '../../Styles/Attendance.module.css';

const TmFilter = ({ filterArray, handleFilterChange }) => {
  let initialValues = {};
  const [form] = Form.useForm();
  useEffect(() => {
    initialValueSetter(filterArray);
  }, []);

  const initialValueSetter = (filterArray) => {
    filterArray?.map((item) => {
      initialValues[item.controlName] = item.defaultValue;
    });

    form.setFieldsValue(initialValues);
  };

  const handleReset = () => {
    form.resetFields();
    let newObj = nullRemover() || {};
    handleFilterChange(newObj);
  };

  const nullRemover = () => {
    let afterClear = form.getFieldsValue();
    let newObj = {};
    for (let obj in afterClear) {
      newObj[obj] =
        afterClear[obj] !== null && afterClear[obj] !== undefined
          ? afterClear[obj]
          : '';
    }

    return newObj;
  };

  const disableButton = () => {};

  const internalChange = (e, controlName, type) => {
    let newObj = nullRemover() || {};
    handleFilterChange(newObj, type);
  };

  return (
    <div className="p-1 mb-2 bg-white border">
      <Form form={form}>
        <Row className="m-0 align-items-center">
          <Col sm="auto ps-1 pe-2">
            <b>Filter</b>
          </Col>
          {filterArray.length > 0 &&
            filterArray.map((filtr, index) => (
              <Fragment key={index + 33}>
                {(filtr.input == 'select' || filtr.input == 'date') && (
                  <Col className=" px-0 my-2 my-lg-0 me-1" lg={filtr.col}>
                    <Form.Item
                      name={filtr.controlName}
                      style={{ marginBottom: 0 }}
                    >
                      {filtr.input === 'select' && (
                        <Select
                          showSearch
                          allowClear={filtr.allowClear}
                          mode={filtr.mode}
                          maxTagCount={filtr.maxTagCount}
                          style={{ width: '100%' }}
                          placeholder={filtr.placeholder}
                          optionFilterProp={filtr.optionFilterProp}
                          // defaultValue={filtr.defaultValue}
                          options={filtr.options}
                          onChange={(e) =>
                            internalChange(e, filtr.controlName, filtr.input)
                          }
                        />
                      )}
                      {filtr.input === 'date' && (
                        <DatePicker
                          className={`${style.tmfiterwidthres}`}
                          minDate={filtr.minDate}
                          maxDate={filtr.maxDate}
                          placeholder={filtr.placeholder}
                          // defaultValue={filtr.defaultValue}
                          allowClear={filtr.allowClear}
                          onChange={(e) =>
                            internalChange(e, filtr.controlName, filtr.input)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
                {filtr.input == 'button' && (
                  <Col lg={filtr.col} className={`${filtr.className} px-0`}>
                    <Button
                      className={`   ${style.tmfiterwidthres}`}
                      type={filtr.type}
                      disabled={disableButton()}
                      htmlType={filtr.htmlType}
                      onClick={handleReset}
                    >
                      {/* {filtr.text} */}
                      Clear Filter
                    </Button>
                  </Col>
                )}
              </Fragment>
            ))}
        </Row>
      </Form>
    </div>
  );
};

export default TmFilter;
