import React, { useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Layout, Spin, theme } from 'antd';
import TmHeader from '../Header/TmHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../SideBar/Sidebar';
import collapsedImage from '../../Assets/Timesheet-Fav-icon.png';
import expandedImage from '../../Assets/Openwave Computing_Logo_Large.png';
// import collapsedImage from '../../Assets/Timesheet-Fav-icon.png';
// import expandedImage from '../../Assets/Timesheet-logo-white.png';
import { headerBar } from '../SideBar/sideBarArray';
import style from '../../Styles/Sidebar.module.css';

const { Header, Sider, Content } = Layout;
function TmLayout({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const location = useLocation();
  const headerName = () => {
    let splited = location.pathname.split('/');
    let key = splited[1].replace(/[^a-zA-Z ]/g, '').trim();
    return headerBar[key] || 'Timesheet';
  };

  return (
    <div style={{ position: 'relative', top: 0 }}>
      <div id="tmoverlay" className="d-none">
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      </div>
      <Layout>
        <Sider
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            fontSize: 16,
            transitionDuration: '500ms',
            // boxShadow: '3px 0px 5px -3px #00000029',
          }}
          className={`${style.sideBar} ${
            collapsed ? style.collapse : style.nocollapse
          }`}
          breakpoint="md"
          trigger={null}
          width={246}
          theme="dark"
          collapsedWidth={75}
          collapsible
          collapsed={collapsed}
        >
          <div
            className="demo-logo-vertical text-white w-100 d-flex align-items-center justify-content-center tmPointer"
            style={{
              height: 64,
              position: 'sticky',
              top: 0,
              zIndex: 1,
              background: 'var(--tm-bgcolor1)',
            }}
          >
            <span className="image-container ">
              {collapsed ? (
                <img
                  src={expandedImage}
                  alt="Collapsed"
                  className={`${collapsed ? 'd-block' : 'd-none'} image-logo`}
                  style={
                    {
                      // transitionDuration: '2000ms',
                      // transform: 'rotate(360deg)',
                    }
                  }
                  onClick={() => navigate('/timesheet')}
                />
              ) : (
                <img
                  src={expandedImage}
                  alt="Collapsed"
                  className={`${collapsed ? 'd-none' : 'd-block'} image-logo`}
                  onClick={() => navigate('/timesheet')}
                  style={{ transform: 'rotate(0deg)' }}
                />
              )}
            </span>
          </div>
          <div className={`${style.sideBarScroller}`}>
            <Sidebar />
          </div>
          <div>
            <div
              onClick={() => setCollapsed(!collapsed)}
              className={`${collapsed ? '' : style.noOverlay}`}
            ></div>
          </div>
        </Sider>
        <Layout>
          <Header
            style={{
              display: 'flex',
              background: colorBgContainer,
              alignItems: 'center',
              justifyContent: 'between',
              zIndex: 10,
              padding: '0px 15px 0px 0px',
              lineHeight: '25px',
              boxShadow: '0px 5px 5px -3px #00000029',
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
            <div>
              <h4 className="firstLetter mb-0 primaryColor">{headerName()}</h4>
            </div>
            <div className="ms-auto">
              <TmHeader />
            </div>
          </Header>
          <Content
            style={{
              padding: 12,
              overflowY: 'auto',
              minHeight: 280,
              height: 'calc(100vh - 64px)',
              background: 'var(--tm-bgcolor3)',
              borderRadius: borderRadiusLG,
            }}
          >
            <div className="container-fluid" style={{ padding: '8px' }}>
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default TmLayout;
