import { Children, useEffect, useRef, useState } from 'react';
import { FaRegCircleXmark } from 'react-icons/fa6';
import style from '../../../Styles/TimeSheet.module.css';
import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Radio,
  Space,
  TreeSelect,
} from 'antd';
import { IoIosSwitch, IoMdInformationCircleOutline } from 'react-icons/io';

import { TimePicker, Input, Select } from 'antd';
import { Table } from 'reactstrap';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../../Toast/ToastComponent';
import { TimeSheetApi } from '../../../Api/TimeSheetApi';
import { useAuth } from '../../../Context/AuthContext';
import {
  CONFIRMATIONFULLDAY,
  CONFIRMATIONHOLIDAY,
  CONFIRMATIONWEEKEND,
  DateFormat,
  LEAVETYPE,
  TASK_BREAK_FEILDS,
  TASK_PERMISSIONS,
} from '../../../constant/constant';
import { ReactSortable } from 'react-sortablejs';
import { RxDragHandleDots2 } from 'react-icons/rx';
import {
  dateFormatToDayjs,
  dateFormatToHHmm,
  dayjsToDateFormat,
  getMonthFromDate,
  getYearFromDate,
} from '../../../ReusableFn/reusableFn';
import Mandatory from '../../ReusableComponents/Mandatory';
import { confirmationPopup } from '../../ReusableComponents/DeletePopup';

const items = [
  {
    key: '1',
    label: (
      <div>
        <b>Conversion Time Formats</b>
      </div>
    ),
  },
  {
    key: '2',
    label: (
      <div>
        <span>
          <b>0.25</b> - Fifteen minutes
        </span>{' '}
        <br />
        <span>
          <b>0.50</b> - Thirty minutes
        </span>{' '}
        <br />
        <span>
          <b>0.75</b> - Forty five minutes
        </span>{' '}
        <br />
        <span>
          <b>1.00</b> - One hour
        </span>{' '}
        <br />
        <span>
          <b>2.50</b> - Two hours Thirty minutes
        </span>{' '}
        <br />
        <span>
          <b>4.75</b> - Four hours and forty five minutes
        </span>{' '}
        <br />
      </div>
    ),
  },
  {
    key: '3',
    label: (
      <div>
        <b>
          <Mandatory /> (Permission, Others & Leave) will not be added in total
          hrs
        </b>
      </div>
    ),
  },
];
const ONE_BY_SIXTY = 0.0166666666666667;
const { TextArea } = Input;
const columns = [
  { feildname: '', className: 'col-auto', headerName: '' },
  {
    feildname: 'project_id',
    className: 'col-auto',
    headerName: 'Project Name',
    required: true,
  },
  {
    feildname: 'module_id',
    className: 'col-3',
    headerName: 'Module',
    required: true,
  },

  { feildname: 'task', className: 'col-3', headerName: 'Task', required: true },
  {
    feildname: 'time_spent',
    className: 'col-3',
    headerName: 'Time Spent',
    required: true,
  },
  {
    feildname: 'individual_hours',
    className: 'col-1',
    headerName: '',
    required: false,
  },
  { feildname: 'Action', className: 'col-1', headerName: '', required: false },
];

const FULL_DAY = 'fullDay';
const HALF_DAY = 'halfDay';

const optionsArr = [[], TASK_BREAK_FEILDS, TASK_PERMISSIONS, LEAVETYPE];

const TaskFeilds = [1, 2, 3];

let setStartTime = [
  dayjs().set('hour', 9).set('minute', 30).set('second', 0),
  dayjs().set('hour', 10).set('minute', 30).set('second', 0),
];
const CROSSED_TIME = dayjs()
  .set('hour', 23)
  .set('minute', 59)
  .set('second', 30);
const LEAVE_DURATION = [
  { value: 'halfDay', label: 'Half Day' },
  { value: 'fullDay', label: 'Full Day' },
];

const CTRL_CONST = {
  isDeactivated: false,
  task_type: false,
  customModule: false,
  LeaveType: false,
};

const AddLogBdyDnD = ({ toggleModal, isEdit }) => {
  const check = useRef(null);
  const [log, setLog] = useState([
    {
      project_id: null,
      project_name: null,
      module_id: null,
      module_name: null,
      task: null,
      leave_duration: null,
      billable: false,
      time_spent: setStartTime,
      type: 'working',
      consider_bill: 'bill',
      individual_hours: 1,
    },
  ]);

  let initialValues = {
    date: dayjs(),
    timesheet_data: log,
    total_hours: 1,
    type: 'working',
    isBillable: false,
  };

  const [feildValue, setFeildValue] = useState({
    project: [],
    module: [[]],
    taskFeild: [],
  });
  const [feildCtrl, setFeildCtrl] = useState([CTRL_CONST]);
  const { authState } = useAuth();
  const [form] = Form.useForm();

  const watchType = Form.useWatch('type', form);
  const dateField = Form.useWatch('date', form);

  const watchRef = useRef('working');
  useEffect(() => {
    (async () => {
      await getProjectDropDown();
      if (isEdit?.edit === true) {
        getEditLogData(isEdit?.id);
      }
    })();

    return () => {
      setFeildValue({
        project: [],
        module: [[]],
        taskFeild: [],
      });
      setFeildCtrl([{ ...CTRL_CONST }]);
      setLog([
        {
          project_id: null,
          project_name: null,
          module_id: null,
          module_name: null,
          task: null,
          billable: false,
          time_spent: [],
          individual_hours: 0,
        },
      ]);
    };
  }, [isEdit]);

  const getEditLogData = async (id) => {
    try {
      const response = await TimeSheetApi.getLogData(id);
      const res = response?.data;
      if (res?.success) {
        if (res?.result?.length > 0) {
          setFeildsLog(res?.result[0]);
        } else {
          form.setFieldsValue(initialValues);
        }
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const setFeildsLog = async (logData) => {
    let setData = {
      date: dateFormatToDayjs(logData?.date),
      type: logData?.type === 'semiWorking' ? 'working' : logData?.type,
      total_hours: logData?.total_hours,
      timesheet_data: [],
    };

    if (
      logData?.timesheet_data?.length > 0 &&
      (setData?.type === 'working' || setData?.type === 'leave')
    ) {
      let moduleCall = [];
      let feildCtrls = [];
      let updatedLog = logData?.timesheet_data.map((item, index) => {
        moduleCall.push(callModuleDropDown(item.project_id));
        feildCtrls.push(manageFeildCtrls(item));
        let timeDayLog = item.time_spent.map((dayItm) =>
          dateFormatToHHmm(dayItm),
        );
        handleTaskFeild(index, item?.module_id);
        return { ...item, time_spent: timeDayLog };
      });
      Promise.all([...moduleCall])
        .then((resArr) => {
          setFeildValue((pre) => ({ ...pre, module: resArr }));
          setFeildCtrl(feildCtrls);
          setData['timesheet_data'] = updatedLog;
          form.setFieldsValue(setData);
          disableLeave('Edit');
        })
        .catch((err) => console.log(err));
    } else {
      await resetLogData(logData);
      form.setFieldsValue({ date: dayjs(logData?.date) });
    }
  };

  const setFormListFeilds = (index, name, value = null) => {
    form.setFieldValue(['timesheet_data', index, name], value);
  };
  const setFormFeilds = (name, value = null) => {
    form.setFieldValue(name, value);
  };
  const getFormListFeilds = (index, name) => {
    return form.getFieldValue(['timesheet_data', index, name]);
  };
  const getFormFeilds = (name) => {
    return form.getFieldValue(name);
  };

  const getProjectDropDown = async () => {
    let reqData = { id: authState?.id, userRoleID: authState?.userRoleID };
    try {
      const response = await TimeSheetApi.getProjectDropDown(reqData);
      if (response?.data?.success) {
        const res = response?.data?.result || [];
        let setProjectDisables = res.map((item) => ({
          ...item,
          disabled: false,
        }));
        check.current = [...setProjectDisables];
        setFeildValue((pre) => ({ ...pre, project: [...setProjectDisables] }));
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const convertToTreeModule = (newResObj, projectId, idx) => {
    let getModuleCustom =
      check?.current?.find((item) => item.value === projectId) || {};

    let formTreeData = [
      {
        value: 'module',
        title: 'Module',
        disabled: true,
        children: newResObj?.mainModules || [],
      },
      {
        value: 'draft_module',
        title: 'Draft Module',
        disabled: true,
        children: newResObj?.draftModules || [],
      },
    ];
    let customModule = {
      value: 'add custom',
      disabled: true,
      title: (
        <span className="mt-3">
          <Button
            type="dashed"
            className="w-100"
            icon={<PlusOutlined />}
            onClick={() => addModuleItem(idx)}
          >
            Add Module
          </Button>
        </span>
      ),
    };
    let treeData = [];
    if (getModuleCustom?.customModule == true) {
      treeData = [...formTreeData, customModule];
    } else {
      treeData = [...formTreeData];
    }
    return treeData || [];
  };

  const callModuleDropDown = (projectId) => {
    let reqData = {
      id: authState?.id,
      project_id: projectId,
      userRoleID: authState?.userRoleID,
    };
    // let formTreeData = [];
    return new Promise((resolve, reject) => {
      TimeSheetApi.getModuleDropDown(reqData)
        .then((response) => {
          if (response?.data?.success) {
            const newResObj = response?.data?.result || [];
            // formTreeData = convertToTreeModule(newResObj, projectId, indx);
            resolve(newResObj);
          } else {
            reject(response?.data?.message);
          }
        })
        .catch((messgae) => console.log(messgae));
    });
  };

  const getModuleDropDown = async (projectId, index) => {
    callModuleDropDown(projectId)
      .then((resArr) => {
        let newModuleObj = [...feildValue.module];
        newModuleObj[index] = resArr;
        setFeildValue((pre) => ({
          ...pre,
          module: newModuleObj,
        }));
        let newCtrlField = feildCtrl;
        newCtrlField[index] = CTRL_CONST;
        setFormListFeilds(index, 'module_name');
        setFormListFeilds(index, 'module_id');
        setFeildCtrl([...newCtrlField]);

        let newVal = getFormListFeilds(index, 'individual_hours') || 0;
        let callTotalTimeSpent =
          Boolean(getFormListFeilds(index, 'time_spent')[0]) &&
          Boolean(getFormListFeilds(index, 'time_spent')[1]);
        if (projectId === 2 || projectId === 3) {
          setFormListFeilds(index, 'consider_bill', 'nonbill');
        } else {
          setFormListFeilds(index, 'consider_bill', 'bill');
        }
        disableLeave();

        if (callTotalTimeSpent) {
          sumUpTotalHrs(newVal, index);
        }
        setFormListFeilds(index, 'module_id');
        setFormListFeilds(index, 'task');
        handleTaskFeild(index);
        // }
      })
      .catch((msg) => console.log(msg));
  };

  const convertTimeToOne = (timespentArr) => {
    let milliSec =
      dayjs(timespentArr[1]).valueOf() - dayjs(timespentArr[0]).valueOf();
    let calMints = milliSec / (1000 * 60);
    let mintsToRound =
      calMints * ONE_BY_SIXTY > 0 ? calMints * ONE_BY_SIXTY : 0;
    return mintsToRound || 0;
  };

  const handleTimeSpent = async (timeSpent, index) => {
    if (timeSpent === null || timeSpent.length <= 0) return;

    const mintsToRound = convertTimeToOne(timeSpent);

    const formattedTimeSpent = formatNumber(mintsToRound);

    setFormListFeilds(index, 'individual_hours', formattedTimeSpent);

    sumUpTotalHrs(formattedTimeSpent, index);
  };

  function formatNumber(mintsToRound) {
    const roundedNumber = Number(mintsToRound).toFixed(2);
    return roundedNumber.endsWith('.00')
      ? Math.floor(Number(roundedNumber))
      : roundedNumber;
  }

  const handleAdd = (add) => {
    if (watchType !== 'working') {
      toastInfo('Please mark it as ‘working’ to add a log.');
      return;
    }
    setFeildCtrl((pre) => [...pre, { ...CTRL_CONST }]);
    setFeildValue((pre) => ({ ...pre, module: [...pre.module, []] }));

    let newAdd = {
      project_id: null,
      project_name: null,
      module_id: null,
      module_name: null,
      task: null,
      leave_duration: null,
      billable: false, //used for ckecking and unchecking in approve and reject while approving timesheet
      time_spent: [],
      consider_bill: 'bill',
      individual_hours: 0,
    };
    let timeSpent = getFormFeilds('timesheet_data') || [];
    if (timeSpent?.length === 0) {
      form.setFieldValue('timesheet_data', [newAdd]);
      return;
    }
    let lastLog = timeSpent[timeSpent.length - 1];
    if (lastLog['leave_duration'] === LEAVE_DURATION[1].value) {
      toastInfo('Please mark it as ‘working’ to add a log.');
      return;
    } else {
    }
    form
      .validateFields()
      .then((values) => {
        if (!values?.errorFields?.length) {
          let lastEndTime = lastLog?.time_spent;
          newAdd['time_spent'] = !!lastEndTime[1] ? [lastEndTime[1], null] : [];
          form.setFieldValue('timesheet_data', [...timeSpent, newAdd]);
        }
      })
      .catch((error) => 0);

    return;
  };

  const changeLogType = (e, index) => {
    let timeSpent = getFormFeilds('timesheet_data') || [];
    let setLogType = e == FULL_DAY ? FULL_DAY : 'working';
    setFormFeilds('type', setLogType);
    if (setLogType == FULL_DAY) {
      let setLabel = confirmLabelSetter(setLogType) || {};
      confirmationPopup(async (canDelete) => {
        if (canDelete) {
          let resetFieldCtrl = feildCtrl[index] || {};
          let resetFeildValue = feildValue;
          let newFieldValue = {
            project: resetFeildValue.project || [],
            module: [resetFeildValue.module[index] || []],
            taskFeild: [resetFeildValue.taskFeild[index] || []],
          };
          setFeildCtrl([resetFieldCtrl]);
          setFeildValue({ ...newFieldValue });
          setFormFeilds('timesheet_data', [
            { ...timeSpent[index], time_spent: [], individual_hours: 0 },
          ]);
          sumUpTotalHrs(0, index);
        } else {
          setFormListFeilds(index, 'leave_duration');
          setFormFeilds('type', watchRef.current);
        }
      }, setLabel);
      return;
    } else {
    }
  };

  const disableLeave = (typeCheck = 'add') => {
    let boolVal = false;
    let timesheet_data = form.getFieldValue('timesheet_data');
    let findLeaveInc = timesheet_data.find((item) => item?.project_id == 3);

    if (!!findLeaveInc) {
      boolVal = true;
    } else {
      boolVal = false;
    }
    let projectList = feildValue.project || [];
    if (isEdit?.edit === true && typeCheck == 'Edit') {
      projectList = check.current;
    }
    let newDisabledArr =
      projectList.map((item, idx) =>
        item.value == 3 ? { ...item, disabled: boolVal } : item,
      ) || [];
    setFeildValue((pre) => ({ ...pre, project: newDisabledArr }));
  };

  const calculateIndivHrs = (timeSpent_arr) => {
    return (
      timeSpent_arr.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.individual_hours),
        0,
      ) || 0
    );
  };

  const extractOnlyBillable = (arr) => {
    return arr.filter((item) => item.consider_bill === 'bill') || [];
  };

  const sumUpTotalHrs = (data, indx) => {
    let timeSpent = getFormFeilds('timesheet_data') || [];

    let onlyBillable = extractOnlyBillable(timeSpent);

    let accTotal = 0;
    onlyBillable.forEach((item) => {
      if (Boolean(item?.time_spent[0]) && Boolean(item?.time_spent[1])) {
        let milliSec =
          dayjs(item?.time_spent[1]).valueOf() -
          dayjs(item?.time_spent[0]).valueOf();
        let calMints = milliSec / (1000 * 60);
        let mintsToRound =
          calMints * ONE_BY_SIXTY > 0 ? calMints * ONE_BY_SIXTY : 0;
        accTotal += mintsToRound;
      }
    });

    setFormFeilds('total_hours', Number(accTotal).toFixed(2));
  };

  const handleTaskFeild = async (indx, module_id = 0) => {
    let checkModule;
    if (isEdit.edit === true && module_id !== 0) {
      checkModule = module_id;
    } else if (module_id === 0) {
      checkModule =
        Number(form.getFieldValue(['timesheet_data', indx, 'module_id'])) || 0;
    }
    let newFeildCtrl;
    if (TaskFeilds.includes(checkModule)) {
      let LeaveType = checkModule == 3 ? true : false;
      newFeildCtrl = feildCtrl.map((item, idx) =>
        idx === indx
          ? {
              ...item,
              LeaveType,
              task_type: true,
            }
          : item,
      );
      let newArrTaskFeilds = feildValue.taskFeild || [];
      newArrTaskFeilds[indx] = optionsArr[checkModule] || [];
      setFeildValue((pre) => ({ ...pre, taskFeild: [...newArrTaskFeilds] }));
    } else {
      newFeildCtrl = feildCtrl.map((item, idx) =>
        idx === indx ? { ...item, LeaveType: false, task_type: false } : item,
      );
    }
    setFeildCtrl(newFeildCtrl);
    setFormListFeilds(indx, 'task');
    setFormListFeilds(indx, 'leave_duration');
  };

  const onSubmit = async (data) => {
    if (authState.id == 1 || authState.id == 2) {
      toastInfo('Application Admin cannot create Timesheet.');
      return;
    }

    if (!data?.timesheet_data?.length) {
      toastInfo('Please add log to proceed.');
      return;
    }
    const month = getMonthFromDate(data.date.$d);
    const year = getYearFromDate(data.date.$d);

    let findLeave =
      data?.timesheet_data.find((item) => item?.project_id === 3) || {};

    data?.timesheet_data?.map((item, indx) => {
      item['task_type'] = feildCtrl[indx]?.task_type || false;
      item['customModule'] = feildCtrl[indx]?.customModule || false;
      item['LeaveType'] = feildCtrl[indx]?.LeaveType || false;
      return item;
    });
    let cal = calculatebillable(data?.timesheet_data) || {};
    let reqData = {
      ...data,
      ...cal,
      type:
        findLeave?.leave_duration == FULL_DAY
          ? 'leave'
          : findLeave?.leave_duration == HALF_DAY
          ? 'semiWorking'
          : data?.type,
      month: month,
      year: year,
      user_id: authState?.id,
      total_hours: Number(data?.total_hours),
      date: dayjsToDateFormat(data.date),
    };

    try {
      const response = isEdit.edit
        ? await TimeSheetApi.update({
            ...reqData,
            id: isEdit?.id,
            userRoleID: authState?.userRoleID,
          })
        : await TimeSheetApi.create({
            ...reqData,
            userRoleID: authState?.userRoleID,
          });
      if (response.data.success) {
        toastSuccess(response.data.message);
        modalClose('changes');
      } else {
        toastWarn(response.data.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  // Drag and Drop Handler

  const moveElements = (oldIdx, newIdx, arr) => {
    if (arr?.length < 2) return arr;
    var element = arr[oldIdx];
    arr.splice(oldIdx, 1);
    arr.splice(newIdx, 0, element);
    return arr;
  };

  const onDragDropEnds = (oldIndex, newIndex) => {
    if (oldIndex === newIndex) return;

    let newFeildCrtl = moveElements(oldIndex, newIndex, feildCtrl || []);
    setFeildCtrl((pre) => [...newFeildCrtl]);

    let newModuleArrVal = moveElements(
      oldIndex,
      newIndex,
      feildValue.module || [],
    );

    setFeildValue((pre) => ({
      ...pre,
      module: newModuleArrVal,
    }));
    let timeSpent = getFormFeilds('timesheet_data') || [];
    let newTimeSpent = moveElements(oldIndex, newIndex, timeSpent || []);
    setLog((pre) => [...newTimeSpent]);
    handleTimeSwap(oldIndex, newIndex);
  };

  const handleTimeSwap = (oldIndex, newIndex) => {
    let timeSpent = getFormFeilds('timesheet_data') || [];
    let AfterSwaped = timeSpent.map((item, index) => {
      if (index >= newIndex) {
        return { ...item, time_spent: [], individual_hours: 0 };
      }
      return item;
    });
    form.setFieldValue('timesheet_data', AfterSwaped);
    let calculatedSwap = Number(calculateIndivHrs(AfterSwaped) || 0).toFixed(2);
    setFormFeilds('total_hours', calculatedSwap);
  };
  const setNewList = (newlist) => {};

  const resetLogData = async (resetLogs) => {
    if (resetLogs?.type !== 'working') {
      let time_spent = resetLogs?.timesheet_data || [];
      let feildCtrls = [];
      time_spent.forEach((item) => {
        feildCtrls.push(manageFeildCtrls(item));
      });
      setFeildCtrl(feildCtrls);
      setFormFeilds('type', resetLogs?.type);
      watchRef.current = resetLogs?.type;
      form.setFieldValue('timesheet_data', time_spent);
      form.setFieldValue('total_hours', 0);
    } else if (resetLogs?.type === 'working') {
      form.setFieldsValue(initialValues);
    }
  };

  const handleLeaves = async (e) => {
    if (e.target.value === 'working') {
      form.setFieldValue('timesheet_data', log);
      setFeildCtrl([CTRL_CONST]);
      setFeildValue((pre) => ({ ...pre, module: [[]], taskFeild: [] }));
      watchRef.current = 'working';
      disableLeave();
      return;
    }

    let setLabel = confirmLabelSetter(e.target.value) || {};
    confirmationPopup(async (canDelete) => {
      if (canDelete) {
        let resetLogs = await getLeaveConfirmation();
        await resetLogData(resetLogs);
      } else {
        setFormFeilds('type', '');
      }
    }, setLabel);
  };

  const confirmLabelSetter = (value) => {
    let setLabel = {};
    switch (value) {
      case 'weekend':
        setLabel = CONFIRMATIONWEEKEND;
        break;
      case 'publicHoliday':
        setLabel = CONFIRMATIONHOLIDAY;
        break;
      case FULL_DAY:
        setLabel = CONFIRMATIONFULLDAY;
        break;
      default:
        setLabel = {};
        break;
    }
    return setLabel || {};
  };

  const manageFeildCtrls = (item) => ({
    task_type: item?.task_type || false,
    customModule: item?.customModule || false,
    isDeactivated: item?.isDeactivated || false,
    LeaveType: item?.LeaveType || false,
  });

  const limitCrossedTime = (timeSpent, indx) => {
    setFormListFeilds(indx, 'time_spent', [timeSpent[0], CROSSED_TIME]);
    const mintsToRound = convertTimeToOne([timeSpent[0], CROSSED_TIME]);
    const formattedTimeSpent = formatNumber(mintsToRound);
    setFormListFeilds(indx, 'individual_hours', formattedTimeSpent);
  };

  const handleHrs = (e, indx) => {
    let timeSpent = getFormFeilds('timesheet_data') || [];
    let hrsEntered = e?.target?.value;
    setFormListFeilds(indx, 'individual_hours', hrsEntered);
    let newValconverted = Number(hrsEntered / ONE_BY_SIXTY);
    newValconverted = Math.round(newValconverted);
    const preEndTime = timeSpent[indx - 1];
    const current_time_spent = timeSpent[indx];

    if (current_time_spent?.time_spent?.length == 2 && hrsEntered >= 0) {
      let newEndTime = dayjs(current_time_spent?.time_spent[0]).add(
        newValconverted,
        'minutes',
      );
      const timeSpentData = [current_time_spent?.time_spent[0], newEndTime];
      const getSameDate =
        dayjs(current_time_spent?.time_spent[0]).format(DateFormat) ===
        dayjs(newEndTime).format(DateFormat);

      if (getSameDate) {
        setFormListFeilds(indx, 'time_spent', timeSpentData);
      } else {
        limitCrossedTime(timeSpentData, indx);
      }
    } else if (preEndTime?.time_spent?.length == 2 && hrsEntered >= 0) {
      let newEndTime = dayjs(preEndTime?.time_spent[1]).add(
        newValconverted,
        'minutes',
      );
      const timeSpentData = [preEndTime?.time_spent[1], newEndTime];
      const getSameDate =
        dayjs(preEndTime?.time_spent[1]).format(DateFormat) ===
        dayjs(newEndTime).format(DateFormat);
      if (getSameDate) {
        setFormListFeilds(indx, 'time_spent', timeSpentData);
      } else {
        limitCrossedTime(timeSpentData, indx);
      }
    } else if (current_time_spent?.time_spent?.length !== 2 && hrsEntered > 0) {
      toastWarn('Enter Time Spent');
    }
    console.log('sumUpTotalHrs is called');
    sumUpTotalHrs(hrsEntered, indx);
  };

  const handleChange = (e, index) => {
    let value = e.target.value;
    let inputString = value;

    let sanitized = inputString.replace(/[^0-9.]/g, '');
    const firstDot = sanitized.indexOf('.');
    if (firstDot !== -1) {
      sanitized =
        sanitized.slice(0, firstDot + 1) +
        sanitized.slice(firstDot + 1).replace(/\./g, '');
    }
    if (sanitized.indexOf('.') !== -1) {
      let parts = sanitized.split('.');
      sanitized = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (parseFloat(sanitized) > 24.0) {
      sanitized = '0';
    }
    if (parseFloat(sanitized) < 0) {
      sanitized = '0';
    }

    e.target.value = sanitized;

    handleHrs(e, index);

    return;
  };

  const getLeaveConfirmation = async () => {
    let checkDate = form.getFieldsValue() || {};
    let reqData = {
      date: dayjsToDateFormat(checkDate?.date),
      type: checkDate?.type,
    };
    try {
      let response = await TimeSheetApi.checkHoliday(reqData);
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.result;
      } else {
        toastWarn(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
      return {};
    }
  };

  const handleRemove = (remove, name) => {
    if (watchType !== 'working') {
      toastInfo('Please mark it as ‘working’ to Remove log.');
      return;
    }
    remove(name);
    disableLeave();
    let setCtrlFeild = removevalues(name, feildCtrl);
    setFeildCtrl((pre) => [...setCtrlFeild]);

    let setvalueFeild = removevalues(name, feildValue.module);
    setFeildValue((pre) => ({ ...pre, module: [...setvalueFeild] }));

    sumUpTotalHrs(0, name);
  };

  const removevalues = (indx, arr) => {
    arr.splice(indx, 1);
    return arr || [];
  };

  const modalClose = (type = 'nochange') => {
    toggleModal(type);
  };

  const validateTimeSpent = (rule, value, cb) => {
    let newSetKey = true;
    if (!value?.length) {
      newSetKey = false;
    } else {
      for (let val of value) {
        if (Boolean(val)) {
          newSetKey = true;
        } else {
          newSetKey = false;
          break;
        }
      }
    }

    if (!newSetKey) {
      return Promise.reject(new Error(''));
    } else {
      return Promise.resolve();
    }
  };

  const validateIndiviHr = (rule, value, cb) => {
    if (value === '') {
      return Promise.reject(new Error(''));
    } else {
      return Promise.resolve();
    }
  };

  const inculdeForCalc = (e, index) => {
    let getItsCtrl = feildCtrl[index];
    if (getItsCtrl.task_type === false) return;
    let time_spent = getFormFeilds('timesheet_data') || [];
    let specObj = time_spent[index] || {};
    let setBill =
      specObj.task === TASK_PERMISSIONS[0].value ? 'nonbill' : 'bill';
    time_spent = time_spent.map((item, idx) => {
      if (idx === index) return { ...item, consider_bill: setBill };
      return item;
    });
    form.setFieldValue('timesheet_data', time_spent);
  };

  const resetDate = () => {
    if (['weekend', 'publicHoliday'].includes(watchType)) {
      setFormFeilds('timesheet_data', [
        {
          project_id: null,
          project_name: null,
          module_id: null,
          module_name: null,
          task: null,
          leave_duration: null,
          billable: false,
          time_spent: [],
          type: 'working',
          consider_bill: 'bill',
          individual_hours: 0,
        },
      ]);
      setFormFeilds('type', 'working');
      setFeildCtrl([{ ...CTRL_CONST }]);
      setFeildValue((pre) => ({
        project: [...pre.project],
        module: [[]],
        taskFeild: [],
      }));
      setLog([
        {
          project_id: null,
          project_name: null,
          module_id: null,
          module_name: null,
          task: null,
          leave_duration: null,
          billable: false,
          time_spent: setStartTime,
          type: 'working',
          consider_bill: 'bill',
          individual_hours: 1,
        },
      ]);
      sumUpTotalHrs(0, 0);
    }
  };

  const calculatebillable = (item) => {
    let bill = 0,
      nonbill = 0;
    item.forEach((itm, indx) => {
      if (itm.billable === true && itm.consider_bill === 'bill') {
        // bill += Number(itm.individual_hours);
        bill += convertTimeToOne(itm?.time_spent);
        return;
      } else if (
        itm.billable === false &&
        itm.consider_bill === 'bill' &&
        itm.project_id !== 1
      ) {
        // nonbill += Number(itm.individual_hours);
        nonbill += convertTimeToOne(itm?.time_spent);
      }
    });
    return {
      total_nonBillable_hours: Number(nonbill.toFixed(2)) || 0,
      total_billable_hours: Number(bill.toFixed(2)) || 0,
    };
  };

  const switchModules = (index) => {
    let newCtrlField = feildCtrl;
    let feildctrlIdx = newCtrlField[index];
    newCtrlField[index] = { ...feildctrlIdx, customModule: false };
    setFormListFeilds(index, 'module_name');
    setFormListFeilds(index, 'module_id');
    setFormListFeilds(index, 'task');
    setFeildCtrl(() => [...newCtrlField]);
  };
  const addCustomModule = (menu, idx) => {
    let projectId = getFormListFeilds(idx, 'project_id') || 0;
    let getModuleCustom =
      feildValue.project.find((item) => item.value === projectId) || {};

    return (
      <>
        {menu}
        {getModuleCustom['customModule'] === true && (
          <>
            <Divider style={{ margin: '8px 0' }} />
            <div className="w-100 text-center">
              <Button
                type="dashed"
                className="w-100"
                icon={<PlusOutlined />}
                onClick={() => addModuleItem(idx)}
              >
                Add Module
              </Button>
            </div>
          </>
        )}
      </>
    );
  };

  const addModuleItem = (idx) => {
    let newCtrlField = feildCtrl;
    let feildctrlIdx = newCtrlField[idx];
    newCtrlField[idx] = { ...feildctrlIdx, customModule: true };
    setFormListFeilds(idx, 'module_id');
    setFormListFeilds(idx, 'task');
    setFormListFeilds(idx, 'module_name');
    setFeildCtrl((pre) => [...newCtrlField]);
  };

  return (
    <>
      <div>
        <Form
          initialValues={initialValues}
          form={form}
          onFinish={onSubmit}
          layout="vertical"
        >
          <div className="row mx-0 align-items-center mb-3">
            <div className="col-auto text-nowrap fs-5 fw-semibold ps-0">
              <>{isEdit?.title} Time Log</>
            </div>
            <div className="col-md-7 ps-0 d-flex">
              <Form.Item
                name={'date'}
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: '' }]}
              >
                <DatePicker
                  inputReadOnly={true}
                  format={DateFormat}
                  onChange={resetDate}
                  maxDate={dayjs()}
                  locale={'en-in'}
                />
              </Form.Item>
              <div>
                <Form.Item
                  name="type"
                  style={{ marginBottom: 0, marginLeft: 8 }}
                  // rules={[{ required: true, message: "" }]}
                >
                  <Radio.Group onChange={handleLeaves}>
                    <Radio value="working">Working</Radio>
                    <Radio value="weekend">Weekend</Radio>
                    <Radio value="publicHoliday">Public Holiday</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
            <div className="col-auto ms-auto pe-1" onClick={modalClose}>
              <Button className="p-0" icon={<CloseOutlined />}></Button>
            </div>
          </div>

          <div className={style.addLogTable}>
            <Table responsive borderless className="">
              <thead>
                <tr>
                  {columns.map((item, index) => (
                    <th key={index}>
                      {item.headerName}
                      {item?.required ? <Mandatory /> : ''}
                    </th>
                  ))}
                </tr>
              </thead>

              <ReactSortable
                tag={'tbody'}
                list={log}
                setList={(newlist) => setNewList(newlist)}
                ghostClass="dropArea"
                handle=".dragHandle"
                filter=".ignoreDrag"
                preventOnFilter={true}
                className="grid-container"
                onEnd={({ oldIndex, newIndex }) =>
                  onDragDropEnds(oldIndex, newIndex)
                }
              >
                <Form.List name="timesheet_data">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <tr key={key}>
                          <td className="col-auto dragHandle">
                            <span style={{ cursor: 'move' }}>
                              <RxDragHandleDots2 size={18} />
                            </span>
                          </td>
                          <td className="col-2 p-1">
                            {feildCtrl[index]?.isDeactivated && (
                              <Form.Item
                                name={[name, 'project_name']}
                                style={{ marginBottom: 0 }}
                              >
                                <Input
                                  readOnly
                                  disabled
                                  placeholder="Enter Project"
                                />
                              </Form.Item>
                            )}
                            {!feildCtrl[index]?.isDeactivated && (
                              <Form.Item
                                name={[name, 'project_id']}
                                style={{ marginBottom: 0 }}
                                rules={[{ required: true, message: '' }]}
                              >
                                <Select
                                  options={feildValue.project}
                                  type="select"
                                  optionFilterProp="label"
                                  placeholder="Select Project"
                                  showSearch
                                  onChange={(e) => getModuleDropDown(e, index)}
                                />
                              </Form.Item>
                            )}
                          </td>
                          <td className="col-2 p-1">
                            {feildCtrl[index]?.isDeactivated && (
                              <Form.Item
                                name={[name, 'module_name']}
                                style={{ marginBottom: 0 }}
                              >
                                <Input
                                  readOnly
                                  disabled={true}
                                  placeholder="Enter Module"
                                />
                              </Form.Item>
                            )}
                            {!feildCtrl[index]?.customModule &&
                              !feildCtrl[index]?.isDeactivated && (
                                <Form.Item
                                  name={[name, 'module_id']}
                                  style={{ marginBottom: 0 }}
                                  rules={[{ required: true, message: '' }]}
                                >
                                  <Select
                                    options={feildValue.module[index]}
                                    type="select"
                                    dropdownRender={(menu) =>
                                      addCustomModule(menu, index)
                                    }
                                    optionFilterProp="label"
                                    placeholder="Select Module"
                                    showSearch
                                    onChange={() => handleTaskFeild(index)}
                                  />

                                  {/* <TreeSelect
                                    showSearch
                                    treeNodeFilterProp="title"
                                    placeholder="Select Module"
                                    popupMatchSelectWidth={false}
                                    onChange={() => handleTaskFeild(index)}
                                    treeData={feildValue.module[index]}
                                  /> */}
                                </Form.Item>
                              )}
                            {!feildCtrl[index]?.isDeactivated &&
                              feildCtrl[index]?.customModule && (
                                <Form.Item
                                  name={[name, 'module_name']}
                                  style={{ marginBottom: 0 }}
                                  rules={[{ required: true, message: '' }]}
                                >
                                  <Input
                                    maxLength={150}
                                    addonAfter={
                                      <IoIosSwitch
                                        className="tmPointer"
                                        title="Switch to Select"
                                        onClick={() => switchModules(index)}
                                      />
                                    }
                                    placeholder="Module Name"
                                    onChange={(e) => {
                                      setFormListFeilds(
                                        index,
                                        'module_name',
                                        e?.target?.value?.replace(/^\s+/, '') ??
                                          '',
                                      );
                                    }}
                                  />
                                </Form.Item>
                              )}
                          </td>
                          <td className="col-4 p-1">
                            {feildCtrl[index]?.LeaveType && (
                              <div className="d-flex justify-content-between">
                                <Form.Item
                                  name={[name, 'leave_duration']}
                                  style={{
                                    marginBottom: 0,
                                    width: '50%',
                                    marginRight: 8,
                                  }}
                                  rules={[{ required: true, message: '' }]}
                                >
                                  {feildCtrl[index]?.task_type && (
                                    <Select
                                      onChange={(e) => changeLogType(e, index)}
                                      options={LEAVE_DURATION}
                                      type="select"
                                      disabled={
                                        !!feildCtrl[index]?.isDeactivated
                                      }
                                      placeholder="Leave Duration"
                                      showSearch
                                    />
                                  )}
                                </Form.Item>
                                <Form.Item
                                  name={[name, 'task']}
                                  style={{ marginBottom: 0, width: '50%' }}
                                  rules={[{ required: true, message: '' }]}
                                >
                                  {feildCtrl[index]?.task_type && (
                                    <Select
                                      // onChange={(e) => inculdeForCalc(e, index)}
                                      options={feildValue.taskFeild[index]}
                                      type="select"
                                      placeholder="Leave Type"
                                      showSearch
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            )}
                            {!feildCtrl[index]?.LeaveType && (
                              <Form.Item
                                name={[name, 'task']}
                                style={{ marginBottom: 0 }}
                                rules={[{ required: true, message: '' }]}
                              >
                                {!feildCtrl[index]?.task_type && (
                                  <TextArea
                                    rows={1}
                                    disabled={
                                      watchType !== 'working' &&
                                      !!feildCtrl[index]?.isDeactivated
                                    }
                                    onChange={(e) => {
                                      setFormListFeilds(
                                        index,
                                        'task',
                                        e?.target?.value?.replace(/^\s+/, '') ??
                                          '',
                                      );
                                    }}
                                    placeholder="Enter the task"
                                  />
                                )}
                                {feildCtrl[index]?.task_type && (
                                  <Select
                                    // onChange={(e) => inculdeForCalc(e, index)}
                                    options={feildValue.taskFeild[index]}
                                    type="select"
                                    disabled={!!feildCtrl[index]?.isDeactivated}
                                    placeholder="Select Task"
                                    showSearch
                                  />
                                )}
                              </Form.Item>
                            )}
                          </td>

                          <td className="col-3 p-1">
                            {watchType === 'working' && (
                              <Form.Item
                                name={[name, 'time_spent']}
                                style={{ marginBottom: 0 }}
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                    validator: validateTimeSpent,
                                  },
                                ]}
                              >
                                <TimePicker.RangePicker
                                  format="h:mm A"
                                  allowClear={false}
                                  // onCalendarChange={(e, S) => console.log(e, S)}
                                  onChange={(e) => handleTimeSpent(e, index)}
                                  style={{
                                    marginBottom: 0,
                                    width: '100%',
                                  }}
                                  autoFocus
                                  needConfirm={false}
                                  // disabledTime={disabledRangeTime}
                                />
                              </Form.Item>
                            )}
                            {!(watchType === 'working') && (
                              <Form.Item
                                name={[name, 'time_spents']}
                                style={{ marginBottom: 0 }}
                              >
                                <TimePicker.RangePicker
                                  format="h:mm A"
                                  allowClear={false}
                                  allowEmpty
                                  disabled={true}
                                  style={{
                                    marginBottom: 0,
                                    width: '100%',
                                  }}
                                />
                              </Form.Item>
                            )}
                          </td>

                          <td className="col-1 p-1">
                            <Form.Item
                              name={[name, 'individual_hours']}
                              style={{ marginBottom: 0 }}
                              rules={[
                                {
                                  required:
                                    watchType == 'working' ? true : false,
                                  message: '',
                                  validator: validateIndiviHr,
                                },
                              ]}
                            >
                              <Input
                                // type="number"
                                // step={0.01}
                                disabled={!(watchType === 'working')}
                                // step={0.016666666666}
                                className={`text-end ${style.antdNum}`}
                                min={0}
                                max={23.99}
                                placeholder="Hrs"
                                onChange={(value) => handleChange(value, index)}
                              />
                            </Form.Item>
                          </td>

                          <td
                            className="col-auto p-1 text-center align-middle"
                            style={{ minWidth: '30px' }}
                          >
                            <FaRegCircleXmark
                              className="text-danger tmPointer"
                              onClick={() => handleRemove(remove, name)}
                              size={16}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </Form.List>
                <tr>
                  <td colSpan={100} className="p-1">
                    <div className="d-flex justify-content-between align-items-center p-0">
                      <div className="col-5">
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button
                            type="dashed"
                            htmlType="button"
                            onClick={() => handleAdd('add')}
                            icon={<PlusOutlined />}
                          >
                            Add Log
                          </Button>
                        </Form.Item>
                      </div>
                      <div className="col-3">
                        <b>
                          <div
                            className="d-flex align-items-center justify-content-between header_bgcolor "
                            style={{ height: '35px' }}
                          >
                            <span className="px-1 ps-2">
                              Total Hours &nbsp; :
                            </span>
                            <span className="px-1 pe-2 me-4">
                              <Form.Item
                                name={'total_hours'}
                                style={{
                                  marginBottom: 0,
                                  border: 0,
                                  maxWidth: 150,
                                }}
                              >
                                <Input
                                  variant="borderless"
                                  readOnly
                                  className={`text-end fw-bold fs-5`}
                                  min={0}
                                  onFocus={(e) => e.preventDefault()}
                                  placeholder="Hrs"
                                />
                              </Form.Item>

                              {/* &nbsp;Hrs */}
                            </span>
                          </div>
                        </b>
                      </div>
                    </div>
                  </td>
                </tr>
              </ReactSortable>
            </Table>
          </div>
          <div className="text-end mt-3">
            <span className="supported_text">
              <Dropdown menu={{ items }} placement="topLeft" arrow>
                <button className="btn m-0 p-0 border-none" type="button">
                  <IoMdInformationCircleOutline size={16} />
                  <small>&nbsp;Conversion Formats</small>
                </button>
              </Dropdown>
            </span>
          </div>
          <div className="antdBtn text-center">
            <Button type="default" htmlType="button" onClick={modalClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {isEdit.edit ? 'Update' : 'Save'}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddLogBdyDnD;
