import AddUser from "../../Components/UserManagement/AddUserFormNew";
function AddUserPage() {
  return (
    <>
      <AddUser />
    </>
  );
}

export default AddUserPage;
