export const setAuthInfo = (data) => {
  localStorage.setItem('authInfo', data);
};

export const getAuthInfo = () => {
  let authInfo =
    localStorage.getItem('authInfo') !== null &&
    localStorage.getItem('authInfo') !== ''
      ? localStorage.getItem('authInfo')
      : { username: '' };

  return authInfo;
};

export const removeAuthInfo = () => {
  localStorage.removeItem('authInfo');
  localStorage.removeItem('sessionTime');
  localStorage.removeItem('sessionSettings');
  window.location.reload();
};

export const setSessionTime = () => {
  localStorage.setItem('sessionTime', btoa(new Date().toISOString()));
};

export const getSessionTime = () => {
  let sessionTime =
    localStorage.getItem('sessionTime') !== null &&
    localStorage.getItem('sessionTime') !== ''
      ? localStorage.getItem('sessionTime')
      : '';

  return sessionTime;
};
export const setSessionSettings = (obj) => {
  localStorage.setItem('sessionSettings', btoa(obj));
};

export const getSessionSettings = () => {
  let sessionSettings =
    localStorage.getItem('sessionSettings') !== null &&
    localStorage.getItem('sessionSettings') !== ''
      ? localStorage.getItem('sessionSettings')
      : '';

  return sessionSettings;
};
