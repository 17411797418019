import React, { useEffect, useState, useRef } from 'react';
import { Button, Checkbox, Form } from 'antd';
import { Row, Col, Table } from 'reactstrap';
import { Radio } from 'antd';
import { FaLock } from 'react-icons/fa6';
import style from '../../../Styles/TimeSheet.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getSettings,
  getUsersTimesheet,
} from '../../../store/reducers/actionPermission';

import { PERMISSION_VALUE } from '../../../constant/constant';
import AntdModalComp from '../../ReusableComponents/AntdModalComp';
import SampleModal from '../../Reports/SampleModal';
import dayjs from 'dayjs';
const LEAVE_LOG_STATUS = ['fullDay', 'halfDay'];
const ONE_BY_SIXTY = 0.0166666666666667;
const HandleBadges = ({ item, approvalStatus, multiStatus }) => (
  <>
    <span>
      <span
        className={`me-2 ${
          approvalStatus === null
            ? ''
            : approvalStatus
            ? 'statusSuccess'
            : 'text-danger'
        }`}
      >
        <b className={`${style.sizeforfont}`}>{item.date}</b>
      </span>
      {!item.isLocked && (
        <span>
          <span
            className={`me-md-2 ${
              approvalStatus === null
                ? ''
                : approvalStatus
                ? 'statusSuccess'
                : 'text-danger'
            }`}
          >
            <b className={`${style.sizeforfont}`}>{item.day}</b>
          </span>
          <span
            className={`alert ms-2 ms-md-0 ${
              approvalStatus === null
                ? 'alert-warning'
                : approvalStatus
                ? 'alert-success'
                : 'alert-danger'
            } mb-0 py-0 px-2 rounded-pill`}
          >
            {`${
              approvalStatus === null
                ? 'Pending'
                : approvalStatus
                ? 'Approved'
                : 'Rejected'
            }`}
          </span>
          {multiStatus && (
            <>
              {' '}
              <span className="m-2 d-none d-md-inline ">
                <small>
                  <i>{multiStatus} by First Approver</i>
                </small>
              </span>
              <div className="d-md-none ">
                <small>
                  <i>{multiStatus} by First Approver</i>
                </small>
              </div>
            </>
          )}
        </span>
      )}
    </span>
  </>
);

let modalHeader = {};
let modalData = {};
function UserTimeSheetAccrdn({
  timeLog,
  handleClick,
  getAttendanceReport,
  handleUpdate,
}) {
  const navigate = useNavigate();
  const { edit_id } = useParams();
  const [modal, setModal] = useState(false);

  const debounceTimeoutRef = useRef(null);

  const [timeLogArr, setTimeLogArr] = useState([]);
  let userData = JSON.parse(atob(edit_id));

  const SettingsData = useSelector(getSettings);

  const permissionsData = useSelector(getUsersTimesheet);

  const [buttonPermission, setButtonPermissions] = useState({});

  const handleButtonPermission = () => {
    if (!permissionsData?.checked) {
      navigate('/');
    }
    for (const element of permissionsData?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  // const getUserTimeLog = () => {};
  useEffect(() => {
    setTimeLogArr(timeLog);
    getAttendanceReport();
    if (permissionsData) {
      handleButtonPermission();
    }
  }, [timeLog]);

  const convertTimeToOne = (timespentArr) => {
    let milliSec =
      dayjs(timespentArr[1]).valueOf() - dayjs(timespentArr[0]).valueOf();
    let calMints = milliSec / (1000 * 60);
    let mintsToRound =
      calMints * ONE_BY_SIXTY > 0 ? calMints * ONE_BY_SIXTY : 0;
    return mintsToRound || 0;
  };

  const calculatebillable = (item) => {
    let bill = 0,
      nonbill = 0;
    item.forEach((itm, indx) => {
      if (itm.billable === true && itm.consider_bill === 'bill') {
        // bill += Number(itm.individual_hours);
        bill += convertTimeToOne(itm?.time_spent);
        return;
      } else if (
        itm.billable === false &&
        itm.consider_bill === 'bill' &&
        itm.project_id !== 1
      ) {
        // nonbill += Number(itm.individual_hours);
        nonbill += convertTimeToOne(itm?.time_spent);
      }
    });
    return {
      total_nonBillable_hours: Number(nonbill.toFixed(2)) || 0,
      total_billable_hours: Number(bill.toFixed(2)) || 0,
    };
  };

  const removeNonBill = (arr) => {
    return arr.filter((item) => item.consider_bill === 'bill');
  };

  const checkBillableAll = (items) => {
    let count = 0;
    let onlyBillArr = removeNonBill(items) || [];
    onlyBillArr.forEach((item) => {
      if (item?.billable) {
        ++count;
      }
    });
    return onlyBillArr?.length === count;
  };

  const billableUpdate = async (logData) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      let approverTypeArray = [
        'is_approved',
        'second_approvers_status',
        'first_approvers_status',
      ];

      for (const key of approverTypeArray) {
        if (logData[key] == 'null') {
          logData[key] = null;
        }
      }

      handleUpdate(logData, 'NoPopUp');
    }, 800);
  }; 
  const handleBillable = async (e, arrIndx, tableIndx) => {
    let checkedState = e.target.checked;
    let newTimeLog = timeLogArr.map((item, index) => {
      if (index === arrIndx) {
        let senData = item?.timesheet_data?.map((timeData, indx) => {
          if (indx === tableIndx) {
            return { ...timeData, billable: checkedState };
          }
          return timeData;
        });
        let cal = calculatebillable(senData) || {};
        let newSpreditm = {
          ...item,
          ...cal,
          timesheet_data: senData,
          isBillable: checkBillableAll(senData),
          // first_approvers_status:item?.first_approvers_status ? 'null' : item.first_approvers_status,
          // second_approvers_status:item?.second_approvers_status ? 'null' : item.second_approvers_status,
        };
        // if (userData?.isFirstApprover === true) {
        //   newSpreditm['first_approvers_status'] = 'null';
        //   newSpreditm['is_approved'] = 'null';
        // } else if (userData?.isSecondApprover === true) {
        //   newSpreditm['second_approvers_status'] = 'null';
        //   newSpreditm['is_approved'] = 'null';
        // }

        billableUpdate(newSpreditm);

        return {
          ...newSpreditm,
        };
      }
      return item;
    });

    setTimeLogArr(newTimeLog);
  };
  const handleAllBillable = (e, arrIndx, type) => {
    let checkedState = e.target.checked;
    let newTimeLog = timeLogArr.map((item, index) => {
      if (index === arrIndx) {
        let senData = item?.timesheet_data?.map((timeData, indx) => {
          if (timeData.consider_bill === 'nonbill' || timeData.project_id === 1)
            return timeData;
          return {
            ...timeData,
            billable: checkedState,
          };
        });
        let cal = calculatebillable(senData) || {};
        let newSpreditm = {
          ...item,
          ...cal,
          isBillable: checkedState,
          timesheet_data: senData,
        };
        // if (userData?.isFirstApprover === true) {
        //   newSpreditm['first_approvers_status'] = 'null';
        //   newSpreditm['is_approved'] = 'null';
        // } else if (userData?.isSecondApprover === false) {
        //   newSpreditm['second_approvers_status'] = 'null';
        //   newSpreditm['is_approved'] = 'null';
        // }

        billableUpdate(newSpreditm);

        return newSpreditm;
      }
      return item;
    });
    setTimeLogArr(newTimeLog);
  };

  // const handleApprove = (e, arrIndx) => {
  //   debugger;
  //   let checkedState = e.target.value;
  //   let arrpoveitem = {};

  //   let newLogItem = new Promise((resolve, reject) => {
  //     timeLogArr.map(async (item, index) => {
  //       if (index === arrIndx) {
  //         let senData = item?.timesheet_data?.map((timeData, indx) => {
  //           if (!checkedState) {
  //             return { ...timeData, billable: checkedState };
  //           }
  //           return timeData;
  //         });
  //         let cal = calculatebillable(senData) || {};
  //         arrpoveitem = {
  //           ...item,
  //           ...cal,
  //           total_nonBillable_hours: !checkedState
  //             ? 0
  //             : item['total_nonBillable_hours'],
  //           timesheet_data: senData,
  //           isBillable: checkedState ? item['isBillable'] : checkedState,
  //           first_approvers_status: item['first_approvers_status']
  //             ? checkedState
  //             : item['first_approvers_status'],
  //           second_approvers_status: item['second_approvers_status']
  //             ? checkedState
  //             : item['second_approvers_status'],
  //           is_approved: checkedState,
  //         };
  //         resolve(arrpoveitem);
  //         return arrpoveitem;
  //       }
  //       return item;
  //     });
  //   });
  //   newLogItem
  //     .then((data) => {
  //       handleClick('update', arrpoveitem);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const getApprovalStatus = (item, index) => {
    if (
      (item.first_approvers_status === true && item.is_approved === true) ||
      (item.second_approvers_status === true && item.is_approved === true)
    ) {
      return { approvalStatus: true };
    } else if (
      item.first_approvers_status === true &&
      item.is_approved === 'null'
    ) {
      return { approvalStatus: null, multiStatus: 'Approved' };
    } else if (
      item.first_approvers_status === false &&
      item.is_approved === 'null'
    ) {
      return { approvalStatus: false, multiStatus: 'Rejected' };
    } else if (
      item.first_approvers_status === 'null' &&
      userData?.isSecondApprover == true
    ) {
      return { approvalStatus: null, multiStatus: 'Pending' };
    } else if (
      (item.first_approvers_status === false && item.is_approved === false) ||
      (item.second_approvers_status === false && item.is_approved === false)
    ) {
      return { approvalStatus: false };
    } else {
      return { approvalStatus: null };
    }
  };

  const WEEKENDHOLIDAY = ['weekend', 'publicHoliday'];
  const LEAVESTATUS = ['leave'];
  const SHOW_MODIFIED = ['semiWorking', 'working'];

  const colorSetter = (item, logItem) => {
    let setColor = '';
    if (WEEKENDHOLIDAY.includes(item.type)) {
      setColor = 'holidayGreen';
    } else if (LEAVESTATUS.includes(item.type)) {
      setColor = 'leaveRed';
    } else if (logItem.project_id === PERMISSION_VALUE) {
      setColor = 'permission';
    } else if (
      logItem?.leave_duration === LEAVE_LOG_STATUS[0] ||
      logItem?.leave_duration === LEAVE_LOG_STATUS[1]
    ) {
      setColor = 'leaveRed';
    }
    return style[setColor];
  };
  const toggleModal = (type, value) => {
    if (value && type === 'Edit') {
      modalData = {
        title: 'new',
        id: value.id,
        status: value.status,
      };
    } else if (type === '') {
      modalData = {};
    }
    modalHeader = <span className="text-nowrap">{`${type} `}</span>;
    setModal(!modal);
  };

  const handlePopUp = async (e, arrIndx) => {
    let checkedState = e.target.value;
    let arrpoveitem = {};

    let newLogItem = new Promise((resolve) => {
      timeLogArr.map(async (item, index) => {
        if (index === arrIndx) {
          let senData = item?.timesheet_data?.map((timeData, indx) => {
            if (!checkedState) {
              return { ...timeData, billable: checkedState };
            }
            return timeData;
          });
          // let cal = calculatebillable(senData) || {};
          arrpoveitem = {
            ...item,
            // ...cal,
            total_nonBillable_hours: !checkedState
              ? 0
              : item['total_nonBillable_hours'],
            timesheet_data: senData,
            isBillable: checkedState ? item['isBillable'] : checkedState,
            first_approvers_status: item['first_approvers_status']
              ? checkedState
              : item['first_approvers_status'],
            second_approvers_status: item['second_approvers_status']
              ? checkedState
              : item['second_approvers_status'],
            is_approved: checkedState == 'null' ? null : checkedState,
          };
          resolve(arrpoveitem);
          return arrpoveitem;
        }
        return item;
      });
    });
    newLogItem
      .then((data) => {
        const calcBill = calculatebillable(arrpoveitem?.timesheet_data) || {};
        handleClick('update', { ...arrpoveitem, ...calcBill });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {timeLogArr.map((item, indx) => {
        const { approvalStatus, multiStatus } = getApprovalStatus(item, indx);
        return (
          <div className={`mb-4 `} key={indx + 77}>
            <Row className="m-0 ">
              <Col md={12} className="py-1 px-1 header_bgcolor">
                <div
                  className={`d-md-flex  align-items-center  justify-content-between ${style.fortablesmall}`}
                >
                  <div className="  p-md-0 ">
                    <HandleBadges
                      item={item}
                      approvalStatus={approvalStatus}
                      multiStatus={multiStatus}
                    />{' '}
                    <span>
                      {/* <Button
                        icon={<MdOutlinePostAdd size={22} />}
                        className="border-0 m-0 p-0"
                        onClick={() => toggleModal('Add', null)}
                      ></Button> */}
                      <AntdModalComp
                        width="30%"
                        toggleModal={() => toggleModal('Add', null)}
                        modalOpen={modal}
                        modalHeader={modalHeader}
                        modalBody={
                          <SampleModal
                            modaldata={modalData}
                            setModal={setModal}
                            modal={modal}
                          />
                        }
                      />
                    </span>
                    {!!item?.isLocked && (
                      <Button
                        type="none"
                        htmlType="button"
                        icon={<FaLock />}
                        onClick={() => handleClick('unlock', item.id)}
                      ></Button>
                    )}
                  </div>

                  <div className={`d-md-flex align-items-center justify-content-md-end ${style.flexsmall}`}>
                    {!item.isLocked && (
                      <div>
                        <Form.Item className="mb-0">
                          <Radio.Group
                            value={
                              userData?.isFirstApprover
                                ? item?.first_approvers_status
                                : item?.is_approved
                            }
                            onChange={(e) => handlePopUp(e, indx)}
                            // onChange={(e) => handleApprove(e, indx)}
                            className={`${style.radioColor}`}
                          >
                            <Radio
                              // disabled={
                              //   !SettingsData.isSingleApprover &&
                              //   userData?.isSecondApprover == true &&
                              //   (!item?.first_approvers_status ||
                              //     item?.first_approvers_status == 'null')
                              //     ? true
                              //     : false
                              // }
                              value={'null'}
                            >
                              {' '}
                              Pending{' '}
                            </Radio>
                            {buttonPermission?.approve && (
                              <Radio
                                // disabled={
                                //   !SettingsData.isSingleApprover &&
                                //   userData?.isSecondApprover == true &&
                                //   (!item?.first_approvers_status ||
                                //     item?.first_approvers_status == 'null')
                                //     ? true
                                //     : false
                                // }
                                value={true}
                              >
                                {' '}
                                Approve{' '}
                              </Radio>
                            )}
                            {buttonPermission?.reject && (
                              <Radio
                                // disabled={
                                //   !SettingsData.isSingleApprover &&
                                //   userData?.isSecondApprover == true &&
                                //   (!item?.first_approvers_status ||
                                //     item?.first_approvers_status == 'null')
                                //     ? true
                                //     : false
                                // }
                                value={false}
                              >
                                {' '}
                                Reject{' '}
                              </Radio>
                            )}
                            {/* <Radio value={"null"}> Pending </Radio> */}
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    )}
                    {/* <div className="px-1 pb-1 tmPointer">
                      <FaLock />
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col
                md={12}
                className={`px-0  ${!!item.isLocked ? style.tableDisable : ''}`}
              >
                <div className="">
                  <Table responsive className={`mb-0`} bordered>
                    <thead>
                      <tr>
                        <td className="col-2 px-2 border-0">
                          <b>PROJECTS</b>
                        </td>
                        <td className="col-2 px-2 border-0">
                          <b>MODULES</b>
                        </td>
                        <td className="col-3 px-2 border-0">
                          <b>TASKS</b>
                        </td>
                        <td className="col-2 px-2 border-0">
                          <b>TIME SPENT</b>
                        </td>
                        <td className="col-auto text-center border-0 px-2">
                          <b>IN HRS</b>
                        </td>
                        <td className="col-auto text-center border-0 px-2">
                          <b>TOTAL</b>
                        </td>
                        <td className="col-auto text-center border-0 px-0">
                          {!LEAVESTATUS.includes(item?.type) &&
                            !WEEKENDHOLIDAY.includes(item?.type) &&
                            !item.isLocked && (
                              <Checkbox
                                disabled={!item?.is_approved}
                                className="p-0 m-0"
                                checked={item?.isBillable}
                                onChange={(e) =>
                                  handleAllBillable(e, indx, 'all')
                                }
                              >
                                <b>BILLABLE</b>
                              </Checkbox>
                            )}
                          {!!item.isLocked && <b>BILLABLE</b>}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.timesheet_data?.length > 0 &&
                        item?.timesheet_data?.map((logItem, index) => (
                          <tr
                            key={index + 45}
                            className={colorSetter(item, logItem)}
                          >
                            <td title={logItem?.project_name}>
                              {logItem?.project_name}
                            </td>
                            <td title={logItem?.module_name}>
                              {logItem?.module_name}
                            </td>
                            <td title={logItem?.task}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="tmTextOverflow">
                                  {logItem?.task}
                                </div>
                                <div
                                  className={`border rounded-pill ${
                                    Boolean(logItem?.billable)
                                      ? style.billable
                                      : style.nonBillable
                                  }`}
                                >
                                  B
                                </div>
                              </div>
                            </td>
                            <td>{logItem.timeShow}</td>
                            <td className="text-center">
                              {!WEEKENDHOLIDAY.includes(item?.type) &&
                              !LEAVESTATUS.includes(item?.type)
                                ? `${logItem.individual_hours ?? 0} hr`
                                : ''}
                              {/* {logItem.individual_hours} hr */}
                            </td>
                            {index === 0 && (
                              <td
                                className="align-middle text-center border"
                                rowSpan={100}
                              >
                                {!WEEKENDHOLIDAY.includes(item?.type) &&
                                !LEAVESTATUS.includes(item?.type)
                                  ? `${item.total_hours ?? 0.0} hrs`
                                  : ''}
                                {/* {item.total_hours} hrs */}
                              </td>
                            )}

                            <td className="text-center px-0">
                              {logItem?.project_id !== 1 &&
                                logItem?.consider_bill === 'bill' && (
                                  <span>
                                    {!item.isLocked && (
                                      <Checkbox
                                        disabled={!item?.is_approved}
                                        key={index + 33}
                                        checked={Boolean(logItem?.billable)}
                                        onChange={(e) =>
                                          handleBillable(e, indx, index)
                                        }
                                        className="p-0 m-0"
                                      />
                                    )}
                                  </span>
                                )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={12} className="px-0 mt-2">
                <div className="row d-fec mx-0">
                  {SHOW_MODIFIED.includes(item?.type) ? (
                    <div
                      style={{
                        color: '#a39f9f',
                        fontWeight: 700,
                        fontStyle: 'italic',
                        fontSize: 12,
                      }}
                      className="col-md-3"
                    >
                      <div className="mb-1">
                        Submitted:{' '}
                        <span style={{ color: '#777' }}>{item?.createdAt}</span>
                      </div>
                      <div>
                        Modified:{' '}
                        <span style={{ color: '#777' }}>{item?.updatedAt}</span>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-md-3 pe-0 d-md-flex justify-content-end align-items-center">
                    <span className={`${style.sizeforfont}`}>
                      Total Hours (Entered)
                    </span>
                    <span className={`ms-2 ${style.sizefortotal}`}>
                      <span>
                        <b className="primaryColor">{item.total_hours}</b>
                      </span>
                      <span className="text-muted fw-bold ms-1">{'hrs'}</span>
                    </span>
                  </div>
                  <div className="col-md-3 pe-0 d-md-flex justify-content-end align-items-center">
                    <span className={`${style.sizeforfont}`}>
                      Billable Hours
                    </span>
                    <span className={` ms-2 ${style.sizefortotal}`}>
                      <span>
                        <b className="primaryColor">
                          {item?.total_billable_hours}
                        </b>
                      </span>
                      <span className="text-muted fw-bold ms-1">{'hrs'}</span>
                    </span>
                  </div>
                  <div className="col-md-3 pe-0 d-md-flex justify-content-end align-items-center">
                    <span className={`${style.sizeforfont}`}>
                      Non-Billable Hours
                    </span>
                    <span className={`ms-2 ${style.sizefortotal}`}>
                      <span>
                        <b className="primaryColor">
                          {item?.total_nonBillable_hours}
                        </b>
                      </span>
                      <span className="text-muted fw-bold ms-1">{'hrs'}</span>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
}
export default UserTimeSheetAccrdn;
