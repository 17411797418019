import { RiTimerLine } from 'react-icons/ri';
import { AiOutlineCodeSandbox, AiOutlineUsergroupDelete } from 'react-icons/ai';
import { IoSettingsOutline } from 'react-icons/io5';
import { RiBox1Line } from 'react-icons/ri';
import { GoDotFill } from 'react-icons/go';
import { FaRegFileLines, FaRegStar, FaUsersGear } from 'react-icons/fa6';
import style from '../../Styles/Layout.module.css';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { LuCalendarX } from 'react-icons/lu';
import { MdOutlineDashboard, MdOutlineSpaceDashboard } from 'react-icons/md';
import { LiaUsersCogSolid } from 'react-icons/lia';

export const items = [
  {
    key: '/dashboard',
    statename: 'dashboard',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <MdOutlineSpaceDashboard size={23} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>Dashboard</span>,
  },
  {
    key: '/',
    statename: 'timeSheet',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <RiTimerLine size={25} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>Timesheet</span>,
    children: [
      {
        key: '/timesheet',
        statename: 'myTimesheet',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>My Timesheet</span>,
      },
      {
        key: '/user_timesheet',
        statename: 'usersTimeSheet',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: (
          <span className={`${style.iconMinHeight}`}>Users Timesheet</span>
        ),
      },
    ],
  },
  {
    key: '/leave_managements',
    statename: 'leaveManagements',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <LuCalendarX size={23} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>Leave</span>,
    children: [
      {
        key: '/my_requests',
        statename: 'myRequest',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>My Requests</span>,
      },
      {
        key: '/user_requests',
        statename: 'userRequest',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>User Requests</span>,
      },
    ],
  },
  {
    key: '/permission_managements',
    statename: 'permissionManagement',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <LuCalendarX size={23} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>Permission</span>,
    children: [
      {
        key: '/my_requests_permission',
        statename: 'myRequestPermission',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>My Requests</span>,
      },
      {
        key: '/user_requests_permission',
        statename: 'userRequestPermission',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>User Requests</span>,
      },
    ],
  },
  {
    key: '/projects42',
    statename: 'projects',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <RiBox1Line size={25} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>Projects</span>,
    children: [
      {
        key: '/projects',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>Project</span>,
        statename: 'project',
      },
      {
        key: '/module',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>Module</span>,
        statename: 'module',
      },
      {
        key: '/draft_module',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>Draft Module</span>,
        statename: 'draftModule',
      },
    ],
  },
  {
    key: '/user_management',
    statename: 'userManagement',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <AiOutlineUsergroupDelete size={25} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>User</span>,
    children: [
      {
        key: '/user',
        statename: 'user',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>User</span>,
      },
      {
        key: '/user_role',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        statename: 'userRole',
        label: <span className={`${style.iconMinHeight}`}>User Role</span>,
      },
    ],
  },

  {
    key: '/masterasda',
    statename: 'master',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <AiOutlineCodeSandbox size={28} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>Masters</span>,
    children: [
      {
        key: '/department',
        statename: 'department',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>Departments</span>,
      },
      {
        key: '/designation',
        statename: 'designation',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>Designations</span>,
      },
      {
        key: '/leaves',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: (
          <span className={`${style.iconMinHeight}`}>Public Holidays</span>
        ),
        statename: 'leave',
      },
      {
        key: '/email_notification',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: (
          <span className={`${style.iconMinHeight}`}>Email Notification</span>
        ),
        statename: 'emailNotification',
      },
    ],
  },
  {
    key: '/reports',
    statename: 'report',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <FaRegFileLines size={23} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>Reports</span>,
    children: [
      {
        key: '/notification',
        statename: 'notificationHistory',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: (
          <span className={`${style.iconMinHeight}`}>Notification History</span>
        ),
      },
      {
        key: '/attendance_report',
        statename: 'attendance',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: (
          <span className={`${style.iconMinHeight}`}>Attendance Report</span>
        ),
      },

      {
        key: '/department_report',
        statename: 'departmentReport',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: (
          <span className={`${style.iconMinHeight}`}>Department Report</span>
        ),
      },
      {
        key: '/user_report',
        statename: 'userReport',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: <span className={`${style.iconMinHeight}`}>User Report</span>,
      },
      {
        key: '/effort_variance',
        statename: 'effortVariance',
        icon: (
          <span className={`${style.iconMinHeight}`}>
            <GoDotFill size={12} />
          </span>
        ),
        label: (
          <span className={`${style.iconMinHeight}`}>Effort Varience</span>
        ),
      },
    ],
  },

  {
    key: '/user_log',
    statename: 'userlog',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <LiaUsersCogSolid size={25} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>User Logs</span>,
  },
  {
    key: '/settings',
    statename: 'settings',
    icon: (
      <span className={`${style.iconMinHeight}`}>
        <IoSettingsOutline size={25} />
      </span>
    ),
    label: <span className={`${style.iconMinHeight}`}>Settings</span>,
  },
];

export const headerBar = {
  timesheet: 'Timesheet',
  dashboard: 'Dashboard',
  usertimesheet: 'Timesheet',
  myusertimesheet: 'Timesheet',
  projects: 'Projects',
  addProject: 'Project',
  module: 'Projects',
  draftmodule: 'Projects',
  user: 'User Management',
  userrole: 'User Management',
  adduserRole: 'User Role',
  adduser: 'User',
  settings: 'Settings',
  userlog: 'User Logs',
  department: 'Masters',
  designation: 'Masters',
  leaves: 'Masters',
  emailnotification: 'Masters',
  attendancereport: 'Reports',
  effortvariance: 'Reports',
  notification: 'Reports',
  myrequests: 'Leave Management',
  departmentreport: 'Reports',
  userreport: 'Reports',
  departmentview: 'Reports',
  userrequests: 'Leave Management',
  userrequestspermission: 'Permission Management',
  myrequestspermission: 'Permission Management',
};

export const defaultKeySetter = {
  timesheet: '/timesheet',
  dashboard: '/dashboard',
  usertimesheet: '/user_timesheet',
  myusertimesheet: '/user_timesheet',
  projects: '/projects',
  addProject: '/projects',
  ViewProject: '/projects',
  module: '/module',
  draftmodule: '/draft_module',
  user: '/user',
  userrole: '/user_role',
  adduserRole: '/user_role',
  adduser: '/user',
  settings: '/settings',
  userlog: '/user_log',
  department: '/department',
  designation: '/designation',
  leaves: '/leaves',
  emailnotification: '/email_notification',
  attendancereport: '/attendance_report',
  effortvariance: '/effort_variance',
  notification: '/notification',
  myrequests: '/my_requests',
  departmentreport: '/department_report',
  userreport: '/user_report',
  departmentview: '/department_report',
  userrequests: '/user_requests',
  userrequestspermission: '/user_requests_permission',
  myrequestspermission: '/my_requests_permission',
};
