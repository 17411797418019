import axiosClient from './axiosClient';

const Settings = {
  get: () => {
    const url = `/api/settings/get`;
    return axiosClient.post(url);
  },
  update: (data) => {
    const url = `/api/settings/edit`;
    return axiosClient.post(url, data);
  },
};

export default Settings;
