import axiosClient from "./axiosClient";

const Module = {
  create: (data) => {
    const url = `/api/module/create`;
    return axiosClient.post(url, data);
  },
  update: (data) => {
    const url = `/api/module/edit`;
    return axiosClient.post(url, data);
  },
  getProjectUsers: (id) => {
    const url = `/api/project/users/${id}`;
    return axiosClient.post(url);
  },
  get: (data) => {
    const url = `/api/module/get`;
    return axiosClient.post(url, data);
  },
  getModule: (id) => {
    const url = `api/module/get/${id}`;
    return axiosClient.post(url);
  },
  delete: (data) => {
    const url = `/api/module/delete`;
    return axiosClient.post(url,data);
  },
  customModule:(data) => {
    const url = `/api/module/convert/draftModule`;
    return axiosClient.post(url,data);
  },
};

// 'http://localhost:3004/api/module/convert/draftModule' \

export default Module;
