import React from 'react';

const ShowStatus = ({ status }) => {
  const handleStatus = (status) => {
    let newStatus = String(status).toLowerCase();
    switch (newStatus) {
      case 'inactive':
        return (
          <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
            In Active
          </span>
        );
        break;
      case 'active':
        return (
          <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
            Active
          </span>
        );
        break;
      case 'rejected':
        return (
          <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
            Rejected
          </span>
        );
        break;

      case 'approved':
        return (
          <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
            Approved
          </span>
        );
        break;
      case 'pending':
        return (
          <span className="alert alert-warning mb-0 py-0 px-2 rounded-pill">
            Pending
          </span>
        );
        break;
      case 'completed':
        return (
          <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
            Completed
          </span>
        );
        break;
      case 'not yet started':
        return (
          <span className="alert alert-secondary mb-0 py-0 px-2 rounded-pill">
            Not yet started
          </span>
        );
        break;
      case 'inprogress':
        return (
          <span className="alert alert-warning mb-0 py-0 px-2 rounded-pill">
            Inprogress
          </span>
        );
        break;
      case 'sent':
        return (
          <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
            Delivered
          </span>
        );
        break;
      case 'unsent':
        return (
          <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
            Not Delivered
          </span>
        );
        break;
      case 'working':
        return (
          <span className="alert alert-success mb-0 py-0 px-2 rounded-pill">
            Working
          </span>
        );
        break;
      case 'notworking':
        return (
          <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
            Not Working
          </span>
        );
        break;
      case 'rejected':
        return (
          <span className="alert alert-danger mb-0 py-0 px-2 rounded-pill">
            REJECTED
          </span>
        );
        break;
        case 'missed':
        return (
          <span className="alert alert-secondary mb-0 py-0 px-2 rounded-pill">
            MISSED
          </span>
        );
        break;

        case 'locked':
        return (
          <span className="alert alert-warning mb-0 py-0 px-2 rounded-pill">
            LOCKED
          </span>
        );
        break;


      default:
        break;
    }
  };
  return <span>{handleStatus(status)}</span>;
};

export default ShowStatus;
