import axiosClient from './axiosClient';

export const LeaveManageApi = {
  get: (data) => {
    const url = `/api/leave/get/request`;
    return axiosClient.post(url, data);
  },
  getById: (id) => {
    const url = `/api/leave/get/${id}`;
    return axiosClient.post(url);
  },
  getUserList: (data) => {
    const url = `/api/leave/get/UserRequest`;
    return axiosClient.post(url, data);
  },
  view: (data) => {
    const url = `/api/leave/view`;
    return axiosClient.post(url, data);
  },
  create: (data) => {
    const url = `/api/leave/create`;
    return axiosClient.post(url, data);
  },
  edit: (data) => {
    const url = `/api/leave/update`;
    return axiosClient.post(url, data);
  },
  delete: (data) => {
    const url = `/api/leave/delete`;
    return axiosClient.post(url, data);
  },
  approve: (data) => {
    const url = `/api/leave/approve`;
    return axiosClient.post(url, data);
  },

  getChart: (data) => {
    const url = `/api/leave/get/user/chartData`;
    return axiosClient.post(url, data);
  },
};
