import axiosClient from './axiosClient';

const UserApi = {
  getAllUsers: (data) => {
    const url = `/api/newusers/get`;
    return axiosClient.post(url, data);
  },
  getProfile: (data) => {
    const url = `/api/newusers/profile`;
    return axiosClient.post(url, data);
  },
  create: (data) => {
    const url = `api/newusers/create`;
    return axiosClient.post(url, data);
  },
  update: (data) => {
    const url = `api/newusers/edit`;
    return axiosClient.post(url, data);
  },
  view: (id) => {
    const url = `api/newusers/get/view/${id}`;
    return axiosClient.post(url, {});
  },
  delete: (data) => {
    const url = `api/newusers/delete`;
    return axiosClient.post(url, data);
  },
  getUser: (id) => {
    const url = `api/newusers/get/${id}`;
    return axiosClient.post(url);
  },
  dropDownUserType: () => {
    const url = `api/userTypes/dropDown`;
    return axiosClient.post(url);
  },
  dropDownUsers: (data) => {
    const url = `api/newusers/dropDown`;
    return axiosClient.post(url, data);
  },
  dropDownAuthority: (data) => {
    const url = `api/newusers/dropDown/authority`;
    return axiosClient.post(url, data);
  },
  dropDownEmployee: (data) => {
    const url = `api/newusers/dropDown/employee`;
    return axiosClient.post(url, data);
  },
  getEmail: (data) => {
    const url = `api/newusers/email`;
    return axiosClient.post(url, data);
  },
  sendCredentials: (data) => {
    const url = `api/newusers/sendCredentials`;
    return axiosClient.post(url, data);
  },
  sendBulkCredentials: (data) => {
    const url = `/api/newusers/bulkSendCredentials`;
    return axiosClient.post(url, data);
  },
  profilepicture: (data) => {
    const url = `api/newusers/update/profilepicture`;
    return axiosClient.post(url, data);
  },
};
export default UserApi;
