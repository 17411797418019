import React from "react";
import ModuleComponent from "../../Components/Module/ModuleComponent";

const Module = () => {
  return (
    <>
      <ModuleComponent />
    </>
  );
};

export default Module;
