import React from 'react';
import { Fragment } from 'react';
import ViewProfileComponent from '../../Components/ViewProfile/ViewProfileComponent';

function ViewProfile() {
  return (
    <Fragment>
      <ViewProfileComponent />
    </Fragment>
  );
}

export default ViewProfile;
