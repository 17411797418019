import React from 'react'
import LeaveComponent from '../../Components/LeaveComponent/LeaveComponent'

const Leave = () => {
  return (
    <div>
      <LeaveComponent />      
    </div>
  )
}

export default Leave
