/**
 * @type {import("antd").ThemeConfig}
 */
export const antdConfiguration = {
  token: {
    colorPrimary: '#1B67B3',
    // colorPrimary: '#F8A013',
    borderRadius: 3,
    colorBgContainer: '#fff',
    // fontFamily: 'Lato',
  },
  components: {
    Button: {
      defaultBg: 'rgb(238, 238, 238)',
      defaultColor: 'rgb(22, 54, 88)',
      colorPrimary: '#134780',
    },
    // Pagination: {
    //   colorPrimary: "rgb(7, 222, 122)",
    // },
  },
};
