import React, { useEffect, useState } from 'react';
import style from '../../Styles/Project.module.css';
import styles from '../../Styles/AddUser.module.css';
import { CardBody, Card, Row, Col } from 'reactstrap';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Image,
  Button,
  Checkbox,
} from 'antd';
import TmTableInput from '../ReusableComponents/TmTableInput';
import TmButton from '../ReusableComponents/TmButton';
import Mandatory from '../ReusableComponents/Mandatory';
import dayjs from 'dayjs';
import { ProjectApi } from '../../Api/ProjectApi';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import { useNavigate, useParams } from 'react-router';
import { DateFormat, BILLING_METHODS } from '../../constant/constant';
import UserApi from '../../Api/UserApi';
import { dayjsToDateFormat } from '../../ReusableFn/reusableFn';
import { FaArrowLeft } from 'react-icons/fa6';
import { usePageAccess } from '../../helpers/handlePageAccess';
import { useSelector } from 'react-redux';
import { getProjects } from '../../store/reducers/actionPermission';
import Department from '../../Api/Department';
import { useAuth } from '../../Context/AuthContext';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
let initialValues = {
  billing_method: null,
  customer_name: null,
  description: null,
  end_date: null,
  fileInfo: null,
  project_managers: null,
  project_members: [],
  project_name: null,
  customModule: true,
  start_date: null,
};

const AddProject = () => {
  const { authState } = useAuth();
  const pageAccess = usePageAccess();
  const data = useSelector(getProjects);

  const [form] = Form.useForm();
  const [authoriry, setAuthority] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState({ count: 0, url: "" });
  const [fileList, setFileList] = useState([]);
  const { edit_id, view_type } = useParams();
  const navigate = useNavigate();
  const startDate = Form.useWatch('start_date', form);
  const endDate = Form.useWatch('end_date', form);

  const [members, setMembers] = useState([]);

  const projectManagers = Form.useWatch('project_managers', form);

  const [department, setDepartment] = useState([]);

  useEffect(() => {
    getDropDown();
  }, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewOpen(true);
  };
  const handleImageChange = (event) => {
    if (event.file.status !== 'removed') {
      let file = event?.file;

      const validtypes = ['image/jpeg', 'image/jpg', 'image/png'];

      let totalSizeKB = file.size / Math.pow(1024, 1);
      if (totalSizeKB > 10000) {
        toastWarn('Please Upload Below 10 MB', 'Invalid');
        return;
      }

      if (validtypes.includes(file?.type)) {
        setFileList(event?.fileList);
      } else {
        toastWarn('Invalid file type. Only JPG and PNG files are allowed');
      }
    }
  };
  const getDropDown = async () => {
    try {
      let authoriry = await UserApi.dropDownAuthority({});
      let employee = await UserApi.dropDownEmployee({});
      let department = await Department.dropDown();
      if (authoriry?.data?.success && employee?.data?.success) {
        setAuthority(authoriry?.data?.result);
        setEmployee(employee?.data?.result);
        setDepartment(department?.data?.result);
      }
      if (edit_id) {
        getProjectById(edit_id);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const getProjectById = async (edit_id) => {
    try {
      const response = await ProjectApi.getProjectByID(atob(edit_id));
      if (response?.data?.success) {
        const res = response?.data?.result[0];
        let editValues = {
          ...res,
          start_date: res['start_date']
            ? dayjs(dayjs(res['start_date']).format(DateFormat), DateFormat)
            : '',
          end_date: res['end_date']
            ? dayjs(dayjs(res['end_date']).format(DateFormat), DateFormat)
            : '',
          project_managers: res?.project_managers
            ? JSON.parse(res?.project_managers)
            : null,
          department: res.department_name,
          project_members: res.project_members.length
            ? res.project_members.map((itm) => ({ ...itm }))
            : [],
        };

        form.setFieldsValue(editValues);

        setFileList(res?.fileInfo ? res?.fileInfo : []);

        // form.get
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const onSubmit = async (reqData) => {
    reqData.project_members = reqData?.project_members.length
      ? reqData?.project_members.map((item) => item?.id)
      : '';

    let formData = new FormData();

    formData.append('user_id', authState?.id);
    formData.append('billing_method', reqData.billing_method);
    formData.append('customer_name', reqData.customer_name);
    formData.append('description', reqData.description);
    formData.append(
      'end_date',
      reqData?.end_date ? dayjsToDateFormat(reqData?.end_date) : '',
    );

    formData.append(
      'department',
      reqData?.department ? reqData?.department : '',
    );

    if (fileList.length) {
      fileList[0].hasOwnProperty('originFileObj') &&
        formData.append('file', fileList[0].originFileObj);
    }

    if (edit_id && !fileList?.length) {
      formData.append('fileDelete', true);
    }

    formData.append(
      'project_managers',
      reqData.project_managers ? JSON.stringify(reqData.project_managers) : '',
    );
    formData.append('project_name', reqData.project_name);
    formData.append('customModule', reqData.customModule);
    formData.append(
      'project_members',
      reqData.project_members ? JSON.stringify(reqData.project_members) : '',
    );
    formData.append(
      'start_date',
      reqData?.start_date ? dayjsToDateFormat(reqData?.start_date) : '',
    );
    if (edit_id) {
      formData.append('id', atob(edit_id));
    }

    try {
      const response = edit_id
        ? await ProjectApi.update(formData)
        : await ProjectApi.createProject(formData);
      if (response.data.success) {
        toastSuccess(response.data.message);
        navigate(`/projects/${view_type}`);
        form.resetFields();
      } else {
        toastWarn(response.data.message);
      }
    } catch (error) {
      toastError('Failed to create user');
    }
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  useEffect(() => {
    if (data) {
      pageAccess(data, edit_id);
    }
  }, [data]);

  const validateInput = (_, value) => {
    if (value !== value.trim() || value.includes('  ')) {
      return Promise.reject(new Error(' Extra spaces are not allowed'));
    }

    return Promise.resolve();
  };

  return (
    <div>
      <div className="d-flex  px-0 pb-1">
        <div
          className={`${styles['arrow-icon']} border px-2 pb-1`}
          onClick={() => navigate(`/projects/${view_type}`)}
        >
          <FaArrowLeft />
        </div>
        <div className={`mx-3 ${styles['add-user']}`}>
          {edit_id ? 'Edit Project' : 'Add Project'}
        </div>
      </div>
      <Row className="my-2">
        <Col>
          <Card>
            <CardBody>
              <Form
                initialValues={initialValues}
                requiredMark={customizeRequiredMark}
                form={form}
                onFinish={onSubmit}
                layout="vertical"
              >
                <Row>
                  <Col md={6} xl={4}>
                    <div className="mb-2">
                      <span className={`fw-bold ${style['title-font-size']}`}>
                        General Details
                      </span>
                    </div>
                    <Form.Item
                      name="project_name"
                      label="Project Name"
                      rules={[
                        {
                          required: true,

                          message: 'This field is required',
                        },
                        {
                          validator: validateInput,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Project Name" />
                    </Form.Item>
                    <Form.Item
                      name="customer_name"
                      label="Customer Name"
                      rules={[
                        { required: true, message: 'This field is required' },
                        {
                          validator: validateInput,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Customer Name" />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                      <TextArea placeholder="Enter Description" />
                    </Form.Item>
                    <Form.Item
                      label="Billing Method"
                      name="billing_method"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <Select
                        options={BILLING_METHODS}
                        type="select"
                        placeholder="Select"
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item label="Department" name="department">
                      <Select
                        allowClear
                        type="select"
                        placeholder="Select"
                        showSearch
                      >
                        {department.map((element) => (
                          <Option
                            key={element.value}
                            value={element?.value}
                            disabled={!element.status}
                          >
                            {element?.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Project Manager"
                      name="project_managers"
                      // rules={[
                      //   { required: true, message: "This field is required" },
                      // ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {authoriry?.map(
                          (element) =>
                            !members?.includes(element?.id) && (
                              <Option key={element?.id} value={element?.id}>
                                {element.value}
                              </Option>
                            ),
                        )}

                        {/* {authoriry?.map((element) => (
                          <Option key={element.id} value={element.id}>
                            {element.value}
                          </Option>
                        ))} */}
                      </Select>
                    </Form.Item>

                    <div className="d-flex justify-content-between">
                      <Form.Item label="Project Duration" name={'start_date'}>
                        <DatePicker
                          maxDate={endDate}
                          placeholder="Start Date"
                          style={{ width: '100%' }}
                          format={DateFormat}
                        />
                      </Form.Item>

                      <Form.Item label={<>&nbsp;</>} name="end_date">
                        <DatePicker
                          minDate={startDate}
                          placeholder="End Date"
                          style={{ width: '100%' }}
                          format={DateFormat}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item name="customModule" valuePropName="checked">
                      <Checkbox>Allow to Create Draft Modules</Checkbox>
                    </Form.Item>

                    <Form.Item label="Add Company Logo">
                      <Upload
                        beforeUpload={(file) => {
                          return false;
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleImageChange}
                        onRemove={handleRemove}
                        multiple={false}
                        listType="picture-card"
                      >
                        {fileList?.length === 0 && <PlusOutlined />}
                      </Upload>
                    </Form.Item>
                    {fileList?.length > 0 && (
                      <Image
                        wrapperStyle={{
                          display: 'none',
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                        }}
                        src={fileList[0].url || fileList[0].preview}
                      />
                    )}
                  </Col>

                  <Col md={6} xl={8}>
                    <div className="mb-2">
                      <span className={`fw-bold ${style['title-font-size']}`}>
                        Team Members
                      </span>
                    </div>
                    <TmTableInput
                      form={form}
                      employee={employee}
                      projectManagers={projectManagers}
                      setMembers={setMembers}
                      members={members}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center antdBtn">
                    <Button
                      type="default"
                      size="medium"
                      htmlType="button"
                      onClick={() => navigate(`/projects/${view_type}`)}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" size="medium" htmlType="submit">
                      {edit_id ? 'Update' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddProject;
