import { useEffect, useState } from 'react';
import style from '../../Styles/Project.module.css';
import noImage from '../../Assets/images.jpg';
import noDataFound from '../../Assets/No Data found.png';
import { Row, Col, Card } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import { TiPowerOutline } from 'react-icons/ti';
import { MdDoNotDisturbOnTotalSilence } from 'react-icons/md';
import NoDataFound from '../ReusableComponents/NoDataFound';
let initialLoad = 0;

const ProjectCard = ({
  items,
  handleClicks,
  buttonPermission,
  table,
  view_type,
}) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState([]);
  const determineView = view_type == true ? 'card' : ' table';
  const handleEdit = (id) => {
    navigate(`/add_Project/${btoa(determineView)}/${btoa(id)}`);
  };

  useEffect(() => {
    handleLoadSetting(items);
    initialLoad = 6;
  }, [items]);

  const handleLoadSetting = async (items) => {
    await setLoad(items.filter((_, indx) => indx < initialLoad));
  };

  const handleLoadMore = async () => {
    if (items.length > initialLoad) {
      initialLoad += 6;
    } else {
      initialLoad = 6;
    }
    handleLoadSetting(items);
  };

  const handleDisable = (value, status) => {
    handleClicks(value, status);
  };

  const handleClick = (id) => {
    // navigate(`/View_Project/${btoa(id)}`);
    navigate(
      `/View_Project/${btoa(determineView)}/${btoa(id)}/${btoa(
        buttonPermission.edit,
      )}`,
    );
  };

  return (
    <>
      <div className="row  px-0">
        {items.length > 0 &&
          load.map((item, indx) => (
            <Col md={4} className="mt-4" key={indx + 56}>
              <div>
                <Card
                  className={`${style.projectCard} ${
                    item?.deleteStatus ? style.disable : ''
                  }`}
                >
                  <div className="rounded-1 px-3 py-2 pt-1 shadow-sm icon-btn">
                    <div className="justify-content-end d-flex">
                      <div className="antdbtn">
                        {!item?.deleteStatus && buttonPermission.edit && (
                          <button
                            className="btn p-0 m-0 icon-hover"
                            type="button"
                            title="Edit"
                            onClick={() => handleEdit(item.id)}
                          >
                            <FiEdit size={16} />
                          </button>
                        )}
                        {!item?.deleteStatus && buttonPermission.delete && (
                          <button
                            className="btn p-0 m-0 icon-hover ms-1"
                            type="button"
                            title="Deactivate"
                            onClick={() =>
                              handleDisable(item.id, item.deleteStatus)
                            }
                          >
                            <MdDoNotDisturbOnTotalSilence size={18} />
                          </button>
                        )}
                        {item?.deleteStatus && buttonPermission.delete && (
                          <button
                            className="btn p-0 m-0 icon-hover ms-1"
                            type="button"
                            title="Activate"
                            onClick={() =>
                              handleDisable(item.id, item.deleteStatus)
                            }
                          >
                            <TiPowerOutline size={20} />
                          </button>
                        )}
                      </div>
                    </div>
                    <div onClick={() => handleClick(item.id)}>
                      <div className={`${style.imgctrl} row m-0 p-0 mt-2`}>
                        <div className="col-5 px-0 shadow-sm rounded-1">
                          {true && (
                            <img
                              src={item.file || noImage}
                              className={`width height`}
                              alt="logo"
                              onError={({ currentTarget }) => {
                                if (currentTarget.onerror === null) {
                                  currentTarget.src = noImage;
                                  currentTarget.alt = 'no image found';
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className="col-7  px-0 ps-2">
                          <h5
                            className="mb-1 tmTextOverflow firstLetter"
                            title={item.project_name}
                          >
                            {item.project_name}
                          </h5>
                          <div>
                            <span className="ligthcolor tmTextOverflow">
                              {item?.customer_name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${style.between} row m-0 p-0 mt-2 pb-3 border border-start-0 border-end-0 border-top-0`}
                      >
                        <div className="col-9 px-0">
                          <div>
                            <span className="ligthcolor">Project Managers</span>
                          </div>
                          <b className="mb-3">
                            {item.project_managers
                              ? item.project_managers
                              : '-'}
                          </b>
                          <div className="mt-2">
                            <span className="ligthcolor">Department</span>
                          </div>
                          <b className="mb-1">
                            {' '}
                            {item.department_name ? item.department_name : '-'}
                          </b>
                        </div>
                        <div className="col-3 px-0 text-end">
                          <div>
                            <span className="ligthcolor"> Members</span>
                          </div>
                          <b className="mb-1">{item.project_members}</b>
                        </div>
                      </div>

                      <div
                        className={`${style.betweenCenter} row m-0 p-0 mt-2`}
                      >
                        <div className="col-6 px-0">
                          <div>
                            <span className="ligthcolor">
                              {' '}
                              Number of Modules
                            </span>
                          </div>
                        </div>
                        <div className="col-6 px-0 text-end">
                          <div>
                            <span
                              className={`ligthcolor fw-bold fs-5 ${style.darkblue}`}
                            >
                              {' '}
                              {item.module_count}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${style.betweenCenter} row m-0 p-0 mt-2`}
                      >
                        <div className="col-6 px-0">
                          <div>
                            <span className="ligthcolor">
                              {' '}
                              Total Estimated Hours
                            </span>
                          </div>
                        </div>
                        <div className="col-6 px-0 text-end">
                          <div
                            className={`ligthcolor fw-bold fs-5  ${style.darkblue} d-flex`}
                          >
                            <div
                              title={item?.totalEstimatedHours}
                              className={`ligthcolor fw-bold fs-5  ${style.darkblue} tmTextOverflow`}
                            >
                              {item?.totalEstimatedHours ?? 0}
                            </div>
                            <div>Hrs</div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${style.betweenCenter} row m-0 p-0 mt-2`}
                      >
                        <div className="col-6 px-0">
                          <div>
                            <span className="ligthcolor">
                              {' '}
                              Total Billable Hours
                            </span>
                          </div>
                        </div>
                        <div className="col-6 px-0 text-end">
                          <div>
                            <span
                              className={`ligthcolor fw-bold fs-5 ${style.darkblue}`}
                            >
                              {item.totalBillableHours ?? 0} Hrs
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          ))}
        {items.length <= 0 && (
          <div className="px-2 mt-3">
            <NoDataFound />
          </div>
        )}
      </div>
      {items?.length > 6 && (
        <div className="text-end mt-3">
          <Button type="dashed" htmlType="button" onClick={handleLoadMore}>
            <span className="supported_text">
              {initialLoad < items.length ? 'Load more' : 'Load less'}
            </span>
          </Button>
        </div>
      )}
    </>
  );
};

export default ProjectCard;
