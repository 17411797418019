import { Button } from 'antd';
import React from 'react';
import style from '../../../Styles/SampleModal.module.css';
import {
  toastError,
  toastInfo,
  toastSuccess,
} from '../../Toast/ToastComponent';
import AttendanceReport from '../../../Api/attendanceReport';

const ApproveMonthTimesheet = ({
  toggleModal,
  getLogsList,
  filterValue,
  getAttendanceReport,
  userData,
}) => {
  const handleApprove = async () => {
    try {
      let body = {
        month: filterValue?.month,
        year: filterValue?.year,
        user_id: userData?.employee_id,
        isFirstApprover: userData?.isFirstApprover,
        isSecondApprover: userData?.isSecondApprover,
        userRoleID: userData?.userRoleID,
        id: userData?.id,
      };
      const response = await AttendanceReport.approveMonth(body);
      if (response?.data?.success) {
        await getLogsList();
        await getAttendanceReport();
        toastSuccess(response?.data?.message);
        toggleModal();
      } else {
        await getLogsList();
        await getAttendanceReport();
        toastInfo(response?.data?.message);
        toggleModal();
      }
    } catch (error) {
      toastError(error);
    }
  };
  return (
    <div>
      <div className="mt-2 fs-6">
        Are you sure you want to approve this month's timesheet entries?
      </div>

      <div className="antdBtn mt-4 text-center">
        <Button type="default" htmlType="button" onClick={toggleModal}>
          Cancel
        </Button>
        <Button onClick={handleApprove} type="primary" htmlType="submit">
          Approve
        </Button>
      </div>
    </div>
  );
};

export default ApproveMonthTimesheet;
