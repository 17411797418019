import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Input, Switch } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { Label } from 'reactstrap';
import { useAuth } from '../../Context/AuthContext';

import { toastSuccess, toastError, toastWarn } from '../Toast/ToastComponent';
import { Leave } from '../../Api/LeaveApi';
import dayjs from 'dayjs';
import { DateFormat } from '../../constant/constant';

const AddLeave = ({ toggleModal, modaldata, setModal, modal, modalHeader }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (modaldata) {
      let defaultValues = {};
      defaultValues.holiday_name = modaldata.holiday_name;
      defaultValues.date_of_holiday = modaldata.date_of_holiday;
      reset(defaultValues);
    }
  }, [modaldata]);
  const { authState } = useAuth();

  const onSubmit = async (data) => {
    let newData = { ...data, created_By_ID: authState.id };

    if (modaldata.id) {
      newData.id = modaldata.id;
      newData.modified_By_ID = authState.id;
      delete newData['created_By_ID'];
    }

    try {
      const response =
        modaldata?.type == 'Add'
          ? await Leave.createHoliday(newData)
          : await Leave.updateHoliday(newData);
      if (response.data.success) {
        toastSuccess(response.data.message);
        reset();
        setModal(!modal);
      } else {
        toastWarn(response.data.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  return (
    <>
      <Form onFinish={handleSubmit(onSubmit)} className="h-100">
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <Form.Item>
              <Label for="date">
                Date
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </Label>
              <Controller
                name="date_of_holiday"
                control={control}
                defaultValue=""
                rules={{ required: 'Date is Required' }}
                render={({ field }) => (
                  <DatePicker
                    disabled={modalHeader?.props?.children == 'View Holiday'}
                    inputReadOnly={true}
                    {...field}
                    style={{ width: '100%' }}
                    format={DateFormat}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date, dateString) => field.onChange(dateString)}
                  />
                )}
              />
              {errors?.date_of_holiday && (
                <p className="text-danger mt-1">
                  {errors?.date_of_holiday?.message}
                </p>
              )}
            </Form.Item>

            <Form.Item>
              <Label>
                Name of Holiday
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </Label>
              <Controller
                name="holiday_name"
                control={control}
                defaultValue=""
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value:
                      /^(?!.*\s{2,})(?!.*^\s)(?!.*\s$)[A-Za-z0-9!@#$%^&*()_+={}\[\]|\\:;'",.<>?/\-`~\s]+$/,
                    message: 'Extra spaces are not allowed',
                  },
                }}
                render={({ field }) => (
                  <Input
                    disabled={modalHeader?.props?.children == 'View Holiday'}
                    {...field}
                    placeholder="Enter Holiday Name"
                  />
                )}
              />
              {errors?.holiday_name && (
                <p className="text-danger mt-1">
                  {errors?.holiday_name?.message}
                </p>
              )}
            </Form.Item>
          </div>
          {modalHeader?.props?.children !== 'View Holiday' && (
            <div className="text-center antdBtn">
              <Button
                type="default"
                onClick={() => setModal(!modal)}
                size="medium"
                htmlType="button"
              >
                Cancel
              </Button>
              <Button type="primary" size="medium" htmlType="submit">
                {modaldata?.type == 'Add' ? 'Save' : 'Update'}
              </Button>
            </div>
          )}
        </div>
      </Form>
    </>
  );
};

export default AddLeave;
