import { Pagination } from 'antd';
import React from 'react';
import { PaginationRange } from '../../constant/constant';

const TmPagination = ({ total,onShowSizeChange, handlePagination, defaultCurrent }) => {
  return (
    <div className="text-end">
      <Pagination
        defaultCurrent={defaultCurrent || 1}
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        total={total}
        size="small"
        showQuickJumper
        pageSize={PaginationRange.pageSize}
        onChange={handlePagination}
        showTotal={(total) => `Total ${total} items`}
      />
    </div>
  );
};

export default TmPagination;
