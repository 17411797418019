import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useState } from 'react';
import ClockLoader from 'react-spinners/ClockLoader';

const Loader = () => {
  const override = `
  display: block;
  margin: 0 auto;
  margin-top: 20vh;
  `;
  return (
    <div
      className="border rounded-1 d-flex justify-content-center align-items-center mt-3 shadow"
      style={{
        height: '50vh',
        background: `#e6e6e6`,
        backdropFilter: 'blur(10px)',
      }}
    >
      <div>
        <div>
          <ClockLoader
            css={override}
            size={80}
            color={'#1b67b3'}
            indicator={<LoadingOutlined style={{ fontSize: 70 }} spin />}
          />
        </div>
      </div>
    </div>
  );
};

export default Loader;
