import { Checkbox, Switch } from "antd";
import { Table } from "reactstrap";
import { FiEdit } from "react-icons/fi";
const inputHeader = ["status", "action", "checkbox"];
const MasterRoleTable = (props) => {
  const { rows, columns } = props;

  return (
    <>
      <Table  className="border table-header-custom" responsive>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                className={`${
                  inputHeader.includes(column.field) ? "text-center" : null
                }`}
                key={column.field}>
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, idx) => (
            <tr key={row?.employee_code + idx}>
              <td className="text-center">
                <Checkbox />
              </td>
              <td>{row?.employee_code}</td>
              <td>{row?.employee_name}</td>
              <td>{row?.date_of_joining}</td>
              <td>{row?.designation_name}</td>
              <td>{row?.department_name}</td>
              <td className="text-center">{<FiEdit />}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default MasterRoleTable;
