import React, { useEffect } from 'react';
import DashboardComp from '../../Components/Dashboard/DashboardComp';
import { useSelector } from 'react-redux';
import { getDashboard } from '../../store/reducers/actionPermission';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const data = useSelector(getDashboard);

  useEffect(() => {
    if (!data?.checked) {
      navigate('/');
    }
  }, []);
  return (
    <div>
      <DashboardComp />
    </div>
  );
};

export default Dashboard;
