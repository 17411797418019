import React from 'react';
import AttendanceComp from '../../Components/Reports/Attendance/AttendanceComp';

const AttendanceReport = () => {
  return (
    <div>
      <AttendanceComp />
    </div>
  );
};

export default AttendanceReport;
