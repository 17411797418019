import React from 'react';
import { MdOutlineLockClock } from 'react-icons/md';
import profilePhoto from '../../Assets/profile.jpg';
import { NORECORD } from '../../constant/constant';
import style from '../../Styles/Dasbboard.module.css';
const LockedList = ({ lockedCount }) => {
  return (
    <section className=" bg-white px-2 rounded ms-1 shadow-sm">
      <div className={` pt-2 fw-bold  `}>Employees Locked status</div>
      <div
        className="row m-0 p-0 flex-nowrap mt-3"
        style={{ overflowX: 'auto' }}
      >
        {lockedCount.length > 0 &&
          lockedCount.map((item) => (
            <div
              className="col-lg-2 p-0 d-flex me-2 flex-column align-items-center justify-content-between mb-3 px-1 border pt-3"
              style={{ minHeight: 180 }}
            >
              <img
                src={item.viewprofilePicture || profilePhoto}
                className={`rounded-circle ${style.lockedListImg}`}
                alt=""
              />

              <div className="text-center">
                {' '}
                <div className="mb-0" title={item?.employee_name}>
                  <b>{item?.employee_name}</b>
                </div>
                <div
                  className="mb-1 tmTextOverflow"
                  title={item?.designation_name}
                >
                  {item?.designation_name || '-'}
                </div>
              </div>

              <div className="px-2 pt-1 d-flex justify-content-between align-items-center border-top mb-3 w-100">
                <div>
                  <MdOutlineLockClock size={24} color="#1b67b3" />
                </div>
                <div style={{ fontSize: 20 }}>
                  <b>{item?.lockedCount || 0}</b>
                </div>
              </div>
            </div>
          ))}
        {lockedCount.length == 0 && (
          <div style={{ minHeight: 150 }} className="d-fcc">
            {NORECORD}
          </div>
        )}
      </div>
    </section>
  );
};

export default LockedList;
