import React, { useState } from 'react';
import '../../../Styles/TimeSheetAccordian.css';
import { MdDeleteOutline } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import { Button, Form } from 'antd';
import { Row, Col, Table } from 'reactstrap';
import { Radio } from 'antd';
import { FaLock } from 'react-icons/fa6';
import style from '../../../Styles/TimeSheet.module.css';
import {
  TbCircleLetterP,
  TbCircleLetterR,
  TbSquareLetterA,
  TbSquareLetterD,
  TbSquareLetterP,
  TbSquareLetterR,
} from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { LEAVETYPE, PERMISSION_VALUE } from '../../../constant/constant';
import { useSelector } from 'react-redux';
import { getSettings } from '../../../store/reducers/actionPermission.js';

const HandleBadges = ({ item, approvalStatus, multiStatus }) => (
  <div className="d-flex justify-content-start align-items-md-center">
    <div
      className={`me-2 pt-1   ${
        approvalStatus === null
          ? ''
          : approvalStatus
          ? 'statusSuccess'
          : 'text-danger'
      }`}
    >
      <b style={{ fontSize: '12px' }}>{item.date}</b>
    </div>
    {!item.isLocked && (
      <div className="pt-1">
        <span
          className={`me-2 ${
            approvalStatus === null
              ? ''
              : approvalStatus
              ? 'statusSuccess'
              : 'text-danger'
          }`}
        >
          <b style={{ fontSize: '12px' }}>{item.day}</b>
        </span>
        <span
          className={`alert ${
            approvalStatus === null
              ? 'alert-warning'
              : approvalStatus
              ? 'alert-success'
              : 'alert-danger'
          } mb-0 py-0 px-2 rounded-pill`}
        >
          {`${
            approvalStatus === null
              ? 'Pending'
              : approvalStatus
              ? 'Approved'
              : 'Rejected'
          }`}
        </span>
        {multiStatus && (
          <span className="m-2">
            <small>
              <i>{multiStatus} by First Approver</i>
            </small>
          </span>
        )}
      </div>
    )}
    {!!item.isLocked && (
      <div>
        <span>
          <FaLock />
        </span>
      </div>
    )}
  </div>
);

const WEEKENDHOLIDAY = ['weekend', 'publicHoliday'];
const LEAVESTATUS = ['leave'];
const LEAVE_LOG_STATUS = ['fullDay', 'halfDay'];
const SHOW_MODIFIED = ['semiWorking', 'working'];

function TimeSheetAccordian({ timeLog, handleClick }) {
  const data = useSelector(getSettings);

  const handleApproval = (status) => {
    switch (status) {
      case true:
        return (
          <Button
            title="Approved"
            className={`px-0 mb-1 alert alert-success`}
            icon={<TbSquareLetterA size={20} />}
          ></Button>
        );
        break;
      case false:
        return (
          <Button
            title="Rejected"
            className={`px-0 mb-1 alert alert-danger`}
            icon={<TbSquareLetterR size={20} />}
          ></Button>
        );
        break;
      case 'null':
        return (
          <Button
            htmlType="button"
            title="Pending"
            className={`px-0 mb-1 alert alert-primary ${style.NormalBtn}`}
            icon={<TbSquareLetterP size={20} />}
          ></Button>
        );
        break;

      default:
        break;
    }
  };

  const getApprovalStatus = (item) => {
    if (
      (item.first_approvers_status === true && item.is_approved === true) ||
      (item.second_approvers_status === true && item.is_approved === true)
    ) {
      return { approvalStatus: true };
    } else if (
      item.first_approvers_status === true &&
      item.is_approved === 'null'
    ) {
      return { approvalStatus: null, multiStatus: 'Approved' };
    } else if (
      item.first_approvers_status === false &&
      item.is_approved === 'null'
    ) {
      return { approvalStatus: false, multiStatus: 'Rejected' };
    } else if (
      (item.first_approvers_status === false && item.is_approved === false) ||
      (item.second_approvers_status === false && item.is_approved === false)
    ) {
      return { approvalStatus: false };
    } else {
      return { approvalStatus: null };
    }
  };

  const colorSetter = (item, logItem) => {
    let setColor = '';
    if (WEEKENDHOLIDAY.includes(item.type)) {
      setColor = 'holidayGreen';
    } else if (LEAVESTATUS.includes(item.type)) {
      setColor = 'leaveRed';
    } else if (logItem.project_id === PERMISSION_VALUE) {
      setColor = 'permission';
    } else if (
      logItem?.leave_duration === LEAVE_LOG_STATUS[0] ||
      logItem?.leave_duration === LEAVE_LOG_STATUS[1]
    ) {
      setColor = 'leaveRed';
    }
    return style[setColor];
  };

  return (
    <>
      {timeLog.map((item, indx) => {
        const { approvalStatus, multiStatus } = getApprovalStatus(item);
        return (
          <div className={`mb-4`} key={indx}>
            <Row className="m-0">
              <Col md={12} className="py-1 px-1 header_bgcolor">
                <div className="d-flex align-items-center justify-content-between py-1">
                  <div>
                    <HandleBadges
                      item={item}
                      approvalStatus={approvalStatus}
                      multiStatus={multiStatus}
                    />
                  </div>

                  {/* <div>
                  <span className="me-2 statusSuccess">
                    <b>{item.date}</b>
                  </span>
                  <span className="me-2 statusSuccess">
                    <b>{item.day}</b>
                  </span>
                  {handleDayApproval(item)}
                </div> */}

                  {/* <div className="d-fec">
                  <div>
                    <Form.Item className="mb-0">
                      <Radio.Group>
                        <Radio value="apple"> Approve </Radio>
                        <Radio value="pear1"> Reject </Radio>
                        <Radio value="pear2"> Need to Check </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="px-1 pb-1 tmPointer">
                    <FaLock />
                  </div>
                </div> */}
                </div>
              </Col>
              <Col
                md={12}
                className={`px-0  ${!!item.isLocked ? style.tableDisable : ''}`}
              >
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <td className="col-2 px-2 border-0">
                        <b>PROJECTS</b>
                      </td>
                      <td className="col-2 px-2 border-0">
                        <b>MODULES</b>
                      </td>
                      <td className="col-3 px-2 border-0">
                        <b>TASKS</b>
                      </td>
                      <td className="col-2 px-2 border-0">
                        <b>TIME SPENT</b>
                      </td>
                      <td className="col-auto text-center border-0 px-2">
                        <b>IN HRS</b>
                      </td>
                      <td className="col-auto text-center border-0 px-2">
                        <b>TOTAL</b>
                      </td>
                      <td className="col-auto text-center border-0"></td>
                    </tr>
                  </thead>
                  <tbody className="rowsColor">
                    {item?.timesheet_data?.length > 0 &&
                      item?.timesheet_data?.map((logItem, index) => (
                        <tr key={index} className={colorSetter(item, logItem)}>
                          <td title={logItem?.project_name}>
                            {logItem?.project_name}
                          </td>
                          <td title={logItem?.module_name}>
                            {logItem?.module_name}
                          </td>
                          <td title={logItem?.task}>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="tmTextOverflow">
                                {logItem?.task}
                              </div>
                              <div
                                className={`border rounded-pill ${
                                  logItem?.billable
                                    ? style.billable
                                    : style.nonBillable
                                }`}
                              >
                                B
                              </div>
                            </div>
                          </td>
                          <td>{logItem.timeShow}</td>
                          <td className="text-center">
                            {!WEEKENDHOLIDAY.includes(item?.type) &&
                            !LEAVESTATUS.includes(item?.type)
                              ? `${logItem.individual_hours ?? 0} hr`
                              : ''}

                            {/* {logItem.individual_hours ?? 0} hr */}
                          </td>
                          {index === 0 && (
                            <td
                              className="align-middle text-center border"
                              rowSpan={100}
                            >
                              {!WEEKENDHOLIDAY.includes(item?.type) &&
                              !LEAVESTATUS.includes(item?.type)
                                ? `${item.total_hours ?? 0.0} hrs`
                                : ''}
                              {/* {item.total_hours ?? 0.0} hrs */}
                            </td>
                          )}
                          {index === 0 && (
                            <td rowSpan={100} style={{ minWidth: 50 }}>
                              {!item.isLocked && (
                                <div className="text-center d-flex flex-column justify-content-start align-items-center">
                                  {(item?.type == 'weekend' ||
                                  item?.type == 'publicHoliday'
                                    ? data?.holiday_entry
                                    : true) &&
                                    (item?.is_approved == true &&
                                    item?.first_approvers_status == true &&
                                    !['publicHoliday', 'weekend'].includes(
                                      item?.type,
                                    ) &&
                                    !['SATURDAY', 'SUNDAY'].includes(item?.day)
                                      ? false
                                      : true) &&
                                    (item?.type == 'leave' &&
                                    logItem?.leave_duration !==
                                      LEAVE_LOG_STATUS[0]
                                      ? false
                                      : true) && (
                                      <>
                                        <Button
                                          title="Edit"
                                          className="px-0 mb-1"
                                          onClick={() =>
                                            handleClick('edit', item.id)
                                          }
                                          icon={<FiEdit size={18} />}
                                        />
                                        {/* <Button
                                        title="Delete"
                                        className="px-0 mb-1"
                                        icon={<MdDeleteOutline size={20} />}
                                      /> */}
                                      </>
                                    )}
                                  {/* {logItem?.leave_duration ===
                                    LEAVE_LOG_STATUS[0] && (
                                    <span>
                                      <Button
                                        htmlType="button"
                                        title="Delete"
                                        className="px-0 mb-1"
                                        icon={<MdDeleteOutline size={20} />}
                                      ></Button>
                                    </span>
                                  )} */}

                                  {/* {handleApproval(item?.is_approved)} */}
                                </div>
                              )}
                              {!!item.isLocked && <div>&nbsp;</div>}
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
              <Col md={12} className="px-0 ">
                <div className="row">
                  <div className="col-md-12">
                    {SHOW_MODIFIED.includes(item?.type) && (
                      <div
                        style={{
                          color: '#a39f9f',
                          fontWeight: 700,
                          fontStyle: 'italic',
                          fontSize: 12,
                        }}
                        className="col-md-3"
                      >
                        <div className="mb-1">
                          Submitted:{' '}
                          <span style={{ color: '#777' }}>
                            {item?.createdAt}
                          </span>
                        </div>
                        <div>
                          Modified:{' '}
                          <span style={{ color: '#777' }}>
                            {item?.updatedAt}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              {/* <Col md={12} className="px-0 mt-2">
              <div className="row d-fec mx-0">
                {dailyHrsList.map((item, index) => (
                  <div className="col-md-3 pe-0 d-fec" key={index}>
                    <span>{item.title}</span>
                    <span className="fs-5 ms-2">
                      <span>
                        <b className="primaryColor">10</b>
                      </span>
                      <span className="text-muted fw-bold ms-1">
                        {item.suffix}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </Col> */}
            </Row>
          </div>
        );
      })}
    </>
  );
}
export default TimeSheetAccordian;
