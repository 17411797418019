import { createSlice } from '@reduxjs/toolkit';

export const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    permissions: undefined,
  },
  reducers: {
    setPermission: (state, action) => {
      state.permissions = action.payload;
    },
    setMyTimeSheet: (state, action) => {
      state.myTimesheet = action.payload;
    },
    setdepartment: (state, action) => {
      state.department = action.payload;
    },
    setdesignation: (state, action) => {
      state.designation = action.payload;
    },
    setleave: (state, action) => {
      state.leave = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setModule: (state, action) => {
      state.module = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setUserLog: (state, action) => {
      state.userlog = action.payload;
    },
    setAttendance: (state, action) => {
      state.attendance = action.payload;
    },
    setSettingPermission: (state, action) => {
      state.settingPermission = action.payload;
    },
    setVariance: (state, action) => {
      state.variance = action.payload;
    },
    setUsersTimesheet: (state, action) => {
      state.userTimesheet = action.payload;
    },
    setMyLeave: (state, action) => {
      state.myLeave = action.payload;
    },
    setUserLeave: (state, action) => {
      state.userLeave = action.payload;
    },
    setMyPermission: (state, action) => {
      state.myPermission = action.payload;
    },
    setUserPermission: (state, action) => {
      state.userPermission = action.payload;
    },
    setNotificationHistory: (state, action) => {
      state.notificationHistory = action.payload;
    },
    setDepartmentReport: (state, action) => {
      state.departmentReport = action.payload;
    },
    setUserReport: (state, action) => {
      state.userReport = action.payload;
    },
    setDashboard: (state, action) => {
      state.dashboard = action.payload;
    },
  },
});

export const {
  setPermission,
  setMyTimeSheet,
  setdepartment,
  setdesignation,
  setleave,
  setEmail,
  setUser,
  setUserRole,
  setProject,
  setModule,
  setSettings,
  setUserLog,
  setAttendance,
  setSettingPermission,
  setVariance,
  setUsersTimesheet,
  setMyLeave,
  setUserLeave,
  setNotificationHistory,
  setDepartmentReport,
  setUserReport,
  setDashboard,
  setMyPermission,
  setUserPermission,
} = permissionSlice.actions;

export const getPermission = (state) => state.permission.permissions;
export const getMyTimeSheet = (state) => state.permission.myTimesheet;
export const getDepartment = (state) => state.permission.department;
export const getLeave = (state) => state.permission.leave;
export const getEmail = (state) => state.permission.email;
export const getUser = (state) => state.permission.user;
export const getUserRole = (state) => state.permission.userRole;
export const getProjects = (state) => state.permission.project;
export const getModules = (state) => state.permission.module;
export const getDesignation = (state) => {
  return state.permission.designation;
};
export const getSettings = (state) => {
  return state.permission.settings;
};
export const getUserLog = (state) => {
  return state.permission.userlog;
};
export const getAttendance = (state) => {
  return state.permission.attendance;
};

export const getSettingPermission = (state) => {
  return state.permission.settingPermission;
};

export const getVariance = (state) => {
  return state.permission.variance;
};
export const getUsersTimesheet = (state) => {
  return state.permission.userTimesheet;
};
export const getMyLeave = (state) => {
  return state.permission.myLeave;
};
export const getUserLeave = (state) => {
  return state.permission.userLeave;
};

export const getMyPermission = (state) => {
  return state.permission.myPermission;
};
export const getUserPermission = (state) => {
  return state.permission.userPermission;
};

export const getNotificationHistory = (state) => {
  return state.permission.notificationHistory;
};
export const getDepartmentReport = (state) => {
  return state.permission.departmentReport;
};

export const getUserReport = (state) => {
  return state.permission.userReport;
};

export const getDashboard = (state) => {
  return state.permission.dashboard;
};

const extractAction = () => {};

export function setSettingsData(data) {
  return async function (dispatch) {
    dispatch(setSettings(data));
  };
}

export function getCheckPermission(data) {
  return async function (dispatch) {
    for (const element of data) {
      if (element?.statename == 'settings') {
        dispatch(setSettingPermission(element));
      }
      if (element?.statename == 'userlog') {
        dispatch(setUserLog(element));
      }
      if (element?.statename == 'dashboard') {
        dispatch(setDashboard(element));
      }

      if (element?.children) {
        for (const children of element?.children) {
          switch (children?.statename) {
            case 'myTimesheet':
              dispatch(setMyTimeSheet(children));
              break;
            case 'department':
              dispatch(setdepartment(children));
              break;
            case 'designation':
              dispatch(setdesignation(children));
              break;
            case 'leave':
              dispatch(setleave(children));
              break;
            case 'emailNotification':
              dispatch(setEmail(children));
              break;
            case 'user':
              dispatch(setUser(children));
              break;
            case 'userRole':
              dispatch(setUserRole(children));
              break;
            case 'project':
              dispatch(setProject(children));
              break;
            case 'module':
              dispatch(setModule(children));
              break;
            case 'attendance':
              dispatch(setAttendance(children));
              break;
            case 'effortVariance':
              dispatch(setVariance(children));
              break;
            case 'usersTimeSheet':
              dispatch(setUsersTimesheet(children));
              break;
            case 'myRequest':
              dispatch(setMyLeave(children));
              break;
            case 'userRequest':
              dispatch(setUserLeave(children));
              break;
            case 'notificationHistory':
              dispatch(setNotificationHistory(children));
              break;
            case 'departmentReport':
              dispatch(setDepartmentReport(children));
              break;
            case 'userReport':
              dispatch(setUserReport(children));
              break;
            case 'myRequestPermission':
              dispatch(setMyPermission(children));
              break;
            case 'userRequestPermission':
              dispatch(setUserPermission(children));
              break;
          }
        }
      }
    }
  };
}

export default permissionSlice.reducer;
