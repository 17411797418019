import React from "react";
import { MdAdd } from "react-icons/md";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineTimer } from "react-icons/md";
import style from "../../Styles/Header.module.css";

function UserInfo() {
  return (
    <>
      <div className="dropdown">
        
        <button
          className="btn shadow-none tm_button2"
          data-bs-toggle="dropdown"
          id="dropdownMenu3"
          title="Add"
        >
          <MdAdd size={25} />
        </button>
        <ul className={`dropdown-menu ${style.profilePopup}`} aria-labelledby="dropdownMenu3">
          <li>
            <button className="dropdown-item" type="button">
              <div className={style.eventStyle}>
                <MdOutlineTimer />
                <div>
                  <p className={style.eventTitle}>Add Timesheet</p>
                </div>
              </div>
            </button>
          </li>

          <li>
            <button className="dropdown-item" type="button">
              <div className={style.eventStyle}>
                <IoPersonOutline />
                <div>
                  <p className={style.eventTitle}>Add User</p>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default UserInfo;
