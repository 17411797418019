import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

export const downloadExcel = async (downloadData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${downloadData.sheetName}`);
  worksheet.getRow(1).height = 30;
  
  // Set headers with specific widths
  worksheet.columns = [
    {
      header: 'S.No',
      key: 'sno',
      width: 10,
    },
    {
      header: 'Date',
      key: 'date',
      width: 20,
    },
    {
      header: 'Project',
      key: 'projectName',
      width: 20,
    },
    {
      header: 'Module',
      key: 'module',
      width: 20,
    },
    {
      header: 'Tasks',
      key: 'task',
      width: 50,
    },
    {
      header: 'Time(From - To)',
      key: 'from',
      width: 15,
    },
    {
      header: 'To',
      key: 'to',
      width: 15,
    },
    {
      header: 'Total Hours Worked',
      key: 'hours',
      width: 15,
    },
    {
      header: 'Remarks',
      key: 'totalHours',
      width: 30,
    },
  ];

  const emptyRow = worksheet.addRow({
    sno: '',
    date: '',
    projectName: '',
    module: '',
    task: '',
    from: '',
    to: '',
    hours: '',
    totalHours: '',
  });
  emptyRow.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DDDDDD' }, // Gray color
    };
    
    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    };
  });
  worksheet.mergeCells(`F1:G1`);

  // Add data
  downloadData['data'].forEach((entry, idx) => {
    let mergeCellArr = [];
    entry.tasks.forEach((task, index, arr) => {
      const row = worksheet.addRow({
        sno: idx + 1,
        date: index === 0 ? entry.date : '',
        projectName: task.projectName || '',
        module: task.module || '',
        task: task.task || '',
        from: task?.from || '',
        to: task?.to || '',
        hours: task.hours,
        totalHours: index === 0 ? entry.totalHours : '',
      });

      mergeCellArr.push(row.number);
      if (entry.tasks.length - 1 === index) {
        worksheet.mergeCells(
          `A${mergeCellArr[0]}:A${mergeCellArr[mergeCellArr.length - 1]}`,
        );
        worksheet.mergeCells(
          `B${mergeCellArr[0]}:B${mergeCellArr[mergeCellArr.length - 1]}`,
        );
        worksheet.mergeCells(
          `I${mergeCellArr[0]}:I${mergeCellArr[mergeCellArr.length - 1]}`,
        );
      }
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        let blockColors =
          cell?._column?._number !== 1 &&
          cell?._column?._number !== 9 &&
          cell?._column?._number !== 2;
        if (task.type === 'Permission' && blockColors) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFffeb9c' },
          };
          cell.font = {
            name: 'Times New Roman',
            size: 12,
            color: { argb: 'FF9c6500' },
          };
          cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        } else if (task.type === 'weekend' || task.type === 'publicHoliday') {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFc6efce' },
          };
          cell.font = {
            name: 'Times New Roman',
            size: 12,
            color: { argb: 'FF006120' },
          };
          cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        } else if (task.type === 'leave') {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFffc7ce' },
          };
          cell.font = {
            name: 'Times New Roman',
            size: 12,
            color: { argb: 'FF9c0006' },
          };
          cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        } else {
          cell.font = {
            name: 'Times New Roman',
            size: 12,
            color: { argb: 'FF000500' },
          };
          if (cell?._column?._number === 5) {
            cell.alignment = {
              horizontal: 'left',
              vertical: 'middle',
            };
          } else {
            cell.alignment = {
              horizontal: 'center',
              vertical: 'middle',
            };
          }
        }
        // else if(task.type === ''){}
      });
    });
    const emptyRow = worksheet.addRow({
      sno: '',
      date: '',
      projectName: '',
      module: '',
      task: '',
      from: '',
      to: '',
      hours: '',
      totalHours: '',
    });
    emptyRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDDDDD' }, // Gray color
      };

      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
    });
  });

  let mergeRow = [];

  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    if (rowNumber !== 1) {
      row.eachCell((cell) => {
        // console.log("cell",cell?._column?._number === 5 );
        // if (cell?._column?._number === 5) {
        //   cell.alignment = {
        //     horizontal: 'left',
        //     vertical: 'middle',
        //   };
        // } else {
        //   cell.alignment = {
        //     horizontal: 'center',
        //     vertical: 'middle',
        //   };
        // }
      });
    } else {
      row.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          font: {
            name: 'Times New Roman',
            size: 20,
          },
          pattern: 'solid',
          fgColor: { argb: 'FFffc000' },
        };
        cell.font = {
          name: 'Times New Roman',
          size: 12,
        };
        cell.alignment = {
          horizontal: 'center',
          vertical: 'middle',
        };
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.font = { bold: true, size: 12 };
      });
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${downloadData.fileName}`);
};

export const attendanceReport = async (downloadData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${downloadData.sheetName}`);
  worksheet.columns = [
    { header: 'S. NO', key: 'serialNumber', width: 10 },
    { header: 'EMPLOYEE ID', key: 'employeeCode', width: 30 },
    { header: 'EMPLOYEE NAME', key: 'username', width: 30 },
    { header: 'DATE OF JOINING', key: 'date_of_joining', width: 30 },
    { header: 'DESIGNATION', key: 'department_name', width: 30 },
    { header: 'DEPARTMENT', key: 'designation_name', width: 30 },
    { header: 'WEEK OFF', key: 'weekOffDays', width: 30 },
    { header: 'PRESENT', key: 'presentDays', width: 30 },
    { header: 'PUBLIC HOLIDAYS', key: 'publicHolidays', width: 30 },
    { header: 'COMPENSATARY OFF', key: 'compOffDays', width: 30 },
    { header: 'ON DUTY', key: 'onDutyDays', width: 30 },
    { header: 'EXTRA WORKING', key: 'extraWorkingHours', width: 30 },
    { header: 'CASUAL LEAVE', key: 'casualLeave', width: 30 },
    { header: 'SICK LEAVE', key: 'sickLeave', width: 30 },
    { header: 'MATERNITY LEAVE', key: 'maternityLeave', width: 30 },
    { header: 'PATERNITY LEAVE', key: 'paternityLeave', width: 30 },
    { header: 'MARRIAGE LEAVE', key: 'marriageLeave', width: 30 },
    { header: 'TOTAL', key: 'total', width: 30 },
    { header: 'LOP', key: 'LOPdays', width: 30 },
    { header: 'NMD', key: 'noMoment', width: 30 },
    { header: 'REMARKS', key: 'Remarks', width: 30 },
  ];
 

  downloadData['data'].forEach(async (item) => {
    worksheet.addRow({
      serialNumber: item?.serialNumber,
      employeeCode: item?.employeeCode,
      username: item?.username,
      date_of_joining: item?.date_of_joining,
      department_name: item?.department_name,
      designation_name: item?.designation_name,
      weekOffDays: item?.weekOffDays,
      presentDays: item?.presentDays,
      publicHolidays: item?.publicHolidays,
      compOffDays: item?.compOffDays,
      onDutyDays: item?.onDutyDays,
      extraWorkingHours: item?.extraWorkingHours,
      casualLeave: item?.casualLeave,
      sickLeave: item?.sickLeave,
      maternityLeave: item?.maternityLeave,
      paternityLeave: item?.paternityLeave,
      marriageLeave: item?.marriageLeave,
      total: item?.total,
      LOPdays: item?.LOPdays,
      noMoment: item?.noMoment,
      Remarks: item?.Remarks,
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${downloadData?.fileName}`);
};
