import React from 'react'
// import DepartReportComponent from '../../Components/Reports/departmentReportcomp/DepartReportComponent'
import DepartmentReports from "../../Components/Reports/DepartmentReport/DepartmentReport"
const DepartmentReport = () => {
  return (
    <div>
      <DepartmentReports />
    </div>
  );
}

export default DepartmentReport
