import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { Button, Form, Select, Image } from 'antd';
import style from '../../Styles/ViewProfie.module.css';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import ModalComponent from '../ReusableComponents/ModalComponent';
import HolidayListsComponent from './HolidayListsComponent';
import { useAuth } from '../../Context/AuthContext';
import UserApi from '../../Api/UserApi';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { YEARS } from '../../constant/constant';
import {
  Chart as ChartJS,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import dayjs from 'dayjs';
import ShowStatus from '../ReusableComponents/ShowStatus';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import { LeaveManageApi } from '../../Api/LeaveManageApi';
import { Leave } from '../../Api/LeaveApi';
import { DeletePopup } from '../ReusableComponents/DeletePopup';

ChartJS.register(
  CategoryScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);
const { Option } = Select;

const modalHeader = <>Public Holidays List</>;

function ViewProfileComponent() {
  let initialData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#ff8d47',
          '#114780',
          '#2E85BF',
          '#4B7E69',
          '#F8A013',
        ],
        hoverBackgroundColor: [
          '#ff8d47',
          '#114780',
          '#2E85BF',
          '#4B7E69',
          '#F8A013',
        ],
      },
    ],
  };

  const options = {
    showDatasetLabels: true,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          align: 'center',
          margin: 'left',
        },
        onClick: (e, legendItem, legend) => {
          return;
        },
      },
      datalabels: {
        display: true,
        weight: 'bold',
        color: '#fff',
        font: {
          size: 25,
        },
        formatter: (value) => {
          return value;
        },
      },
    },
    cutout: '50%',
    responsive: true,
    maintainAspectRatio: false,
  };

  const plugins = [
    {
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

        // Calculate the total value
        const totalValue = chart.data.datasets[0].data.reduce(
          (a, b) => a + b,
          0,
        );

        ctx.save();

        if (totalValue === 0) {
          // Clear the chart area
          ctx.clearRect(0, 0, chart.width, chart.height);

          // Draw the empty message
          ctx.font = 'bold 20px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#000';
          ctx.fillText('No Data Available', centerX, centerY);
        } else {
          ctx.font = 'bold 30px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#000';
          ctx.fillText(totalValue, centerX, centerY - 15);

          ctx.font = '16px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#000';
          ctx.fillText('Total', centerX, centerY + 15);
        }

        ctx.restore();
      },
    },
  ];

  const { authState, login } = useAuth();

  const [dataList, setDataList] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [state, setState] = useState(undefined);

  const [Year, setYear] = useState(dayjs().year());

  const toggleModal = () => setModalOpen(!modalOpen);

  const [chartData, setChartData] = useState(initialData);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewOpen(true);
    setPreviewImage(file.url || file.preview);
  };

  const handleRemove = async () => {
    await DeletePopup(async (canDelete) => {
      if (canDelete) {
        try {
          getprofilePicture(null);
          setFileList([]);
        } catch (error) {
          toastError(error.message);
        }
      }
    });
  };
  const handleImageChange = ({ fileList: newFileList }) => {
    if (newFileList.length === 0 || newFileList === null) return;

    const validtypes = ['image/jpeg', 'image/jpg', 'image/png'];
    let totalSizeKB = newFileList[0]?.size / Math.pow(1024, 1);

    if (totalSizeKB > 10000) {
      toastWarn('Please Upload Below 10 MB', 'Invalid');
      return;
    }
    if (validtypes.includes(newFileList[0]?.type)) {
      setFileList(newFileList);
      if (newFileList[0]?.name === fileList[0]?.name) {
        getprofilePicture(newFileList);
      }
    } else {
      toastWarn('Invalid file type. Only JPG and PNG files are allowed');
      return;
    }

    return;

    // if (event.file.status !== 'removed') {
    //   let file = event?.file;
    //   console.log(fileList[0]?.lastModified, event?.fileList[0]?.lastModified);
    //   const validtypes = ['image/jpeg', 'image/jpg', 'image/png'];

    //   let totalSizeKB = file.size / Math.pow(1024, 1);
    //   if (totalSizeKB > 10000) {
    //     toastWarn('Please Upload Below 10 MB', 'Invalid');
    //     return;
    //   }
    //   if (validtypes.includes(file?.type)) {
    //     setFileList(event?.fileList);
    //     getprofilePicture(event?.fileList);

    //   } else {
    //     toastWarn('Invalid file type. Only JPG and PNG files are allowed');
    //   }
    // }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getProfileInfo = async (profileID) => {
    try {
      let data = {
        id: profileID,
      };
      const response = await UserApi.getProfile(data);
      if (response?.data?.success) {
        setState(response?.data?.result);

        if (response?.data?.result?.profilePicture) {
          setFileList([...response?.data?.result?.profilePicture]);
        }
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const getLeaveChart = async () => {
    try {
      const response = await LeaveManageApi.getChart({
        id: authState.id,
        year: Year,
      });
      if (response?.data?.success) {
        let obj = { ...initialData };
        obj.labels = Object.keys(response?.data?.result);
        obj.datasets[0].data = Object.values(response?.data?.result);
        const totalValue = obj.datasets[0].data.reduce((a, b) => a + b, 0);
        if (totalValue) {
          setChartData(obj);
        } else {
          setChartData(initialData);
        }
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const getHolidayList = async () => {
    try {
      const data = { year: Year };
      let response = await Leave.getPublicHolidayList(data);
      if (response?.data?.success) {
        let res;
        res = response.data.result || [];
        setDataList(res);
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      toastError(error?.message);
    }
  };

  const getprofilePicture = async (file) => {
    const formData = new FormData();

    formData.append('id', authState.id);

    formData.append('profilePicture', file ? file[0].originFileObj : null);

    // formData.append('profilePicture', file);

    try {
      const response = await UserApi.profilepicture(formData);

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        getProfileInfo(authState.id);
        await login({ ...response?.data?.result });
        return;
      }
    } catch (error) {
      toastError(error?.message);
    }
  };
  useEffect(() => {
    getProfileInfo(authState.id);
    getLeaveChart();
    getHolidayList();
  }, [Year]);

  return (
    <Fragment>
      <div>
        <Row className="">
          <Col className=" " lg={6}>
            <Row className={`m-0 ${style.row}`}>
              <Col className="px-0 fw-bold h5">Profile</Col>
            </Row>
            <div className="antCardControl">
              <Card
                className="w-100  pb-5"
                title=""
                style={{
                  height: 468,
                  marginTop: 3.9,
                }}
              >
                <div className={`mt-3 ms-md-4 ms-2`}>
                  <span
                    className={`border alert alert-success m-0 p-0  px-2 rounded-pill`}
                  >
                    {state?.userRole_name ?? '-'}
                  </span>
                </div>
                <Row style={{}} className=" m-0 mt-md-0 mt-2 p-0">
                  <Col>
                    <div className={`   ${style}`}>
                      <div style={{ justifyContent: 'center' }}>
                        {/* <ImgCrop rotationSlider cropShape="round">
                          <Upload
                            className="pe-2 d-flex justify-content-center"
                            style={{ border: 'none' }}
                            listType="picture-circle"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleImageChange}
                            onRemove={handleRemove}
                            showUploadList={{
                              showRemoveIcon: true,
                              showPreviewIcon: true,
                            }}
                            itemRender={(originNode, file) => {
                              return React.cloneElement(originNode, {
                                title: '',
                                style: { border: 'none' },
                              });
                            }}
                            // beforeUpload={(file) => {
                            //   return false;
                            // }}
                            customRequest={({ onSuccess }) => onSuccess('ok')}
                          >
                            {fileList.length < 1 && <PlusOutlined />}
                          </Upload>
                        </ImgCrop>
                        {fileList?.length > 0 && (
                          <Image
                            wrapperStyle={{
                              display: 'none',
                            }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) =>
                                setPreviewOpen(visible),
                            }}
                            // src={fileList[0].url || fileList[0].preview}
                            src={fileList[0].url || fileList[0].preview}
                          />
                        )} */}

                        <div className="profilename  mt-md-0 mt-1 text-center "></div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <table>
                  <tbody>
                    <tr>
                      <td colSpan={100}>
                        <div className="d-fcc">
                          <ImgCrop rotationSlider cropShape="round">
                            <Upload
                              className=""
                              style={{ border: 'none' }}
                              listType="picture-circle"
                              fileList={fileList}
                              onPreview={handlePreview}
                              onChange={handleImageChange}
                              onRemove={handleRemove}
                              showUploadList={{
                                showRemoveIcon: true,
                                showPreviewIcon: true,
                              }}
                              itemRender={(originNode, file) => {
                                return React.cloneElement(originNode, {
                                  title: '',
                                  style: { border: 'none' },
                                });
                              }}
                              // beforeUpload={(file) => {
                              //   return false;
                              // }}
                              customRequest={({ onSuccess }) => onSuccess('ok')}
                            >
                              {fileList.length < 1 && <PlusOutlined />}
                            </Upload>
                          </ImgCrop>
                          {fileList?.length > 0 && (
                            <Image
                              wrapperStyle={{
                                display: 'none',
                              }}
                              preview={{
                                visible: previewOpen,
                                onVisibleChange: (visible) =>
                                  setPreviewOpen(visible),
                              }}
                              // src={fileList[0].url || fileList[0].preview}
                              src={fileList[0].url || fileList[0].preview}
                            />
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <span className="fw-bold">{state?.username}</span>
                        <br />
                        <span>{state?.designation_name}</span>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <span className={`  ${style.start}`}>Email</span>
                      </td>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <p className={`tmTextOverflow ${style.end}`}>
                          {state?.email ?? '-'}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <span className={`  ${style.start}`}>Phone Number</span>
                      </td>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <p className={`tmTextOverflow ${style.end}`}>
                          {state?.phone ?? '-'}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <span className={`  ${style.start}`}>Department</span>
                      </td>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <p className={`tmTextOverflow ${style.end}`}>
                          {state?.department_name ?? '-'}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <span className={`  ${style.start}`}>
                          Date of Birth
                        </span>
                      </td>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <p className={`tmTextOverflow ${style.end}`}>
                          {state?.date_of_birth ?? '-'}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <span className={`  ${style.start}`}>Status</span>
                      </td>
                      <td style={{ verticalAlign: 'top', padding: '5px' }}>
                        <div className={`${style.Activecolor}`}>
                          <ShowStatus
                            status={
                              [state?.date_of_birth] ? 'Active' : 'InActive'
                            }
                          />
                        </div>
                        <span>
                          <sup>
                            <i>from</i> {state?.date_of_joining ?? '-'}
                          </sup>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* <Row className="mt-3 pt-0 pt-md-3">
                  <Row className={`${style.testmargin}`}>
                    
                    <Col>
                      <div className="d-flex">
                        <div className={`  ${style.start}`}>
                          <div>Email</div>
                        </div>
                        <div className={` ${style.end}`}>
                          <div>{state?.email ?? '-'}</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={`d-flex ${style.forPhone}`}>
                        <div className={`  ${style.start}`}>
                          <p>Phone Number</p>
                        </div>
                        <div className={` ${style.end}`}>
                          <p>{state?.phone ?? '-'}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex  mt-1  ">
                        <div className={`  ${style.start}`}>
                          <p>Department</p>
                        </div>
                        <div className={` ${style.end}`}>
                          <p>{state?.department_name ?? '-'}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex mt-1  ">
                        <div className={`  ${style.start}`}>
                          <p>Date of Birth</p>
                        </div>
                        <div className={` ${style.end}`}>
                          <p>{state?.date_of_birth ?? '-'}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex  mt-1   ">
                        <div className={`  ${style.start}`}>
                          <p>Status</p>
                        </div>
                        <div className={` ${style.end}`}>
                          <div className={`${style.Activecolor}`}>
                            <ShowStatus
                              status={
                                [state?.date_of_birth] ? 'Active' : 'InActive'
                              }
                            />
                          </div>
                          <span>
                            <sup>
                              <i>from</i> {state?.date_of_joining ?? '-'}
                            </sup>
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Row> */}
              </Card>
            </div>
          </Col>
          <Col className=" " lg={6}>
            <Row className={` px-0 m-0  mt-1  ${style.row}`}>
              <Col md={9} className="fw-bold px-0    h5">
                Leave
              </Col>

              {/* <Form.Item>
                  <Select
                    options={YEARS}
                    type="select"
                    placeholder="Select"
                    defaultValue={dayjs().year()}
                    onChange={(e) => setYear(e)}
                  />
                </Form.Item> */}

              <Col className="px-0 display-none display-md-block " md={3}>
                <div>
                  <Form.Item>
                    <Select
                      options={YEARS}
                      type="select"
                      placeholder="Select"
                      defaultValue={dayjs().year()}
                      onChange={(e) => setYear(e)}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Card
              style={{
                height: 402,
              }}
            >
              {chartData?.datasets[0]?.data.length > 0 && (
                <Row>
                  <div className="d-flex justify-content-center mt-3 mt-md-5">
                    <div style={{ width: '290px', height: '290px' }}>
                      <Doughnut
                        data={chartData}
                        options={options}
                        plugins={plugins}
                      />
                    </div>
                  </div>
                </Row>
              )}
            </Card>
            <Row className="mt-1">
              <Col className="mt-2">
                <Card className="py-2">
                  <Row className="mx-0">
                    <Col md={6} className="fw-bold h5 mt-1">
                      Public Holidays
                    </Col>

                    <Col
                      md={6}
                      className="me-md-auto d-flex justify-content-between justify-content-md-end align-items-center col-12 col-md-6"
                    >
                      <div className={` px-2  ${style.teen} me-md-2 rounded`}>
                        {dataList?.length}
                      </div>
                      <div className={`d-flex me-md-0  ${style.viewAll}`}>
                        <Button
                          type="primary"
                          size="medium"
                          className="text-center  antdBtn"
                          onClick={toggleModal}
                        >
                          <ModalComponent
                            size="lg"
                            modalOpen={modalOpen}
                            toggleModal={toggleModal}
                            modalHeader={modalHeader}
                            modalBody={
                              <HolidayListsComponent
                                Year={Year}
                                dataList={dataList}
                              />
                            }
                          />
                          View all
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default ViewProfileComponent;
