import React, { createContext, useState, useContext } from 'react';
import { setAuthInfo, getAuthInfo } from './AuthSessionStorage';
import { encryptionKey, decryptData, encryptData } from '../helpers/encrypt.js';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  let authInfo = getAuthInfo();

  const handleDecrypt = () => {
    try {
      const data = decryptData(authInfo, encryptionKey);
      return data;
    } catch (error) {
      localStorage.removeItem('authInfo');
    }
  };

  const decrypted = authInfo.length ? handleDecrypt : [];

  const [authState, setAuthState] = useState(decrypted);

  const login = (userData) => {
    setAuthState(userData);
    const encrypted = encryptData(userData, encryptionKey);
    setAuthInfo(encrypted);
  };

  const logout = () => {
    setAuthState([]);
  };

  return (
    <AuthContext.Provider value={{ ...authState, login, logout, authState }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
