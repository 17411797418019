import { Select } from 'antd';
import React, { Fragment } from 'react';

const DashboardFilters = ({ filterArray, handleFilterChange }) => {
  return (
    <>
      {filterArray.map((filtr, index) => (
        <div className="ms-lg-2" key={index + 33}>
          <Select
            showSearch
            allowClear={filtr.allowClear}
            mode={filtr.mode}
            size={filtr.size}
            maxTagCount={filtr.maxTagCount}
            placeholder={filtr.placeholder}
            optionFilterProp={filtr.optionFilterProp}
            // style={{ width: '100%' }}
            defaultValue={filtr.defaultValue}
            options={filtr.options}
            onChange={(e) =>
              handleFilterChange(e, filtr.controlName, filtr.input)
            }
          />
        </div>
      ))}
    </>
  );
};

export default DashboardFilters;
