import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { act } from "react";
import { Experimental_CssVarsProvider } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { Checkbox, Input } from "antd";
import { RxCaretDown } from "react-icons/rx";
import { Label } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const AddUserType = () => {
  const [state, setState] = useState([
    {
      label: "TimeSheet",
      disabled: false,
      statename: "timeSheet",
      children: [
        {
          label: "My TimeSheet",
          disabled: false,
          statename: "myTimesheet",
          actions: [
            { label: "Add", disabled: false, statename: "Add" },
            { label: "Edit", disabled: false, statename: "Edit" },
            { label: "Delete", disabled: false, statename: "Delete" },
          ],
        },
        {
          label: "Users Timesheet",
          disabled: false,
          statename: "usersTimeSheet",
          actions: [
            { label: "Add", disabled: false, statename: "Add" },
            { label: "Edit", disabled: false, statename: "Edit" },
            { label: "Delete", disabled: false, statename: "Delete" },
          ],
        },
      ],
    },
    {
      label: "User Management",
      disabled: false,
      statename: "usersManagement",
      actions: [
        { label: "Add", disabled: false, statename: "Add" },
        { label: "Edit", disabled: false, statename: "Edit" },
        { label: "Delete", disabled: false, statename: "Delete" },
      ],
    },
  ]);

  const navigate = useNavigate();

  const handleChange = (event, parentName) => {
    const { name } = event.target;

    const prevState = [...state];

    for (const parentItem of prevState) {
      if (parentItem.statename == parentName) {
        if (parentItem.actions) {
          let check = [];
          parentItem.actions.forEach((action) => {
            if (action.statename == name) {
              action.disabled = !action.disabled;
              parentItem.disabled = true;
            }
            check.push(action.disabled);
          });
          let isFalse = check.includes(true);
          if (!isFalse) {
            parentItem.disabled = false;
          }
        } else if (parentItem.children) {
          parentItem.children.forEach((childItem) => {
            if (name == childItem.statename) {
              childItem.disabled = !childItem.disabled;
              childItem.actions.forEach((action) => {
                action.disabled = childItem.disabled;
                parentItem.disabled = true;
              });
            }
          });
        }
      } else if (!parentName) {
        if (parentItem.statename == name && parentItem.children) {
          parentItem.disabled = !parentItem.disabled;
          parentItem.children.forEach((childItem) => {
            childItem.disabled = parentItem.disabled;
            childItem.actions.forEach((action) => {
              action.disabled = parentItem.disabled;
            });
          });
        } else if (parentItem.statename == name && parentItem.actions) {
          parentItem.disabled = !parentItem.disabled;
          parentItem.actions.forEach((action) => {
            action.disabled = parentItem.disabled;
          });
        }
      }
      if (parentItem.children) {
        let check = [];
        let check2 = [];
        parentItem.children.forEach((childItem) => {
          if (childItem.statename == parentName) {
            childItem.actions.forEach((action) => {
              if (action.statename == name) {
                action.disabled = !action.disabled;
                childItem.disabled = true;
                parentItem.disabled = true;
              }
              check.push(action.disabled);
            });
          }
          let isFalse = check.includes(true);
          if (!isFalse) {
            if (childItem.statename == parentName) {
              childItem.disabled = false;
            }
          }
          check2.push(childItem.disabled);
        });

        let isFalse2 = check2.length ? check2.includes(true) : true;
        if (!isFalse2) {
          parentItem.disabled = false;
        }
      }
    }
    setState(prevState);
  };

  const handleBack = () => navigate(-1);

  return (
    <>
      <div>
        <div className="d-flex  px-2 pb-1">
          <button className="btn p-0 me-2 pb-2" onClick={handleBack}>
            <span>
              <FaArrowLeft size={18} />
            </span>
          </button>
          <div>
            <h5>Add User Type</h5>
          </div>
        </div>
        <Card className="px-3 py-2">
          <Row>
            <Col sm="4">
              <div className="my-2 mb-4">
                <Label for="usertype">User Type Name</Label>
                <Input placeholder="Enter Name" />
              </div>
            </Col>
            <div className="W-100">
              <h6>Permissions</h6>
            </div>
            <Col md={4}>
              {state.map((parentItem, parentIndex) => (
                <Accordion key={parentIndex}>
                  <AccordionSummary>
                    {/* <input
                      style={{ marginRight: 10 }}
                      type="checkbox"
                      name={parentItem.statename}
                      onChange={handleChange}
                      checked={parentItem?.disabled}
                    /> */}
                    <div className="w-100 d-flex justify-content-between">
                      <span>
                        <Checkbox
                          style={{ marginRight: 10 }}
                          name={parentItem.statename}
                          onChange={handleChange}
                          checked={parentItem?.disabled}
                        />
                        {parentItem.label}
                      </span>
                      <span className="carethandler">
                        <RxCaretDown size={20} />
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {parentItem.children?.map((childItem, childIndex) => (
                      <Accordion key={childIndex}>
                        <AccordionSummary>
                          {/* <input
                            
                            type="checkbox"
                            name={childItem.statename}
                            checked={childItem.disabled}
                            onChange={(e) =>
                              handleChange(e, parentItem.statename)
                            }
                          /> */}
                          <div className="w-100 d-flex justify-content-between">
                            <span>
                              <Checkbox
                                name={childItem.statename}
                                style={{ marginRight: 10 }}
                                checked={childItem.disabled}
                                onChange={(e) =>
                                  handleChange(e, parentItem.statename)
                                }
                              />
                              {childItem.label}
                            </span>
                            <span className="carethandler">
                              <RxCaretDown size={20} />
                            </span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {childItem.actions?.map((actionItem, actionIndex) => (
                            <div
                              key={actionIndex}
                              className="rounded-1"
                              style={{
                                marginLeft: 25,
                                padding: "8px",
                                marginBottom: 3,
                                border: "1px solid #DADCDF",
                                borderRadius: "0.15rem",
                              }}>
                              {/* <input
                                style={{ marginRight: 10 }}
                                type="checkbox"
                                name={actionItem.statename}
                                checked={actionItem.disabled}
                                onChange={(e) =>
                                  handleChange(e, childItem.statename)
                                }
                              /> */}
                              <div className="w-100 d-flex justify-content-between">
                                <span>
                                  <Checkbox
                                    style={{ marginRight: 10 }}
                                    name={actionItem.statename}
                                    checked={actionItem.disabled}
                                    onChange={(e) =>
                                      handleChange(e, childItem.statename)
                                    }
                                  />
                                  {actionItem.label}
                                </span>
                              </div>
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    {parentItem.actions?.map((actionItem, actionIndex) => (
                      <div
                        className="rounded-1"
                        key={actionIndex}
                        style={{
                          marginLeft: 25,
                          padding: "8px",
                          marginBottom: 3,
                          border: "1px solid #DADCDF",
                          borderRadius: "0.15rem",
                        }}>
                        <div className="w-100 d-flex justify-content-between">
                          <span>
                            <Checkbox
                              name={actionItem.statename}
                              style={{ marginRight: 10 }}
                              type="checkbox"
                              checked={actionItem.disabled}
                              onChange={(e) =>
                                handleChange(e, parentItem.statename)
                              }
                            />
                            {actionItem.label}
                          </span>
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default AddUserType;
