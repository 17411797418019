import React from "react";
import { FiEdit } from "react-icons/fi";
import { MdAdd } from "react-icons/md";

const TmButton = (props) => {
  const { btnStyle, type, icon, label, onClick } = props;

  return (
    <>
    <button style={{minHeight:"32px",height:"32px"}} type={type} className={`d-inline btn btn-sm py-1 ${btnStyle}`} onClick={onClick}>
      <span className="d-flex align-items-center justify-content-center">
        <span>{icon}</span>
        <span>{label}</span>
      </span>
    </button>
    </>
  );
};


export default TmButton;
