import React, { useState } from 'react';

import { Button, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useForm, Controller } from 'react-hook-form';
import style from '../../Styles/SampleModal.module.css';
import climage from '../../Assets/climage.png';
import { ImDroplet } from 'react-icons/im';

import { PiChatTeardropDotsDuotone } from 'react-icons/pi';
const SampleModal = ({ modaldata, modal, setModal }) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    //console.log(data);
  };

  return (
    <div>
      <div>
        <Form onFinish={handleSubmit(onSubmit)}>
          <div className="d-flex mt-3">
            <div className={` ${style.heading}`}>FirstApprover</div>
            <div className={`   ${style.lowercontent}`}>: Vachinathan</div>
          </div>
          <div className="d-flex mt-1">
            <div className={` ${style.heading}`}>SecondApprover</div>
            <div className={`   ${style.lowercontent2}`}>: Vachinathan</div>
          </div>
          <div className="d-flex mt-1">
            <div className={` ${style.heading}`}>Add Notes</div>
          </div>
          <div className="mt-1">
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <TextArea
                  showCount
                  maxLength={40}
                  {...field}
                  placeholder="disable resize"
                  style={{ height: 90, resize: 'none' }}
                />
              )}
            />
          </div>
          <div className="mt-1">
            <div className={`  ${style.heading}`}>Note</div>
            <div className="m-1  d-flex">
              <div>
                {/* <img src={climage} /> */}
                {/* <ImDroplet /> */}
                {/* <PiChatTeardropDotsDuotone /> */}
              </div>
              <div className={`     rounded ${style.cardLike}`}>
                <div className="p-1  ">
                  <div className={` ps-1   ${style.lowercontent}`}>
                    Vachinathan
                  </div>
                  <div className={`  ps-1 ${style.lowercontent}`}>
                    Some Comments
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex m-1">
              <div>
                {/* <img src={climage} /> */}
                {/* <ImDroplet /> */}
                {/* <PiChatTeardropDotsDuotone /> */}
              </div>
              <div className={`     rounded ${style.cardLike}`}>
                <div className="p-1  ">
                  <div className={` ps-1   ${style.lowercontent}`}>
                    Vachinathan
                  </div>
                  <div className={`  ps-1 ${style.lowercontent}`}>
                    Some Comments
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex m-1">
              <div>
                {/* <img src={climage} /> */}
                {/* <ImDroplet /> */}
                {/* <PiChatTeardropDotsDuotone /> */}
              </div>
              <div className={`     rounded ${style.cardLike}`}>
                <div className="p-1  ">
                  <div className={` ps-1   ${style.lowercontent}`}>
                    Vachinathan
                  </div>
                  <div className={`  ps-1 ${style.lowercontent}`}>
                    Some Comments
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-2 antdBtn">
            <Button
              onClick={() => setModal(!modal)}
              type="default"
              size="medium"
              htmlType="button"
            >
              Cancel
            </Button>
            <Button type="primary" size="medium" htmlType="submit">
              {modaldata.hasOwnProperty('title') ? 'Update' : 'Save'}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SampleModal;
