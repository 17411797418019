import React from "react";
import MasterRoleComponent from "../../Components/Master/MasterRoleComponent";

const Master = () => {
  return (
    <div>
      <MasterRoleComponent />
    </div>
  );
};

export default Master;
