import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import TmPagination from './Pagination';
import { NORECORD, PaginationRange } from '../../constant/constant';
import ShowStatus from './ShowStatus';
import { IoEyeOutline } from 'react-icons/io5';

const inputHeader = ['action', 'checkbox'];

const TmMasterTable = (props) => {
  const { rows, columns, toggleModal, custom, handleDelete, buttonPermission } =
    props;
  const [pagination, setPagination] = useState([]);
  let columnKey = [];
  let customKey = [...custom];
  if (rows.length > 0) {
    columnKey = columns.map((item) => item.field);
  }

  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };

  return (
    <div className="icon-btn">
      <Table className="table-header-custom border" responsive>
        <thead>
          <tr>
            {columns.length > 0 &&
              columns.map((column, index) => (
                <th
                  className={`${
                    inputHeader.includes(column.field) ? 'text-center' : null
                  }`}
                  key={index + 1}
                >
                  {column.headerName}
                </th>
              ))}
            {(buttonPermission == undefined ||
              buttonPermission?.edit ||
              buttonPermission?.view ||
              buttonPermission?.delete) &&
              customKey.includes('action') && (
                <th className="text-center">ACTION</th>
              )}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((row, idx) => (
              <tr key={idx + 1} className="hoverTr">
                {columnKey.map((item, index) => (
                  <td
                    key={index + 1}
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleModal('View', row)}
                  >
                    {item !== 'status' ? (
                      <span title={row[item]}>{row[item] || '--'}</span>
                    ) : (
                      <ShowStatus status={row[item] ? 'Active' : 'InActive'} />
                    )}
                  </td>
                ))}
                {/* <td key={index + 1}>
                  {item !== "status" ? (
                    <span title={row[item]}>{row[item]}</span>
                  ) : (
                    <ShowStatus status={row[item]} />
                  )}
                </td> */}

                {(buttonPermission == undefined ||
                  buttonPermission?.edit ||
                  buttonPermission?.view ||
                  buttonPermission?.delete) &&
                  customKey.includes('action') && (
                    <td className="text-center  ">
                      <span>
                        {(buttonPermission == undefined ||
                          buttonPermission?.view) &&
                          (columns[1].field == 'department_name' ||
                          columns[1].field == 'designation_name'
                            ? true
                            : true) &&
                          (columns[1].field == 'title'
                            ? ![].includes(row.id)
                            : true) && (
                            <button
                              title="View"
                              className="btn p-0 m-0 icon-hover"
                              onClick={() => toggleModal('View', row)}
                            >
                              {<IoEyeOutline size={18} />}
                            </button>
                          )}
                      </span>
                      <span>
                        {' '}
                        {(buttonPermission == undefined ||
                          buttonPermission?.edit) &&
                          (columns[1].field == 'department_name' ||
                          columns[1].field == 'designation_name'
                            ? true
                            : true) &&
                          (columns[1].field == 'title'
                            ? ![].includes(row.id)
                            : true) && (
                            <button
                              title="Edit"
                              className="btn p-0 m-0 icon-hover"
                              onClick={() => toggleModal('Edit', row)}
                            >
                              {<FiEdit />}
                            </button>
                          )}
                      </span>

                      <span>
                        {(buttonPermission == undefined ||
                          buttonPermission?.delete) &&
                          (columns[1].field == 'department_name' ||
                          columns[1].field == 'designation_name'
                            ? row.id !== 1
                            : true) &&
                          (columns[1].field == 'title'
                            ? row?.departmentsID?.length !== 0 &&
                              row?.departmentsID
                            : true) && (
                            <button
                              className="btn p-0 m-0 icon-hover"
                              title="Delete"
                            >
                              <MdDeleteOutline
                                size={18}
                                onClick={() => handleDelete(row.id)}
                              />
                            </button>
                          )}
                      </span>
                    </td>
                  )}
              </tr>
            ))}
        </tbody>
      </Table>
      <div>
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default TmMasterTable;
