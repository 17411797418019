import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'react-hook-form';
import { Table } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { FaRegCircleXmark } from 'react-icons/fa6';
import { toastInfo } from '../Toast/ToastComponent';
import { useParams } from 'react-router-dom';

const { Option } = Select;

let Setedited = [];

const columns = [
  { feildname: 'S. No', className: 'col-1' },
  { feildname: 'User', className: 'col-3' },
  { feildname: 'Email', className: 'col-3' },
  { feildname: 'Designation', className: 'col-3' },
  { feildname: 'Action', className: 'col-1' },
];

const TmTableInput = ({
  employee,
  form,
  projectManagers,
  setMembers,
  members,
}) => {
  const { edit_id } = useParams();
  let getTableValue = form.getFieldValue('project_members') || [];
  useEffect(() => {
    if (edit_id && members.length === 0) {
      let addMemArr = [];
      getTableValue.forEach((item) => addMemArr.push(item.id));
      setMembers(addMemArr);
    }
  }, [getTableValue]);

  const updateEmplye = employee.map((item) => {
    let newItem = {
      ...item,
      // label: item?.value,
      label: `${item?.value} (${item.designation_name})`,
      value: item.id,
      disabled: false,
    };
    if (members.length === 0) {
      return newItem;
    } else if (members.length > 0) {
      return members.includes(item?.id)
        ? { ...newItem, disabled: true }
        : newItem;
    }
  });

  const handleAdd = (add) => {
    let getTableValue = form.getFieldValue('project_members') || [];
    if (employee.length === getTableValue.length) {
      toastInfo('No Users to Add');
      return;
    }
    add();
    return;
  };

  const handleRemove = (remove, name) => {
    let removeMembers = members;
    removeMembers.splice(name, 1);
    setMembers([...removeMembers]);
    remove(name);
  };

  const handleChange = (valueId, index) => {
    let membersArr = [...members];
    membersArr[index] = valueId;
    setMembers((pre) => [...membersArr]);
    let dataToSet = employee.find((item) => valueId == item.id);
    let getTableValue = form.getFieldValue('project_members');
    Setedited = getTableValue.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          email: dataToSet?.email,
          designation_name: dataToSet?.designation_name,
        };
      }
      return item;
    });

    form.setFieldValue('project_members', Setedited);
  };

  const inputType = ['text', 'number', 'textarea'];

  return (
    <>
      <Table responsive className="border">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index} className={`${item.className}`}>
                {item.feildname}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <Form.List name="project_members">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="col-4">
                      <Form.Item
                        name={[name, 'id']}
                        style={{ marginBottom: 0 }}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Select
                          onChange={(e) => handleChange(e, index)}
                          type="select"
                          optionFilterProp="label"
                          placeholder="Select Members"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {updateEmplye?.map(
                            (element) =>
                              !projectManagers?.includes(element?.id) && (
                                <Option
                                  key={element.value}
                                  value={element.value}
                                  disabled={element?.disabled}
                                >
                                  {element.label}
                                </Option>
                              ),
                          )}

                          {/* {updateEmplye?.map((element) => (
                            <Option
                              key={element.value}
                              value={element.value}
                              disabled={element?.disabled}
                            >
                              {element.label}
                            </Option>
                          ))} */}
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      <div
                        style={{
                          overflowX: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          maxWidth: 150,
                        }}
                        title={
                          form?.getFieldValue('project_members')[index]?.email
                        }
                      >
                        {form?.getFieldValue('project_members')[index]?.email}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          overflowX: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          maxWidth: 150,
                        }}
                        title={
                          form?.getFieldValue('project_members')[index]
                            ?.designation_name
                        }
                      >
                        {
                          form?.getFieldValue('project_members')[index]
                            ?.designation_name
                        }
                      </div>
                    </td>
                    <td className="text-center">
                      <button type="button" className="btn m-0 p-0">
                        <FaRegCircleXmark
                          color="red"
                          onClick={() => handleRemove(remove, name)}
                          size={16}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={100} className="py-1">
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        type="dashed"
                        onClick={() => handleAdd(add)}
                        icon={<PlusOutlined />}
                      >
                        Add Members
                      </Button>
                    </Form.Item>
                  </td>
                </tr>
              </>
            )}
          </Form.List>
        </tbody>
      </Table>
    </>
  );
};

export default TmTableInput;
