import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import TmPagination from '../../ReusableComponents/Pagination';
import { NORECORD, PaginationRange } from '../../../constant/constant';
import { IoEyeOutline } from 'react-icons/io5';
import { Dropdown } from 'antd';
import style from '../../../Styles/Department.module.css';
const DepartmentReportTable = (props) => {
  const [pagination, setPagination] = useState([]);
  const { rows, columns, handleclick } = props;

  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };
  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  return (
    <div>
      <Table responsive className="mt-2">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index}>{item.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((item, idx) => (
              <tr
                className={`${style.hoverTr}`}
                style={{ cursor: 'pointer' }}
                onClick={() => handleclick(item, 'view')}
                // className={`${item?.deleteStatus ? style.disable : ''}`}
                key={idx}
              >
                <td>{[item.serialNo || '--']} </td>
                <td> {[item.department_name || '--']}</td>

                <td className="cursor-pointer align-middle moduleTable">
                  <div className="d-flex align-items-center ">
                    <div className=" d-flex align-items-center ">
                      {item?.department_head.map(
                        (element, indx) =>
                          (indx === 0 || indx === 1) && (
                            <span
                              key={indx + 99}
                              className=" alert alert-primary mb-0 p-1 py-0 me-1 d-inline-block"
                              title={element}
                              style={{
                                width: `${
                                  item?.department_head?.length === 1
                                    ? '100%'
                                    : '55px'
                                }`,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                              }}
                            >
                              {element}
                            </span>
                          ),
                      )}
                    </div>
                    <div>
                      {item?.department_head.length > 2 && (
                        <Dropdown
                          onClick={(e) => e.stopPropagation()}
                          menu={{
                            items: item?.department_head.map((item, indx) => ({
                              key: indx,
                              label: (
                                <div
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {item}
                                </div>
                              ),
                            })),
                          }}
                          placement="bottomRight"
                          arrow
                        >
                          <span className="text-decoration-underline text-primary tmPointer">
                            See More
                          </span>
                        </Dropdown>
                      )}
                    </div>
                    {item?.department_head.length <= 0 && '--'}
                  </div>
                </td>
                <td> {[item.no_of_projects || '--']}</td>
                <td> {[item.no_of_users || '--']}</td>
                <td> {[item.pendingCount || '--']}</td>
                <td> {[item.approveCount || '--']}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className='pt-1 mt-md-0'>
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default DepartmentReportTable;
