import { width } from '@mui/system';
import {
  Checkbox,
  Form,
  Input,
  Select,
  TimePicker,
  Button,
  InputNumber,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import moment from 'moment';
import dayjs from 'dayjs';
import UserApi from '../../Api/UserApi.js';
import { toastError, toastInfo, toastSuccess } from '../Toast/ToastComponent';
import Settings from '../../Api/settingsApi.js';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSettingPermission } from '../../store/reducers/actionPermission.js';
// import Mandatory from "../ReusableComponents/Mandatory";

const customizeRequiredMark = (label, { required }) => (
  <>
    {label}
    {required && <></>}
  </>
);

let initialValues = {
  isLock: true,
  lockDays: 2,
  holiday_entry: true,
  notify_leave_action: true,
  notify_leave_request: true,
  notify_timesheet_action: true,
  isSingleApprover: false,
  trigger_email: true,
  triggerTime: undefined,
  isSessionExpire: true,
  sessionHours: 5,
  // leave_bcc: [],
  // leave_cc: [],
  // timsheet_bcc: [],
  // timsheet_cc: [],
};

const { Option } = Select;

const SettingsComp = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const data = useSelector(getSettingPermission);

  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const [userList, setUserList] = useState([]);

  const [settingsId, setSettingId] = useState(undefined);

  // let timsheet_bcc = Form.useWatch('timsheet_bcc', form);

  // let timsheet_cc = Form.useWatch('timsheet_cc', form);

  // let leave_bcc = Form.useWatch('leave_bcc', form);

  // let leave_cc = Form.useWatch('leave_cc', form);

  const onSubmit = async (data) => {
    try {
      if (data?.trigger_email && !data?.triggerTime) {
        toastInfo('Please select the trigger time.');
        return;
      } else if (data?.isLock && data?.lockDays == null) {
        toastInfo('Please choose the days for locking.');
        return;
      }

      if (data?.isSessionExpire && !data?.sessionHours) {
        toastInfo('Please select the Session Expire time.');
        return;
      }

      data.cronFormat = `${data?.triggerTime?.$m} ${data?.triggerTime?.$H} * * *`;
      data.triggerTime = `${data?.triggerTime?.$H}:${data?.triggerTime?.$m}`;
      data.id = settingsId;
      const response = await Settings.update(data);
      if (response.data.success) {
        toastSuccess(response?.data?.message);
        window.location.reload();
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const getUsersEmail = async () => {
    try {
      let response = await UserApi.getEmail();
      if (response.data.success) {
        setUserList(response?.data?.result);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const getSettings = async () => {
    try {
      let response = await Settings.get();
      if (response.data.success) {
        let obj = response?.data?.result[0];
        setSettingId(obj.id);
        obj.triggerTime = dayjs(obj.triggerTime, 'HH:mm');
        form.setFieldsValue(obj);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  useEffect(() => {
    getSettings();
    getUsersEmail();
    if (data) {
      handleButtonPermission();
    }
  }, [data]);

  return (
    <div>
      <Card className="p-2 px-3">
        <div className="col-md-4">
          <h6 className="text-nowrap mb-3">Settings</h6>
        </div>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            requiredMark={customizeRequiredMark}
            initialValues={initialValues}
          >
            <Row className="m-0">
              <Col className="px-0" xs={12}>
                <Row className="m-0">
                  <Col md={'auto'} className="px-0">
                    <Form.Item
                      label=""
                      name="trigger_email"
                      valuePropName="checked"
                    >
                      <Checkbox>
                        Trigger an email notification if the timesheet is not
                        submitted before
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col md={'auto'} className="px-0">
                    <Form.Item
                      label=""
                      name="triggerTime"
                      // rules={
                      //   trigger_email && [
                      //     {
                      //       required: true,
                      //       message: 'This field is required*',
                      //     },
                      //   ]
                      // }
                    >
                      <TimePicker
                        style={{ width: '100%' }}
                        format="h:mm A"
                        placeholder="12.00 AM"
                        // use12Hours
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* <Col className="px-0" xs={4}>
                <Form.Item
                  name="timsheet_cc"
                  label="CC"
                  // rules={[
                  //   { required: true, message: 'This field is required*' },
                  // ]}
                >
                  <Select
                    // options={userList}
                    type="select"
                    style={{ width: '90%' }}
                    placeholder="Select"
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {userList?.map(
                      (element) =>
                        !timsheet_bcc?.includes(element.value) && (
                          <Option key={element.value} value={element.value}>
                            {element.label}
                          </Option>
                        ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="px-0" xs={4}>
                <Form.Item
                  name="timsheet_bcc"
                  label="BCC"
                  // rules={[
                  //   { required: true, message: 'This field is required*' },
                  // ]}
                >
                  <Select
                    // options={userList}
                    mode="multiple"
                    style={{ width: '90%' }}
                    type="select"
                    placeholder="Select"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {userList?.map(
                      (element) =>
                        !timsheet_cc?.includes(element.value) && (
                          <Option key={element.id} value={element.value}>
                            {element.label}
                          </Option>
                        ),
                    )}
                  </Select>
                </Form.Item>
              </Col> */}
              <div className="w-100"></div>
              <Col xs={12} className="px-0">
                {/* <Form.Item
                  label=""
                  name="isSingleApprover"
                  valuePropName="checked"
                >
                  <Checkbox>
                    Timesheet can be approved by a single approver.
                  </Checkbox>
                </Form.Item> */}
                <Form.Item
                  label=""
                  name="notify_timesheet_action"
                  valuePropName="checked"
                >
                  <Checkbox>
                    Notify when a timesheet is approved or rejected.
                  </Checkbox>
                </Form.Item>
                {/* <Form.Item
                  label=""
                  name="notify_timesheet_rejected"
                  valuePropName="checked"
                >
                  <Checkbox>Notify when timesheet is rejected</Checkbox>
                </Form.Item> */}
              </Col>
              {/* <div className="w-100 mt-3"></div> */}
              <Col xs={12} className="px-0 pt-0 m-0">
                <Form.Item
                  label=""
                  name="notify_leave_request"
                  valuePropName="checked"
                >
                  <Checkbox>Notify when a leave request is submitted.</Checkbox>
                </Form.Item>
              </Col>
              <Col className="px-0" xs={4}>
                {/* <Form.Item
                  name="leave_cc"
                  label="CC"
                  // rules={[
                  //   { required: true, message: 'This field is required*' },
                  // ]}
                >
                  <Select
                    mode="multiple"
                    type="select"
                    style={{ width: '90%' }}
                    placeholder="Select"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {userList?.map(
                      (element) =>
                        !leave_bcc?.includes(element.value) && (
                          <Option key={element.id} value={element.value}>
                            {element.label}
                          </Option>
                        ),
                    )}
                  </Select>
                </Form.Item> */}
              </Col>
              <Col className="px-0" xs={4}>
                {/* <Form.Item
                  name="leave_bcc"
                  label="BCC"
                  // rules={[
                  //   { required: true, message: 'This field is required*' },
                  // ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '90%' }}
                    type="select"
                    placeholder="Select"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {userList?.map(
                      (element) =>
                        !leave_cc?.includes(element.value) && (
                          <Option key={element.id} value={element.value}>
                            {element.label}
                          </Option>
                        ),
                    )}
                  </Select>
                </Form.Item> */}
              </Col>

              <Col className="px-0" xs={12}>
                {/* <Form.Item
                  label=""
                  name="notify_permission"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>Notify when permission Apply</Checkbox>
                </Form.Item> */}
                {/* <Form.Item
                  label=""
                  name="notify_leave_rejected"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>Notify when leave request is rejected</Checkbox>
                </Form.Item>   */}
                {/* <Form.Item
                  label=""
                  name="notify_permission_rejected"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>
                    Notify when Permission request is rejected
                  </Checkbox>
                </Form.Item> */}

                <div className="pt-0">
                  {' '}
                  <Form.Item
                    label=""
                    name="notify_leave_action"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox>
                      Notify when a leave request is approved or rejected.
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="pt-4"></div>
                <Form.Item
                  label=""
                  name="holiday_entry"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>
                    Allow entry of timesheets on Saturdays, Sundays, and public
                    holidays.
                  </Checkbox>
                </Form.Item>
                {/* <Form.Item
                  label=""
                  name="allow_to_unlock"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>Allow project manager to unlock timesheet</Checkbox>
                </Form.Item> */}
                {/*  */}
                <div>
                  <Row className="m-0 pt-3 mb-3 mb-md-0">
                    <Col md={'auto'} className="px-0">
                      <Form.Item
                        label=""
                        name="isLock"
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox>
                          Timesheet will be locked for the days after
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col md={'auto'} xs="auto" className="px-0">
                      <Form.Item
                        label=""
                        name="lockDays"
                        style={{ maxWidth: '100px', marginBottom: 0 }}
                        rules={[
                          {
                            type: 'number',
                            min: 0,
                            message: 'Invaild Number',
                          },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          placeholder="0"
                          step={1}
                          parser={(value) => parseInt(value, 10)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs="auto">
                      <div className="mt-1">days of no action.</div>
                    </Col>
                  </Row>
                  <Row className="m-0 pt-md-3 mb-3 mb-md-0">
                    <Col md={'auto'} className="px-0">
                      <Form.Item
                        label=""
                        name="isSessionExpire"
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox>The session will expire</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col md={'auto'} className="px-0" xs="auto">
                      <Form.Item
                        label=""
                        name="sessionHours"
                        style={{ maxWidth: '100px', marginBottom: 0 }}
                        rules={[
                          {
                            type: 'number',
                            min: 0,
                            max: 48,
                            message: 'Hours must be between 0 and 48',
                          },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          placeholder="0"
                          step={1}
                          parser={(value) => parseInt(value, 10)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs="auto">
                      <div className="mt-1">hours after logging in.</div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="text-center antdBtn mt-5">
              <Button
                type="default"
                size="medium"
                htmlType="button"
                onClick={() => navigate('/')}
              >
                Cancel
              </Button>
              {buttonPermission?.edit && (
                <Button type="primary" size="medium" htmlType="submit">
                  Update
                </Button>
              )}
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default SettingsComp;
