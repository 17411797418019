import axiosClient from './axiosClient';

const dashboardApiConfig = {
  userLockCount: '/api/dashboard/get/userLockCount',
  userTimeSheetReport: '/api/dashboard/get/userTimeSheetReport',
  overAllMonthReport: '/api/dashboard/get/overAllMonthReport',
  userProjectReport: '/api/dashboard/get/userProjectReport',
  leaveReport: '/api/dashboard/get/leaveReport',
  permissionReport: '/api/dashboard/get/permissionReport',
};

const DashboardApi = {
  lockedCount: (data) => {
    const url = dashboardApiConfig.userLockCount;
    return axiosClient.post(url, data);
  },
  timesheetReport: (data) => {
    const url = dashboardApiConfig.userTimeSheetReport;
    return axiosClient.post(url, data);
  },
  overAllReport: (data) => {
    const url = dashboardApiConfig.overAllMonthReport;
    return axiosClient.post(url, data);
  },
  userProjectReport: (data) => {
    const url = dashboardApiConfig.userProjectReport;
    return axiosClient.post(url, data);
  },
  leaveTakenReport: (data) => {
    const url = dashboardApiConfig.leaveReport;
    return axiosClient.post(url, data);
  },
  permissionTakenReport: (data) => {
    const url = dashboardApiConfig.permissionReport;
    return axiosClient.post(url, data);
  },
};

export default DashboardApi;
