import React from 'react';

import EmailHistory from '../../Components/Reports/NotificationHistory/EmailHistory';

const NotificationHistory = () => {
  return (
    <div>
      <EmailHistory />
    </div>
  );
};

export default NotificationHistory;
