import React from 'react';
import { Row, Col } from 'reactstrap';
import climage from '../../Assets/climage.png';
import Comments from '../../Assets/Comments.png';
import Rejected from '../../Assets/Rejected.png';
import approved from '../../Assets/approved.png';

import style from '../../Styles/LeaveMangment.module.css';

const LeaveLogData = ({ logData }) => {
  return (
    <div>
      {logData?.map((element, idx) => (
        <Row className=" mt-3 mb-0 mx-0">
          <Col xs={4} className="ms-2 mt-1 ">
            <div className={` d-flex mt-1 ${style['forpara']}`}>
              <div className={` fw-semibold  ${style['forpara']}`}>
                {element?.dateTime}
              </div>
            </div>
          </Col>
          <Col xs="auto" className="p-0 ">
            <div>
              {' '}
              <img
                className={`${style['CommmentImage']}`}
                src={
                  element?.type == 'Create' || !element.type
                    ? climage
                    : element?.type == 'Comment'
                    ? Comments
                    : element?.type == 'Rejected'
                    ? Rejected
                    : approved
                }
              />
            </div>

            {idx < logData?.length - 1 && (
              <div className={`    ${style['vertical']} `}></div>
            )}
          </Col>
          <Col xs={5} className="">
            <div className="mt-2">{element?.description}</div>
            <div className={`  ${style['StartDate']}`}>{element?.title}</div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default LeaveLogData;
