import React from "react";
import { AuthProvider } from "./Context/AuthContext";
import AppRouter from "./Routes/AppRouter";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import store from "./store";
import { antdConfiguration } from "./config/antdConfig";

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={antdConfiguration}
      >
        <AuthProvider>
          <>
            <AppRouter />
          </>
        </AuthProvider>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
