import React, { useState } from 'react';
import { IoPersonOutline } from 'react-icons/io5';
import { PiSignOutLight } from 'react-icons/pi';
import style from '../../Styles/Header.module.css';
import { removeAuthInfo } from '../../Context/AuthSessionStorage';
import { useAuth } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import profilePhoto from '../../Assets/profile.jpg';
import { TbPasswordFingerprint } from 'react-icons/tb';

import { DOMAIN_HOST } from '../../config/appConfig';
import ChangePassword from '../Login/ChangePassword';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
const EventHandlers = () => {
  const [modal, setModal] = useState(false);
  const { authState, logout } = useAuth();

  const handleLogout = async () => {
    removeAuthInfo();
    logout();
    window.close();
    window.open(DOMAIN_HOST, '_self');
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const navigate = useNavigate();

  return (
    <div>
      <div className={style.dropdown}>
        <img
          className="rounded-circle width height"
          data-bs-toggle="dropdown"
          id="dropdownMenu2"
          src={
            authState?.viewprofilePicture
              ? authState?.viewprofilePicture
              : profilePhoto
          }
          alt=""
        />

        <ul
          className={`dropdown-menu ${style.profilePopup}`}
          aria-labelledby="dropdownMenu2"
        >
          <li className="dropPointer">
            <div
              className="d-flex align-items-center px-3"
              // onClick={() => navigate("/View_Profile")}
            >
              <img
                className={`rounded-circle ${style.image}`}
                src={
                  authState?.viewprofilePicture
                    ? authState?.viewprofilePicture
                    : profilePhoto
                }
                alt=""
              />
              <span className="ms-2">
                <h6 className="mb-0">{authState.username}</h6>
                <small>{authState.email}</small>
              </span>
            </div>
          </li>

          <li>
            <button
              className={`dropdown-item ${style.menuItem}`}
              type="button"
              onClick={() => navigate('/View_Profile')}
            >
              <div className={style.txtContainer}>
                <IoPersonOutline />
                <div>
                  <p className={style.containerTitle}>Profile</p>
                </div>
              </div>
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              type="button"
              onClick={toggleModal}
            >
              <div className={style.txtContainer}>
                <TbPasswordFingerprint />
                <div>
                  <p className={style.containerTitle}>Change Password</p>
                </div>
              </div>
            </button>
          </li>

          <li>
            <button
              className="dropdown-item"
              type="button"
              onClick={handleLogout}
            >
              <div className={style.txtContainer}>
                <PiSignOutLight />
                <div>
                  <p className={style.containerTitle}>Logout</p>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </div>
      <>
        <AntdModalComp
          width="30%"
          toggleModal={toggleModal}
          modalOpen={modal}
          modalHeader={<>Change Password</>}
          modalBody={
            <ChangePassword
              toggleModal={toggleModal}
              modalData={{ email: authState.email }}
            />
          }
        />
      </>
    </div>
  );
};

export default EventHandlers;
