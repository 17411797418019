import axiosClient from './axiosClient';

export const Permissions = {
  get: (data) => {
    const url = `/api/permission/get/request`;
    return axiosClient.post(url, data);
  },
  getById: (id) => {
    const url = `/api/permission/get/${id}`;
    return axiosClient.post(url);
  },
  getUserList: (data) => {
    const url = `/api/permission/get/UserRequest`;
    return axiosClient.post(url, data);
  },
  view: (data) => {
    const url = `/api/permission/view`;
    return axiosClient.post(url, data);
  },
  create: (data) => {
    const url = `/api/permission/create`;
    return axiosClient.post(url, data);
  },
  edit: (data) => {
    const url = `/api/permission/update`;
    return axiosClient.post(url, data);
  },
  delete: (data) => {
    const url = `/api/permission/delete`;
    return axiosClient.post(url, data);
  },
  approve: (data) => {
    const url = `/api/permission/approve`;
    return axiosClient.post(url, data);
  },
  getChart: (data) => {
    const url = `/api/permission/get/user/chartData`;
    return axiosClient.post(url, data);
  },
};
