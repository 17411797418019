import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ModalComponent = (props) => {
  const { modalOpen, size, toggleModal, modalHeader, modalBody } = props;
  return (
    <>
      <div>
        <Modal
          returnFocusAfterClose={true}
          size={size}
          isOpen={modalOpen}
          toggle={() => toggleModal("Add", null)}
          centered={true}>
          <ModalHeader
            className="modal-header border-0 shadow-none pb-0"
            toggle={() => toggleModal("Add", null)}>
            {modalHeader}
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                height: "auto",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              onClick={(e) => e.stopPropagation()}>
              {modalBody}
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default ModalComponent;
