import React, { useEffect, useRef, useState } from 'react';
import { MdAdd, MdDeleteOutline } from 'react-icons/md';
import TmMasterTable from '../ReusableComponents/TmMasterTable';
import UserRole from '../../Api/UserRole';
import { toastError, toastSuccess, toastWarn } from '../Toast/ToastComponent';
import { useNavigate } from 'react-router-dom';
import { DeletePopup } from '../ReusableComponents/DeletePopup';
import { Button, Input } from 'antd';
import Loader from '../ReusableComponents/Loader';
import LeaveManagementTable from '../LeaveMangmentComponent/LeaveManagementTable';
import { FiEdit } from 'react-icons/fi';
import UserRoleTable from './UserRoleTable';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../store/reducers/actionPermission';
import TmFilter from '../ReusableComponents/TmFilter';
import { STATUSMAIL } from '../../constant/constant';

const columns = [
  { field: 'serialNo', headerName: 'S.NO' },
  { field: 'userRole_name', headerName: 'USER ROLE' },
  { field: 'createdAt', headerName: 'CREATED DATE' },
  { field: 'created_By_Name', headerName: 'CREATED BY' },
  { field: 'updatedAt', headerName: 'UPDATED DATE' },
  { field: 'modified_By_Name', headerName: 'UPDATED BY' },
  { field: 'status', headerName: 'STATUS' },
];

let filterValue = { status: '' };
const { Search } = Input;
const UserRoleComponent = () => {
  const debounceTimeoutRef = useRef(null);
  const filterArray = [
    {
      options: STATUSMAIL,
      optionFilterProp: 'label',
      placeholder: 'Status',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'status',
      allowClear: true,
      input: 'select',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];
  const data = useSelector(getUserRole);

  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });

  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handledata = (type, value) => {
    navigate(`/add_user_Role/${value?.id}`);
  };

  useEffect(() => {
    getUserRoleList();
    if (data) {
      handleButtonPermission();
    }
    return () => {
      filterValue = { status: '' };
    };
  }, [data]);

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };
  const getUserRoleList = async () => {
    try {
      let response = await UserRole.getUserRoleList({
        ...filterValue,
      });
      if (response?.data?.success) {
        let res;
        res = response.data.result || [];
        setUserRoles(res);
        setLoading(true);
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      toastError(error.message);
    }
  };
  const handleFilterChange = (obj, type) => {
    // filterValue = { ...filterValue, [keys]: e };
    // filterValue = obj || {};
    filterValue = { ...filterValue, ...obj } || {};
    getUserRoleList();
  };

  const callDelete = async (id) => {
    await DeletePopup(async (canDelete) => {
      if (canDelete) {
        try {
          const response = await UserRole.delete(id);
          if (response.data.success) {
            toastSuccess(response.data.message);
            getUserRoleList();
          } else {
            toastWarn(response.data.message);
          }
        } catch (error) {
          toastError(error.message);
        }
      }
    });
  };

  const actions = (
    <span>
      <button className="icon-hover btn p-0" onClick={handledata}>
        <FiEdit size={18} />
      </button>
      <button className="icon-hover btn p-0" onClick={callDelete}>
        <MdDeleteOutline size={20} />
      </button>
    </span>
  );

  return (
    <>
      <div>
        {/* <div className="d-flex pb-2 justify-content-between px-0">
          <h5 className="text-nowrap">User Role</h5>

         
        </div> */}
        <div className="d-md-flex">
          <div>
            {' '}
            <h5 className="text-nowrap">User Role</h5>
          </div>
          <div className="ms-md-auto d-flex ">
            <div className=" me-2">
              {' '}
              <Search
                allowClear
                onChange={handleSearch}
                placeholder="Search "
              />
            </div>
            <div>
              <span>
                {buttonPermission?.add && (
                  <Button
                    title="Add"
                    type="primary"
                    htmlType="button"
                    onClick={() => navigate('/add_user_Role')}
                    icon={<MdAdd size={20} />}
                  ></Button>
                )}
              </span>
            </div>
          </div>
        </div>
    

        <div>
          {' '}
          <div className="mt-2 p-0">
            <TmFilter
              filterArray={filterArray}
              handleFilterChange={handleFilterChange}
            />
          </div>
        </div>
        {loading && (
          <div className="mt-3">
            <UserRoleTable
              buttonPermission={buttonPermission}
              rows={userRoles}
              columns={columns}
              toggleModal={handledata}
              custom={['action']}
              handleDelete={callDelete}
            />
          </div>
        )}
        {!loading && <Loader />}
      </div>
    </>
  );
};
export default UserRoleComponent;
