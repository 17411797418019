import dayjs from 'dayjs';
import { DateFormat } from '../constant/constant';

export const getDatesBetween = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(dayjs(currentDate).format(DateFormat));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};
