import React from "react";
import style from "../../Styles/Report.module.css";
import { Form, Input } from "antd";

const CompetencyMatrix = () => {
  return (
    <>
      <div>
        <div className="h5 p-1">Competency Matrix</div>
        <div className="row ">
          <div className="col-12 ">
            <div className={`${style.CompetencyCard}`}>
              {" "}
              <div className="col-12 py-2 ps-3">Skills</div>{" "}
              <div className="col-12 w-25 pb-3 ps-3">
                <Form>
           
                    <div className="my-2 mb-4">
                
                 
                      <Input
                        type="text"

                 
                      />
                    </div>
              
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="row  mt-2 ">
          <div className="col-12 ">
            <div className={`${style.CompetencyCard2}`}>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Communication
              </div>{" "}
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Time Management
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Teamwork / Collaberation
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Problem Solving
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Decision Making
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Adaptability / Flexibility
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Creativity / Innovation
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Organization Skills
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Presentation Skills
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Technical Skills
              </div>
              <div className={`col-12 ps-5 ${style.CompetencySpace}`}>
                Multitasking
              </div>
              <div className={`col-12 ps-5 pb-3 ${style.CompetencySpace}`}>
                Leadership
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompetencyMatrix;
