import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Switch,
} from 'antd';
import { toastSuccess, toastError, toastInfo } from '../Toast/ToastComponent';
import style from '../../Styles/TmModule.module.css';
import dayjs from 'dayjs';
import Mandatory from '../ReusableComponents/Mandatory';
import { Row, Col } from 'reactstrap';
import Module from '../../Api/Module';
import { ProjectApi } from '../../Api/ProjectApi';
import { DateFormat, MODULE_STATUS } from '../../constant/constant';
import {
  dateFormatToDayjs,
  dayjsToDateFormat,
} from '../../ReusableFn/reusableFn';
import { useAuth } from '../../Context/AuthContext';

const { Option } = Select;
const format = 'HH:mm';

dayjs.extend(require('dayjs/plugin/buddhistEra'));

const AddModuleComponent = ({
  getData,
  modaldata,
  modal,
  setModal,
  modalHeader,
}) => {
  const [projectList, setProjectList] = useState([]);
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();
  const { authState } = useAuth();
  const [form] = Form.useForm();
  const [feildControls, setFeildControls] = useState({
    setMinDate: '',
    setMaxDate: '',
  });
  const draftModuleEdit = modalHeader?.props?.children === 'Edit Draft Module';
  const draftModuleView = modalHeader?.props?.children === 'View Draft Module';
  const moduleView =
    modalHeader?.props?.children == 'View Module' || draftModuleView;

  let initialValues = {
    project_id: null,
    module_name: null,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
    checkData: false,
    convertModule: false,
    estimated_hours: null,
    module_status: 'NOT YET STARTED',
    access_members: [],
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  const handleEditData = async () => {
    await getProjectUsers(modaldata.project_id);
    const values = {};
    for (const element in modaldata) {
      if (
        element == 'start_date' ||
        element == 'end_date' ||
        element == 'start_time' ||
        element == 'end_time'
      ) {
        values[element] = modaldata[element] ? dayjs(modaldata[element]) : '';
      } else if (element == 'access_members') {
        values[element] = modaldata[element]
          ? JSON.parse(modaldata[element])
          : [];
      } else {
        values[element] = modaldata[element];
      }
    }

    form.setFieldsValue(values);
  };

  useEffect(() => {
    getDropDown();
    if (modaldata?.hasOwnProperty('id')) {
      handleEditData();
    }
  }, [modaldata, form]);

  const handleCancel = async () => {
    form.resetFields();
    setModal(!modal);
  };

  const onSubmit = async (data) => {
    try {
      // data.start_date =
      //   data.start_date && dayjs(data.start_date).format('DD-MM-YYYY');
      // data.end_date =
      //   data.end_date && dayjs(data.end_date).format('DD-MM-YYYY');

      // if (data.access_members == undefined) {
      //   toastInfo("Please Add Access members");
      //   return;
      // }
      delete data.checkData;

      data.access_members = data.access_members
        ? JSON.stringify(data.access_members)
        : null;

      if (modaldata.hasOwnProperty('id')) {
        data.id = modaldata?.id;
      }

      if (draftModuleEdit || draftModuleView) {
        data.isDraft = data?.convertModule ? false : true;
        delete data?.convertModule;
      }

      data.user_id = authState?.id;

      const response = modaldata.hasOwnProperty('id')
        ? await Module.update(data)
        : await Module.create(data);
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        getData();
        form.resetFields();
        setModal(!modal);
        setMembers([]);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const getDropDown = async () => {
    try {
      const response = await ProjectApi.dropDown({
        id: authState.id,
        userRoleID: authState?.userRoleID,
      });
      if (response?.data?.success) {
        setProjectList(response?.data?.result);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const calculateHr = (type = 'update') => {
    const { end_date, start_date, start_time, end_time } =
      form.getFieldsValue();
    if (type == 'reset') {
      setFeildControls((pre) => ({ ...pre, setMinDate: start_date }));
      form.setFieldValue('end_date', '');
    }
    if (end_date && start_date && start_time && end_time) {
      let calDays =
        (end_date.valueOf() - start_date.valueOf()) / (1000 * 60 * 60);
      let calHrs = 24 - start_time.get('hour') + end_time.get('hour');
      let overAll = calDays + calHrs - 24;
      overAll = Number(overAll).toFixed(2);
      overAll = parseFloat(overAll);

      form.setFieldValue('estimated_hours', overAll > 0 ? overAll : 0);
    } else {
      form.setFieldValue('estimated_hours', null);
    }
  };

  const getProjectUsers = async (id) => {
    checkAll({});
    form.setFieldValue('checkData', false);
    try {
      const response = await Module.getProjectUsers(id);
      if (response?.data?.success) {
        setMembers(response?.data?.result);
        let parseData = !!modaldata?.access_members
          ? JSON.parse(modaldata?.access_members)
          : '{}';
        if (
          parseData?.length === response?.data?.result?.length &&
          modaldata.hasOwnProperty('id')
        ) {
          form.setFieldValue('checkData', true);
        }
        form.setFieldValue('access_members', undefined);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  useEffect(() => {}, []);

  const validateInput = (_, value) => {
    if (value !== value.trim() || value.includes('  ')) {
      return Promise.reject(new Error(' Extra spaces are not allowed'));
    }

    return Promise.resolve();
  };

  const checkAll = (e) => {
    if (members.length <= 0) return;
    let getMembersId = members.map((item) => item.id);
    if (!!e?.target?.checked) {
      form.setFieldValue('access_members', getMembersId);
      return;
    }
    form.setFieldValue('access_members', []);
  };

  const handleCheck = (e) => {
    let allAccessMembers = form.getFieldValue('access_members') || [];
    let countAccessMembers = e.target.checked === true ? 1 : -1;
    if (allAccessMembers?.length + 1 === members.length) {
      form.setFieldValue('checkData', true);
    } else {
      form.setFieldValue('checkData', false);
    }
  };

  const checkCustom = async (e) => {
    let value = e?.target?.checked;

    const module_name = form.getFieldValue('module_name');

    if (value == true) {
      if (modaldata.hasOwnProperty('id')) {
        let reqData = {
          module_id: modaldata?.id || 0,
          module_name: module_name,
        };

        try {
          const response = await Module.customModule(reqData);

          if (response?.data?.success) {
            toastSuccess(response?.data?.message);
            form.setFieldValue(
              'convertModule',
              response?.data?.result?.isDraft,
            );
          } else {
            toastInfo(response?.data?.message);
            form.setFieldValue(
              'convertModule',
              response?.data?.result?.isDraft,
            );
          }
        } catch (error) {
          toastError(error.message);
        }
      }
    }
  };

  return (
    <div className="addmodule1">
      <Form
        onFinish={onSubmit}
        initialValues={initialValues}
        layout="vertical"
        form={form}
        requiredMark={customizeRequiredMark}
      >
        <Row gutter={16} className="m-0">
          <Col md={6}>
            <Form.Item
              name="project_id"
              label="Project"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                onChange={(value) => getProjectUsers(value)}
                disabled={modaldata.hasOwnProperty('id')}
                // disabled={
                //   moduleView ||
                //   draftModuleEdit ||
                //   draftModuleView ||
                //   modaldata.hasOwnProperty('id')
                // }
                type="select"
                placeholder="Select"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {projectList?.map((element) => (
                  <Option key={element.value} value={element.value}>
                    {element.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="module_name"
              label="Module"
              rules={[
                { required: true, message: 'This field is required' },
                {
                  validator: validateInput,
                },
              ]}
            >
              <Input
                onChange={() => form.setFieldValue('convertModule', false)}
                disabled={moduleView}
                placeholder="Enter Module"
              />
            </Form.Item>
            <div className="row justify-content-between">
              <div className="col-6">
                <Form.Item
                  className="w-100"
                  name="start_date"
                  label="Start Date"
                >
                  <DatePicker
                    disabled={moduleView}
                    inputReadOnly={true}
                    className="w-100"
                    format={DateFormat}
                    onChange={() => calculateHr('reset')}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  className="w-100"
                  name="start_time"
                  label={<>&nbsp;</>}
                >
                  <TimePicker
                    disabled={moduleView}
                    inputReadOnly={true}
                    className="w-100"
                    format="h:mm A"
                    placeholder="12.00 AM"
                    use12Hours
                    onChange={calculateHr}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-6">
                <Form.Item className="w-100" name="end_date" label="End Date">
                  <DatePicker
                    disabled={moduleView}
                    inputReadOnly={true}
                    className="w-100"
                    minDate={feildControls.setMinDate}
                    format={DateFormat}
                    onChange={calculateHr}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  className="w-100"
                  name="end_time"
                  label={<>&nbsp;</>}
                >
                  <TimePicker
                    disabled={moduleView}
                    inputReadOnly={true}
                    className="w-100"
                    format="h:mm A"
                    placeholder="12.00 AM"
                    use12Hours
                    onChange={calculateHr}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              name="estimated_hours"
              label="Estimated Hours"
              className="w-100"
              rules={[
                {
                  type: 'number',
                  min: 0,
                  message: 'Invalid Number',
                },
              ]}
            >
              <InputNumber
                disabled={moduleView}
                className="w-100"
                placeholder="0:00"
                // min={0}
                type="number"
              />
            </Form.Item>
            <Form.Item
              label="Module Status"
              name="module_status"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                disabled={moduleView}
                options={MODULE_STATUS}
                type="select"
                placeholder="Select"
                showSearch
              />
            </Form.Item>
            {(draftModuleEdit || draftModuleView) && (
              <Form.Item name="convertModule" valuePropName="checked">
                <Checkbox
                  disabled={moduleView}
                  onChange={(e) => checkCustom(e)}
                >
                  Modify this module as a new module
                </Checkbox>
              </Form.Item>
            )}
          </Col>
          <Col md={6}>
            <div>
              <div className="d-flex ">
                <div style={{ height: 32 }}>Access</div>
                {/* members.length > 0 && */}
                {
                  <div className="ms-auto">
                    <Form.Item
                      valuePropName="checked"
                      name="checkData"
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox
                        disabled={moduleView}
                        onClick={(e) => checkAll(e)}
                      >
                        Select All
                      </Checkbox>
                    </Form.Item>
                  </div>
                }
              </div>
              <div className={`${style.addModule} border ps-3`}>
                <Form.Item name="access_members">
                  <Checkbox.Group>
                    <Row>
                      {members?.map((item, index) => (
                        <Col md={12} key={index + 33} className="mt-1">
                          <Checkbox
                            onChange={(e) => handleCheck(e)}
                            disabled={moduleView}
                            value={item.id}
                          >
                            {item.value}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </div>
          </Col>

          {!moduleView && (
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Button
                  type="default"
                  className={style['btn-space']}
                  htmlType="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  // onClick={handleSubmit(onSubmit)}
                >
                  {modaldata.hasOwnProperty('id') ? 'Update' : 'Save'}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default AddModuleComponent;
