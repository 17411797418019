import React from 'react';
import EffortVarianceComp from '../../Components/Reports/EffortVarianceComp';

const EffortVarience = () => {
  return (
    <div>
      <EffortVarianceComp />
    </div>
  );
};

export default EffortVarience;
