import React from 'react';
import ProjectComponent from '../../Components/Project/ProjectComponent';

const Project = () => {
  return (
    <>
      <ProjectComponent />
    </>
  );
};

export default Project;
