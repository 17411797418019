import React, { useState, useEffect, useRef } from 'react';
import ModalComponent from '../ReusableComponents/ModalComponent';
import { MdAdd } from 'react-icons/md';
import TmMasterTable from '../ReusableComponents/TmMasterTable';
import AddDesignation from './AddDesignation';
import Designation from '../../Api/Designation';
import { toastError, toastSuccess, toastWarn } from '../Toast/ToastComponent';
import { Button, Input, Modal } from 'antd';
import { DeletePopup } from '../ReusableComponents/DeletePopup';
import { getDesignation } from '../../store/reducers/actionPermission';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TmPagination from '../ReusableComponents/Pagination';
import { ACTIVE_STATUS, PaginationRange } from '../../constant/constant';
import Loader from '../ReusableComponents/Loader';
import { DesignationcompHeader } from '../../constant/tableHeader';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
import TmFilter from '../ReusableComponents/TmFilter';

let modalHeader;
let modalData = {};
let filterArray = [
  {
    options: ACTIVE_STATUS,
    optionFilterProp: 'label',
    placeholder: 'Status',
    order: 1,
    col: 2,
    mode: '',
    maxTagCount: '',
    defaultValue: true,
    controlName: 'status',
    allowClear: true,
    input: 'select',
  },
  {
    order: 1,
    col: 'auto',
    className: 'ms-auto pe-0',
    input: 'button',
    type: 'dashed',
    htmlType: 'button',
    text: 'Reset',
  },
];
const { Search } = Input;
const DesignationComp = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [designation, setDesignation] = useState([]);
  const [loading, setLoading] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const [filterArr, setFilterArr] = useState({ search: '', status: true });
  const permission = useSelector(getDesignation);
  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });

  useEffect(() => {
    getDesignationtList(filterArr);
    if (permission) {
      handleButtonPermission();
    }
  }, [modal, permission]);

  const handleButtonPermission = () => {
    if (!permission?.checked) {
      navigate('/');
    }
    let newObj = {};
    for (const element of permission?.actions) {
      const keyName = element?.statename?.toLowerCase();
      newObj[keyName] = element?.checked;
    }
    setButtonPermissions((prevState) => ({
      ...prevState,
      ...newObj,
      // [keyName]: element?.checked,
    }));
  };

  const handleFilterChange = (obj, type) => {
    setFilterArr((pre) => ({ ...pre, ...obj }));
    getDesignationtList({ ...filterArr, ...obj });
  };

  const getDesignationtList = async (obj) => {
    try {
      let response = await Designation.getDesignationList(obj);
      if (response?.data?.success) {
        let res;
        res = response.data.result || [];
        setDesignation(res);
        setLoading(true);
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const toggleModal = (type, value) => {
    if (type == 'Close') {
      setModal(!modal);
      return;
    }

    if (value && (type === 'Edit' || type === 'View')) {
      modalData = {
        title: value.designation_name,
        id: value.id,
        status: value.status,
      };
    } else if (type === 'Add') {
      modalData = {};
    }

    modalHeader = <span className="text-nowrap">{`${type} Designation`}</span>;
    setModal(!modal);
  };

  const callDelete = async (id) => {
    await DeletePopup(async (canDelete) => {
      if (canDelete) {
        try {
          const response = await Designation.delete(id);
          if (response.data.success) {
            toastSuccess(response.data.message);
            getDesignationtList(filterArr);
          } else {
            toastWarn(response.data.message);
          }
        } catch (error) {
          toastError(error.message);
        }
      }
    });
  };

  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      getDesignationtList({ search: value });
    }, 400);
    setFilterArr((pre) => ({ ...pre, search: value }));
  };

  return (
    <>
      <div>
        <div className="d-md-flex">
          <div>
            {' '}
            <h5 className="text-nowrap">Designations</h5>
          </div>
          <div className="ms-md-auto d-flex ">
            <div className=" me-2">
              {' '}
              <Search
                allowClear
                onChange={handleSearch}
                placeholder="Search "
                value={filterArr.search}
              />
            </div>
            <div>
              <span>
                <Button
                  title="Add"
                  type="primary"
                  htmlType="button"
                  onClick={() => toggleModal('Add', null)}
                  icon={<MdAdd size={20} />}
                ></Button>
              </span>
            </div>
          </div>
        </div>

        <div className="mt-2 mb-0">
          <TmFilter
            filterArray={filterArray}
            handleFilterChange={handleFilterChange}
          />
        </div>
        {loading && (
          <div>
            <TmMasterTable
              buttonPermission={buttonPermission}
              modaldata={modalData}
              rows={designation}
              columns={DesignationcompHeader}
              custom={['checkbox', 'action']}
              toggleModal={toggleModal}
              handleDelete={callDelete}
            />
          </div>
        )}
        {!loading && <Loader />}
      </div>
      <>
        <AntdModalComp
          width="30%"
          toggleModal={() => toggleModal('Close', null)}
          modalOpen={modal}
          modalHeader={modalHeader}
          modalBody={
            <AddDesignation
              modalHeader={modalHeader}
              modaldata={modalData}
              getDesignationtList={getDesignationtList}
              setModal={setModal}
              modal={modal}
            />
          }
        />
      </>
    </>
  );
};

export default DesignationComp;
