import axiosClient from "./axiosClient";

const Designation = {
  dropDown: () => {
    const url = `/api/designation/dropDown`;
    return axiosClient.post(url);
  },
  getDesignationList: (data) => {
    const url = `/api/designation/get`;
    return axiosClient.post(url,data);
  },
  create: (data) => {
    const url = `api/designation/create`;
    return axiosClient.post(url, data);
  },
  update: (data) => {
    const url = `api/designation/edit`;
    return axiosClient.post(url, data);
  },
  delete: (id) => {
    const url = `api/designation/delete`;
    return axiosClient.post(url, { id });
  },
};

export default Designation;
