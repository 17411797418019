import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TmPagination from '../../ReusableComponents/Pagination';
import { NORECORD, PaginationRange } from '../../../constant/constant';
import ShowStatus from '../../ReusableComponents/ShowStatus';
import { Dropdown } from 'antd';
import TmTableImage from '../../ReusableComponents/TmTableImage';
import ListMembers from '../../ReusableComponents/ListMembers';
const UserReportTable = (props) => {
  const [pagination, setPagination] = useState([]);
  const { rows, columns, handleClick } = props;

  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };
  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };
  return (
    <div>
      <Table responsive className="mt-2">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index}>{item.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((item, idx) => (
              <tr
                style={{ cursor: 'pointer' }}
                key={idx}
                className="hoverTr"
                onClick={() => handleClick(item, 'type')}
              >
                <td>{item?.serialNo || '--'} </td>
                <td>
                  <span>
                    <TmTableImage
                      itm={item?.username}
                      profilePicture={item?.profilePicture}
                    />
                  </span>
                </td>
                <td>{item?.department_name || '--'} </td>
                <td>{item?.designation_name || '--'} </td>
                <td className="cursor-pointer align-middle moduleTable">
                  {/* <div className="d-flex align-items-center ">
                    <div className=" d-flex align-items-center ">
                      {item?.projects.map(
                        (element, indx) =>
                          (indx === 0 || indx === 1) && (
                            <span
                              key={indx + 99}
                              className=" alert alert-primary mb-0 p-1 py-0 me-1 d-inline-block"
                              title={element}
                              style={{
                                width: `${
                                  item?.projects?.length === 1 ? '100%' : '55px'
                                }`,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                              }}
                            >
                              {element}
                            </span>
                          ),
                      )}
                    </div>
                    <div>
                      {item?.projects.length > 2 && (
                        <Dropdown
                          onClick={(e) => e.stopPropagation()}
                          menu={{
                            items: item?.projects.map((item, indx) => ({
                              key: indx,
                              label: (
                                <div
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {item}
                                </div>
                              ),
                            })),
                          }}
                          placement="bottomRight"
                          arrow
                        >
                          <span className="text-decoration-underline text-primary tmPointer">
                            See More
                          </span>
                        </Dropdown>
                      )}
                    </div>
                    {item?.projects.length <= 0 && '--'}
                  </div> */}
                  <ListMembers members={item?.projects} />
                </td>
                {/* <td> {item?.projects || '--'}</td> */}
                <td className="ps-3"> {item?.leaveDays || '--'}</td>
                <td> {item?.permissionDays || '--'}</td>
                <td> {item?.pendingDays || '--'}</td>
                <td> {item?.approvedDays || '--'}</td>
                <td> {item?.rejectedDays || '--'}</td>

                <td> {item?.missingDays || '--'}</td>
                <td> {item?.lockedDays || '--'}</td>
                <td>
                  <ShowStatus status={item.monthTimeSheet} />
                </td>
                <td onClick={(e) => e.stopPropagation()}>
                  <a
                    className="text-decoration-underline"
                    onClick={() => handleClick(item, 'type')}
                  >
                    View History
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className='mt-2 mt-md-0"'>
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default UserReportTable;
