import axiosClient from './axiosClient';

const EmailApi = {
  getEmailList: (data) => {
    const url = `/api/email/get`;
    return axiosClient.post(url, data);
  },
  create: (data) => {
    const url = `/api/email/create`;
    return axiosClient.post(url, data);
  },
  update: (data) => {
    const url = `/api/email/edit`;
    return axiosClient.post(url, data);
  },
  delete: (id) => {
    const url = `/api/email/delete`;
    return axiosClient.post(url, { id });
  },
  getEmailDropDown: () => {
    const url = `/api/email/dropdown`;
    return axiosClient.post(url, {});
  },
  getEmailHistory: (data) => {
    const url = `api/email/get/emailstatus`;
    return axiosClient.post(url, data);
  },
};

export default EmailApi;
