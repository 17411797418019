import axiosClient from "./axiosClient";
// /**
//   @typedef {object} loginProps
//    @property {boolean} success
//  */

const LoginApi = {
  getUsers: () => {
    const url = `/api/users/getAllUsers`;
    return axiosClient.post(url);
  },
  login: (data) => {
    const url = `/api/users/login`;
    return axiosClient.post(url, data);
  },
  // /**
  //  * 
  //  * @param {} data 
  //  * @returns {import("axios").AxiosResponse<loginProps>}
  //  */
  resetPassword: (data) => {
    const url = `api/users/password/reset`;
    return axiosClient.post(url, data);
  },
  changePassword: (data) => {
    const url = `api/users/password/change`;
    return axiosClient.post(url, data);
  },
  editAccount: (id, data) => {
    const url = `/account/${id}`;
    return axiosClient.put(url, data);
  },
  deleteAccount: (id) => {
    const url = `/account/${id}`;
    return axiosClient.delete(url);
  },
};

export default LoginApi;
