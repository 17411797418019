import React, { useState } from 'react';
import { Form, Input, Select, Button, Tooltip } from 'antd';
import {
  Controller,
  useForm,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { CiCirclePlus } from 'react-icons/ci';
import style from '../../Styles/AddUser.module.css';
import { Label, Row, Col } from 'reactstrap';
import { toastInfo } from '../Toast/ToastComponent';
import { FaRegCircleXmark } from 'react-icons/fa6';
const { TextArea } = Input;
const { Option } = Select;

const GENDER = [
  { id: 1, value: 'male', label: 'Male' },
  { id: 2, value: 'female', label: 'Female' },
  { id: 3, value: 'other', label: 'Other' },
];

const RELATIONS = [
  { id: 1, value: 'family', label: 'Family' },
  { id: 2, value: 'friend', label: 'Friend' },
  { id: 3, value: 'colleague', label: 'Colleague' },
];

const AddContact = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additional_contacts',
  });
  const text = <span> Add Contact</span>;
  const text1 = <span> Remove</span>;

  const handleAppend = (append) => {
    const [additional_contacts] = watch(['additional_contacts']);
    if (additional_contacts.length <= 1) {
      append();
      return;
    }
    toastInfo('Max 2 Contacts only allowed');
  };

  return (
    // <Form onFinish={handleSubmit(onSubmit)}>
    <div className="mt-3">
      <Row justify="space-between">
        <Col md={5} className="mx-0">
          <div className="d-flex">
            <div className={style['title-style']}>
              Additional Contact Information
            </div>
            <div>
              {' '}
              {/* <Form.Item> */}
              {/* <Tooltip placement="topRight" title={text}> */}
              <Button
                className={`border-none  px-2  ${style.defaultColor} plusIcon-btn`}
                type="none"
                onClick={() => handleAppend(append)}
              >
                <CiCirclePlus className="pe-1" size={24} />
                Add Contact
              </Button>
              {/* </Tooltip> */}
            </div>
          </div>
        </Col>
        <Col className="text-start"></Col>
      </Row>
      {fields.map((item, index) => (
        <div key={item.id}>
          <Row justify="space-between">
            <Col>
              <div className="d-flex">
                <div className={style['title-style']}>
                  Contact Information {index + 1}
                </div>
                <div>
                  <Tooltip placement="topRight" title={text1}>
                    <Button
                      type="danger"
                      className="text-danger ms-1"
                      onClick={() => remove(index)}
                      icon={<FaRegCircleXmark size={16} color="red" />}
                    ></Button>
                  </Tooltip>{' '}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <Form.Item>
                <Label>First Name</Label>
                <Controller
                  name={`additional_contacts[${index}].add_first_name`}
                  control={control}
                  defaultValue={item.add_first_name || ''}
                  render={({ field }) => (
                    <Input {...field} placeholder="Enter First Name" />
                  )}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item>
                <Label>Last Name</Label>
                <Controller
                  name={`additional_contacts[${index}].add_last_name`}
                  control={control}
                  defaultValue={item.add_last_name || ''}
                  render={({ field }) => (
                    <Input {...field} placeholder="Enter Last Name" />
                  )}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item>
                <Label>Gender</Label>
                <Controller
                  name={`additional_contacts[${index}].gender`}
                  control={control}
                  defaultValue={item.gender || null}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {GENDER.map((option) => (
                        <Option key={option.id} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item>
                <Label>Email</Label>
                <Controller
                  name={`additional_contacts[${index}].add_email`}
                  control={control}
                  defaultValue={item.add_email || ''}
                  // rules={{
                  //   pattern: {
                  //     value: /^\S+@\S+$/i,
                  //     message: "Invalid email format",
                  //   },
                  // }}
                  render={({ field }) => (
                    <Input type="email" {...field} placeholder="Enter Email" />
                  )}
                />
                {/* {errors?.additional_contacts?.[index]?.add_email && (
                  <p className="text-danger mt-1">
                    {errors.additional_contacts[index].add_email.message}
                  </p>
                )} */}
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item>
                <Label>Phone Number</Label>
                <Controller
                  name={`additional_contacts[${index}].add_phone`}
                  control={control}
                  defaultValue={item.add_phone || ''}
                  rules={{
                    pattern: {
                      value: /^\d{10}$/,
                      message: 'Phone number must be exactly 10 digits',
                    },
                  }}
                  render={({ field }) => (
                    <Input {...field} placeholder="Enter Phone Number" />
                  )}
                />
                {errors?.additional_contacts?.[index]?.add_phone && (
                  <p className="text-danger mt-1">
                    {errors.additional_contacts[index].add_phone.message}
                  </p>
                )}
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item>
                <Label>Permanent Address</Label>
                <Controller
                  name={`additional_contacts[${index}].add_permanent_address`}
                  control={control}
                  defaultValue={item.add_permanent_address || ''}
                  render={({ field }) => (
                    <TextArea {...field} placeholder="Enter Address" rows={1} />
                  )}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item>
                <Label>Relation</Label>
                <Controller
                  name={`additional_contacts[${index}].relation`}
                  control={control}
                  defaultValue={item.relation || null}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {RELATIONS.map((option) => (
                        <Option key={option.id} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      ))}
    </div>
    // </Form>
  );
};

export default AddContact;
