import axiosClient from './axiosClient';

const UserRole = {
  getUserRoleList: (data) => {
    const url = `/api/userRoles/get`;
    return axiosClient.post(url, data);
  },
  create: (data) => {
    const url = `/api/userRoles/create`;
    return axiosClient.post(url, data);
  },
  delete: (id) => {
    const url = `/api/userRoles/delete`;
    return axiosClient.post(url, { id });
  },
  update: (data) => {
    const url = `/api/userRoles/edit`;
    return axiosClient.post(url, data);
  },
  dropDownUserRole: (data) => {
    const url = `/api/userRoles/dropDown`;
    return axiosClient.post(url, data);
  },
  getMenuPermission: (data) => {
    const url = `/api/userRoles/menu/permission`;
    return axiosClient.post(url, data);
  },
};

export default UserRole;
