import { configureStore } from "@reduxjs/toolkit";
import permissionReducer from "./reducers/actionPermission.js";

const store = configureStore({
  reducer: {
    permission: permissionReducer,
  },
});

export default store;
