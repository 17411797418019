import React from 'react';
import style from '../../Styles/Dasbboard.module.css';
import { useNavigate } from 'react-router-dom';


const DashColorCards = ({ projectReport = {} }) => {
  const navigate=useNavigate()
  const cardData = {
    totalUsers: 'Employees',
    projects: 'Projects',
    billableHours: 'Billable Hrs',
    leave: 'Leave Taken',
  };
  const handleclick = (key) => {
  
    if (key === 'projects') {
      return navigate('/projects');
    } else if (key === 'billableHours' || key === 'totalUsers') {
      return navigate('/user_timesheet');
    } else if (key === 'leave') {
      return navigate('/user_requests');
    }
  }
  return (
    <>
      {Object.keys(cardData).map((key) => (
        <div key={key} style={{cursor:'pointer'}} onClick={() => handleclick(key)} className="col-6 p-0 m-0 p-1 h-50">
          <div className={`${style.dashCardParent}  h-100 rounded shadow-sm `}>
            <span className="w-25 h-25"></span>
            <div className="text-end pe-2 pb-2">
              <div>
                {key === 'billableHours' ? (
                  <>
                    <span className="fs-5">{projectReport[key] || 0}</span>
                    <small> hrs</small>
                  </>
                ) : (
                  <span className="fs-5">{projectReport[key] || 0}</span>
                )}
              </div>
              <div>{cardData[key]}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DashColorCards;

// import React from 'react';
// import style from '../../Styles/Dasbboard.module.css';
// const DashColorCards = ({ projectReport }) => {
//   return (
//     <>
//       <div className={`col-6 p-0 m-0 p-1 h-50`}>
//         <div className={`${style.dashCardParent} rounded shadow h-100`}>
//           <span className="w-25 h-25"></span>
//           <div className="text-end pe-2 pb-2">
//             <div className="fs-5">{projectReport?.totalUsers || 0}</div>
//             <div>Employees</div>
//           </div>
//         </div>
//       </div>
//       <div className={`col-6 p-0 m-0 p-1 h-50`}>
//         <div className={`${style.dashCardParent} h-100 rounded shadow`}>
//           <span className=" w-25 h-25">a</span>
//           <div className="text-end pe-2 pb-2">
//             <div className="fs-5">{projectReport?.projects || 0}</div>
//             <div>Projects</div>
//           </div>
//         </div>
//       </div>
//       <div className={`col-6 p-0 m-0 p-1 h-50`}>
//         <div className={`${style.dashCardParent} h-100 rounded shadow`}>
//           <span className=" w-25 h-25"></span>
//           <div className="text-end pe-2 pb-2">
//             <div className="fs-5">{projectReport?.billableHours || 0} hrs</div>
//             <div>Billable Hrs</div>
//           </div>
//         </div>
//       </div>
//       <div className={`col-6 p-0 m-0 p-1 h-50`}>
//         <div className={`${style.dashCardParent} h-100 rounded shadow`}>
//           <span className=" w-25 h-25"></span>
//           <div className="text-end pe-2 pb-2">
//             <div className="fs-5">{projectReport?.leave || 0}</div>
//             <div>Leave Taken</div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default DashColorCards;
