import React from 'react';
import { Table } from 'react-bootstrap';
import { NORECORD } from '../../constant/constant';
import style from '../../Styles/Dasbboard.module.css';
import TmTableImage from '../ReusableComponents/TmTableImage';

const DashboardCardView = (props) => {
  const { columns, change, rows } = props;
  const generateFeild = columns?.map((item) => item.field);

  return (
    <div className={` mt-1 `} style={{ height: '300px', overflowY: 'auto' }}>
      <Table responsive>
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th key={index} className="">
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.userResult.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {rows?.userResult?.length > 0 &&
            rows?.userResult?.map((item, index) => (
              <tr key={index}>
                {generateFeild?.map((item1, index1) => (
                  <td title={item[item1]} className="" key={index1}>
                    {item1 === 'employeeName' ? (
                      <div className="">
                        <TmTableImage
                          itm={item?.employeeName}
                          profilePicture={item?.viewprofilePicture}
                        />
                      </div>
                    ) : (
                      <div>{item[item1] || '--'}</div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DashboardCardView;
