import React, { Fragment } from "react";
import { DepartmentComponent } from "../../Components/Department/DepartmentComponent";

export const Department = () => {
  return (
    <>
      <DepartmentComponent />
    </>
  );
};
