import { Dropdown, Switch, Button } from 'antd';
import { Table } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import TmPagination from '../../Components/ReusableComponents/Pagination';
import {
  NORECORD,
  PaginationCheck,
  PaginationRange,
} from '../../constant/constant';
import { useEffect, useState } from 'react';
import { statusColorSet } from '../../ReusableFn/reusableFn';
import style from '../../Styles/Module.module.css';
import { TiPowerOutline } from 'react-icons/ti';
import { MdDoNotDisturbOnTotalSilence } from 'react-icons/md';
import ShowStatus from '../ReusableComponents/ShowStatus';
import { IoEyeOutline } from 'react-icons/io5';
import ListMembers from '../ReusableComponents/ListMembers';
const inputHeader = ['action', 'checkbox'];

const ModuleTableComponent = (props) => {
  const { rows, columns, toggleModal, handleClicks, buttonPermission } = props;
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };

  const handleDisable = (value, status, e) => {
    if (e) {
      e.stopPropagation();
    }
    handleClicks(value, status);
  };

  return (
    <div className="icon-btn">
      <Table className="table-header-custom border" responsive>
        <thead>
          <tr>
            {columns.map((column, index) =>
              column.headerName !== 'ACTION' ? (
                <th
                  key={index + 22}
                  className={`${column.className} ${
                    inputHeader.includes(column.field) ? 'text-center' : null
                  }`}
                >
                  {column.headerName}
                </th>
              ) : (
                (buttonPermission == undefined ||
                  buttonPermission?.view ||
                  buttonPermission?.edit ||
                  buttonPermission?.delete) && (
                  <th
                    key={index + 22}
                    className={`${column.className} ${
                      inputHeader.includes(column.field) ? 'text-center' : null
                    }`}
                  >
                    {column.headerName}
                  </th>
                )
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((row, idx) => (
              <tr
                style={{ cursor: 'pointer' }}
                key={idx + 56}
                onClick={() => toggleModal('View', row)}
                className={`hoverTr ${row?.deleteStatus ? style.disable : ''}`}
              >
                <td title={row?.serialNo}> {[row.serialNo]}</td>
                <td title={row?.module_name}>{row?.module_name || '--'} </td>
                <td title={row?.project_name}>{row?.project_name || '--'}</td>
                <td title={row?.estimated_hours}>
                  {row?.estimated_hours || '--'}
                </td>

                <td className="cursor-pointer align-middle moduleTable">
                  <ListMembers members={row?.access_members} />
                  {/* <div className="d-flex align-items-center ">
                    <div className=" d-flex align-items-center ">
                      {row?.access_members.map(
                        (element, indx) =>
                          (indx === 0 || indx === 1) && (
                            <span
                              key={indx + 99}
                              className=" alert alert-primary mb-0 p-1 py-0 me-1 d-inline-block"
                              title={element}
                              style={{
                                width: `${
                                  row?.access_members?.length === 1
                                    ? '100%'
                                    : '55px'
                                }`,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                              }}
                            >
                              {element}
                            </span>
                          ),
                      )}
                    </div>
                    <div>
                      {row?.access_members.length > 2 && (
                        <Dropdown
                          onClick={(e) => e.stopPropagation()}
                          menu={{
                            items: row?.access_members.map((item, indx) => ({
                              key: indx,
                              label: (
                                <div
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {item}
                                </div>
                              ),
                            })),
                          }}
                          placement="bottomRight"
                          arrow
                        >
                          <span className="text-decoration-underline text-primary tmPointer">
                            See More
                          </span>
                        </Dropdown>
                      )}
                    </div>
                    {row?.access_members.length <= 0 && '--'}
                  </div> */}
                </td>
                <td title={row?.createdAt}>{row?.createdAt}</td>
                <td title={row?.created_By_name}>
                  {row?.created_By_name ? row?.created_By_name : '--'}
                </td>

                <td title={row?.modified_By_Name}>
                  {row?.modified_By_Name ? row?.modified_By_Name : '--'}
                </td>
                <td>
                  {/* <span className={`${statusColorSet(row.module_status)}`}>
                    {row.module_status}
                  </span> */}
                  {<ShowStatus status={row.module_status} />}
                </td>
                <td className="text-center ">
                  <span>
                    {' '}
                    {!row?.deleteStatus && buttonPermission?.view && (
                      <button
                        className="btn p-0  m-0 icon-hover"
                        title="View"
                        type="button"
                        onClick={() => toggleModal('View', row)}
                      >
                        <IoEyeOutline size={18} />
                      </button>
                    )}
                  </span>
                  <span>
                    {' '}
                    {!row?.deleteStatus && buttonPermission?.edit && (
                      <button
                        className="btn m-0 p-0 icon-hover"
                        title="Edit"
                        type="button"
                        onClick={(e) => toggleModal('Edit', row, e)}
                      >
                        <FiEdit />
                      </button>
                    )}
                  </span>
                  <span>
                    {' '}
                    {row?.deleteStatus && buttonPermission?.delete && (
                      <button
                        className="btn p-0 m-0 icon-hover"
                        type="button"
                        title="Activate"
                        onClick={(e) =>
                          handleDisable(row.id, row.deleteStatus, e)
                        }
                      >
                        {' '}
                        <TiPowerOutline size={18} />
                      </button>
                    )}
                  </span>

                  <span>
                    {' '}
                    {!row?.deleteStatus && buttonPermission.delete && (
                      <button
                        className="btn p-0 m-0 icon-hover"
                        type="button"
                        title="Deactivate"
                        onClick={(e) =>
                          handleDisable(row.id, row.deleteStatus, e)
                        }
                      >
                        <MdDoNotDisturbOnTotalSilence size={20} />
                      </button>
                    )}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className='mt-2 mt-lg-0'>
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default ModuleTableComponent;
