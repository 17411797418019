import axiosClient from './axiosClient';

const Department = {
  dropDown: (data) => {
    const url = `/api/department/dropDown`;
    return axiosClient.post(url, data);
  },
  getDepartmentList: (data) => {
    const url = `/api/department/get`;
    return axiosClient.post(url, data);
  },
  create: (data) => {
    const url = `/api/department/create`;
    return axiosClient.post(url, data);
  },
  update: (data) => {
    const url = `/api/department/edit`;
    return axiosClient.post(url, data);
  },
  delete: (id) => {
    const url = `/api/department/delete`;
    return axiosClient.post(url, { id });
  },
  report: (data) => {
    const url = `/api/report/department/get`;
    return axiosClient.post(url, data);
  },
  report2: (data) => {
    const url = `/api/report/user/get`;
    return axiosClient.post(url, data);
  },
  viewActivity: (data) => {
    const url = `/api/report/user/logs`;
    return axiosClient.post(url, data);
  },
};

export default Department;
