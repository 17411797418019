import React from 'react';
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import style from '../../Styles/Dasbboard.module.css';

/**
 * @typedef {import('chart.js').ChartOptions} ChartOptions
 */
/**
 * @type {ChartOptions}
 */

const doughtnutOptions = {
  rotation: -90,
  circumference: 180,
  responsive: true,
  cutout: '70%',
  plugins: {
    legend: {
      position: 'bottom',
      fullSize: false,
      labels: {
        boxWidth: 0,
      },
    },
    // tooltip: {
    //   callbacks: {
    //     label: function (context) {
    //       let label = context.label || '';
    //       console.log("hksbdf)))))))",label);

    //       if (label) {
    //         label += ': ' + context.raw;
    //       }
    //       return label;
    //     },
    //   },
    // },
  },
};

const MonthReport = ({ overallStatus }) => {
  let approvedDays = overallStatus?.approvedDays
    ? overallStatus?.approvedDays
    : 0;

  let pendingDays = overallStatus?.pendingDays ? overallStatus?.pendingDays : 0;

  const dataForChart = [approvedDays, pendingDays];

  const doughtnutData = {
    labels: [],
    datasets: [
      {
        label: [],
        data: dataForChart || [],
        backgroundColor: ['#a7e1ca', '#fed79a'],
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="m-1 rounded bg-white shadow-sm h-100">
      <span className="fw-bold ps-lg-2 px-lg-0 px-2 pt-2 d-block">
        Overall Timesheet Status Month Wise
      </span>
      <div
        className={` d-lg-flex justify-content-lg-between ${style.StatusHight}`}
      >
        {dataForChart.every((element) => element == 0) ? (
          <div className="d-fcc w-50">No Data Found</div>
        ) : (
          <div
            className={`d-fcc ps-lg-0    ps-1 ${style.position}`}
            style={{ position: 'relative', top: 0 }}
          >
            <Doughnut data={doughtnutData} options={doughtnutOptions} />
            <div className={`text-center ${style.Total}`}>
              <span className="fw-bold fs-6">{approvedDays + pendingDays}</span>
              <br />
              Total
            </div>
          </div>
        )}

        <div
          className={` px-2 px-lg-0    d-fcc flex-column ${style.statusCardColor}`}
        >
          <p
            className={`d-flex mb-2 justify-content-lg-between justify-content-center  align-items-center   p-1 px-4 rounded ${style.monthYearres}`}
          >
            <span className="d-block">Approved</span>
            <span className="px-3 ms-1  rounded-pill d-block  text-end">
              {overallStatus?.approvedDays || 0}
            </span>
          </p>

          <p
            className={`d-flex mb-2 justify-content-lg-between justify-content-center  align-items-center   p-1 px-4 rounded ${style.monthYearres}`}
          >
            <span className="d-block">Pending</span>
            <span className="px-3  ms-1  rounded-pill d-block  text-end">
              {overallStatus?.pendingDays || 0}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MonthReport;

{
  /* <p className=" d-flex mb-2 justify-content-between align-items-center w-75 p-1 px-2 rounded">
  <span className="d-block">Rejected</span>
  <span className="px-3 rounded-pill d-block  text-end">
    {overallStatus?.rejectedDays || 0}
  </span>
</p>; */
}
