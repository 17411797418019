import React, { useEffect, useRef, useState } from 'react';
import { LuDownload } from 'react-icons/lu';
import { Button, Input } from 'antd';
import { TimeSheetApi } from '../../../Api/TimeSheetApi';
import { useAuth } from '../../../Context/AuthContext';
import TmFilter from '../../ReusableComponents/TmFilter';
import {
  EMPLOYEE_STATUS_FILTER,
  MONTHS,
  USERTIMESHEET_STATUS,
  YEARS,
} from '../../../constant/constant';
import { UserTimesheetTable } from './UserTimesheetTable';

import { toastError } from '../../Toast/ToastComponent';
import Loader from '../../ReusableComponents/Loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NoDataFound from '../../ReusableComponents/NoDataFound';
import {
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
} from '../../../ReusableFn/reusableFn';
import Department from '../../../Api/Department';
import Designation from '../../../Api/Designation';
import { useSelector } from 'react-redux';
import { getUsersTimesheet } from '../../../store/reducers/actionPermission';
const { Search } = Input;

const columns = [
  {
    field: 'employee_name',
    headerName: 'EMPLOYEE NAME',
    className: 'text-nowrap w-25',
  },
  // {
  //   field: 'emp_code',
  //   headerName: 'EMP CODE',
  //   className: 'text-nowrap',
  // },
  {
    field: 'employee_department',
    headerName: 'DEPARTMENT',
    className: 'text-nowrap',
  },
  {
    field: 'rejected_count',
    headerName: 'DAILY STATUS',
    className: 'text-nowrap',
  },
  {
    field: 'monthStatus',
    headerName: 'MONTHLY STATUS',
    className: 'text-nowrap',
  },
  // {
  //   field: 'designation',
  //   headerName: 'DESIGNATION',
  //   className: 'text-nowrap',
  // },

  {
    field: 'total_hours_worked',
    headerName: 'TOTAL HOURS WORKED',
    className: 'text-nowrap',
  },
  { field: 'billable', headerName: 'BILLABLE', className: 'text-nowrap' },
  {
    field: 'non_billable',
    headerName: 'NON BILLABLE',
    className: 'text-nowrap',
  },
  {
    field: 'leave_count',
    headerName: 'LEAVE',
    className: 'text-nowrap',
  },
  {
    field: 'permissions',
    headerName: 'PERMISSION',
    className: 'text-nowrap',
  },
  // {
  //   field: "locked_counts",
  //   headerName: "LOCKED COUNTS",
  //   className: "text-nowrap",
  // },
  {
    field: 'first_approver',
    headerName: '1ST APPROVER',
    className: 'text-nowrap',
  },
  {
    field: 'second_approver',
    headerName: '2ND APPROVER',
    className: 'text-nowrap',
  },
];
let filterValue = {
  month: generateCurrentMonth(),
  year: generateCurrentYear(),
  status: '',
  department_id: '',
  designation_id: '',
  search: '',
};
export const UserTimesheet = () => {
  const permissionData = useSelector(getUsersTimesheet);
  const debounceTimeoutRef = useRef(null);

  const [filterList, setFilterList] = useState({
    department: [],
    designation: [],
  });
  const [getData, setgetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleButtonPermission = () => {
    if (!permissionData?.checked) {
      navigate('/');
    }
  };

  useEffect(() => {
    getFilterList();
    if (permissionData) {
      handleButtonPermission();
    }
    return () => {
      filterValue = {
        month: generateCurrentMonth(),
        year: generateCurrentYear(),
        status: '',
        department_id: '',
        designation_id: '',
      };
    };
  }, [permissionData]);

  const { authState } = useAuth();
  const filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: generateCurrentMonth(),
      controlName: 'month',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: generateCurrentYear(),
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },
    {
      options: filterList.department,
      optionFilterProp: 'label',
      placeholder: 'Department',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'department_id',
      allowClear: true,
      input: 'select',
    },
    {
      options: filterList.designation,
      optionFilterProp: 'label',
      placeholder: 'Designation',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'designation_id',
      allowClear: true,
      input: 'select',
    },
    {
      options: USERTIMESHEET_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Month Status',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'status',
      allowClear: true,
      input: 'select',
    },
  ];
  const UserTimesheet = async () => {
    let reqData = {
      user_id: authState.id,
      userRoleID: authState?.userRoleID,
      ...filterValue,
    };
    try {
      const response = await TimeSheetApi.userView(reqData);

      if (response?.data.success) {
        setLoading(true);
        setgetData(response?.data?.result);
      } else {
        toastError(response?.data.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const getFilterList = async () => {
    Promise.all([await Department.dropDown({}), await Designation.dropDown({})])
      .then((resData) => {
        if (resData?.length > 0) {
          setFilterList((pre) => ({
            ...pre,
            department: resData[0].data.result || [],
            designation: resData[1].data.result || [],
          }));
        }
      })
      .catch((err) => console.log(err));
    UserTimesheet();
  };

  const handleDownload = () => {};

  const handleFilterChange = (obj, type) => {
    filterValue = { ...filterValue, ...obj } || {};
    UserTimesheet();
  };

  const handleClick = (data) => {
    let reqData = {
      isSecondApprover: data?.isSecondApprover,
      isFirstApprover: data?.isFirstApprover,
      employee_id: data?.employee_id,
      username: data?.username,
      designation_name: data?.designation_name,
      employeeCode: data?.employeeCode,
      profilePicture: data?.profilePicture,
    };

    navigate(`/my_user_timesheet/${btoa(JSON.stringify(reqData))}`);
  };

  // const handleClick = (data) => {
  //   navigate(`/my_user_timesheet/${btoa(JSON.stringify(data))}`);
  // };

  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };
  return (
    <div>
      <div className="row  m-0">
        <div className="col-md-4 px-0">
          <h5 className="text-nowrap">User Timesheet</h5>
        </div>
        <div className="col-md-3 px-0 ps-md-5" style={{ marginLeft: 'auto' }}>
          <Search allowClear onChange={handleSearch} placeholder="Search " />
        </div>

        <div className="col-9 ps-md-0 mt-2">
          <div className="row align-items-center justify-content-end">
            <div className="col-auto px-0 d-none">
              <Button
                htmlType="button"
                type="dashed"
                onClick={handleDownload}
                icon={<LuDownload size={16} />}
              >
                Download
              </Button>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <>
          <div className="row">
            {/* style={{ position: "sticky", top: -12, zIndex: 0 }} */}
            <div>
              <TmFilter
                className="d-none"
                filterArray={filterArray}
                handleFilterChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {' '}
              {getData.length > 0 && (
                <div>
                  <UserTimesheetTable
                    buttonPermission=""
                    handleClick={handleClick}
                    columns={columns}
                    rows={getData}
                  />
                </div>
              )}
              {getData.length <= 0 && <NoDataFound />}
            </div>
          </div>
        </>
      )}
      {!loading && <Loader />}
    </div>
  );
};
