import axiosClient from './axiosClient';

const NotificationAPI = {
  get: (data) => {
    const url = `/api/notification/get`;
    return axiosClient.post(url, data);
  },
  edit: (data) => {
    const url = `/api/notification/edit`;
    return axiosClient.post(url, data);
  },
  read: (data) => {
    const url = `/api/notification/read`;
    return axiosClient.post(url, data);
  },
};

export default NotificationAPI;
