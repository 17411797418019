import React, { useEffect, useState } from 'react';
import { Dropdown, Input } from 'antd';
import style from '../../Styles/Dasbboard.module.css';
import { Table } from 'react-bootstrap';
import { MdOutlineLockClock } from 'react-icons/md';
import DashboardApi from '../../Api/DashboardApi';
import { toastError, toastWarn } from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { NORECORD } from '../../constant/constant';
import TmTableImage from '../ReusableComponents/TmTableImage';

const EmployeeList = ({ filterArr }) => {
  let newFilterArr = { ...filterArr };
  const { Search } = Input;
  const { authState } = useAuth();
  const [empList, setEmpList] = useState([]);

  useEffect(() => {
    newFilterArr = { ...newFilterArr, filterArr };
    getEmpList();
  }, [filterArr]);

  const outerFn = (() => {
    let outData;
    return function innerFn() {
      clearTimeout(outData);
      outData = setTimeout(() => {
        getEmpList();
      }, 600);
    };
  })();

  const handleSearch = async (e) => {
    let value = e.target.value;
    newFilterArr = { ...newFilterArr, search: value };
    outerFn();
  };

  const getEmpList = async () => {
    let reqData = {
      ...newFilterArr,
      userRoleID: authState?.userRoleID,
      id: authState?.id,
    };

    try {
      const response = await DashboardApi.timesheetReport(reqData);
      if (response.data.success) {
        setEmpList(response.data.result);
      } else {
        toastWarn(response.data.message);
      }
    } catch (err) {
      console.log(err);
      toastError(err.message);
    }
  };

  useEffect(() => {
    getEmpList('');
  }, []);

  return (
    <div className="h-100">
      <div className={`rounded shadow-sm  px-1 bg-white pb-2 h-100`}>
        <div className="px-1 ">
          <div className={` pt-2 ${style.dashtablealign}`}>
            Employees list status
          </div>
          <div className={`mx-1 ${style.dashHorizantal}`}></div>
          <div className="mt-2">
            <Search
              size="small"
              allowClear
              placeholder="Search"
              onChange={handleSearch}
              // value={filterArr.search}
            />
          </div>
        </div>

        <div
          className={` mt-2 ${style.tableWrapper} `}
          style={{ height: '85%' }}
        >
          <Table className={` ${style.dashtable}`}>
            <thead>
              <tr className={`${style.tableHeadfixed}`}>
                <th className={`ps-1 ${style.tableHeadcolor}`}>Employee</th>
                <th
                  className="ps-3"
                  style={{ color: '#82BCA4' }}
                  title="Approved Days"
                >
                  AD
                </th>
                <th
                  className="ps-3"
                  style={{ color: '#A886DE' }}
                  title="Rejected Days"
                >
                  RD
                </th>
                <th
                  className="ps-3"
                  style={{ color: '#F1C682' }}
                  title="Pending Days"
                >
                  PD
                </th>
                <th
                  className="ps-3"
                  style={{ color: '#A0A1A8' }}
                  title="Missed Days"
                >
                  MD
                </th>
              </tr>
            </thead>

            <tbody className="">
              {empList.length <= 0 && (
                <tr>
                  <td colSpan={100} className="text-center">
                    {NORECORD}
                  </td>
                </tr>
              )}
              {empList.map((item, index) => (
                <tr className={`${style.tablebody}`} key={index}>
                  <td>
                    <span>
                      <TmTableImage
                        itm={item.employee_name}
                        profilePicture={item?.profilePicture}
                      />
                    </span>
                  </td>

                  <td className="ps-3">{item.approvedDays}</td>
                  <td className="ps-3">{item.rejectedDays}</td>
                  <td className="ps-3">{item.pendingDays}</td>
                  <td className="ps-3">{item.missedDays}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;
