import React from 'react';
import { Drawer } from 'antd';
const TmDrawer = ({ drawerbody, title, open, toggleDrawer }) => {
  return (
    <>
      <Drawer
        title={title}
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        open={open}
        closeIcon
        key={'right'}
        // headerStyle={{background:'#DBE2E6'}}
      >
        {drawerbody}
      </Drawer>
    </>
  );
};

export default TmDrawer;
