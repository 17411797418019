import { Dropdown } from 'antd';
import React from 'react';

const ListMembers = ({ members }) => {
  return (
    <>
      <div className="d-flex align-items-center ">
        <div className=" d-flex align-items-center ">
          {members.map(
            (element, indx) =>
              (indx === 0 || indx === 1) && (
                <span
                  key={indx + 99}
                  className=" alert alert-primary mb-0 p-1 py-0 me-1 d-inline-block"
                  title={element}
                  style={{
                    width: `${members?.length === 1 ? 'auto' : '48px'}`,
                    maxWidth: `100px`,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                  }}
                >
                  {element}
                </span>
              ),
          )}
        </div>
        <div>
          {members.length > 2 && (
            <Dropdown
              onClick={(e) => e.stopPropagation()}
              menu={{
                items: members.map((item, indx) => ({
                  key: indx,
                  label: (
                    <div
                      className=""
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {item}
                    </div>
                  ),
                })),
              }}
              placement="bottomRight"
              arrow
            >
              <span className="text-decoration-underline text-primary tmPointer">
                See More
              </span>
            </Dropdown>
          )}
        </div>
        {members.length <= 0 && '--'}
      </div>
    </>
  );
};

export default ListMembers;
