import React, { Fragment, useEffect, useState } from 'react';
import style from '../../Styles/ViewProfie.module.css';
import { Leave } from '../../Api/LeaveApi';
import { toastError, toastSuccess, toastWarn } from '../Toast/ToastComponent';
import { NORECORD } from '../../constant/constant';
import { Table } from 'react-bootstrap';

const HolidayListsComponent = ({ Year, dataList }) => {
  return (
    <Fragment>
      <div className="">
        <div className={`table-Responsive fw-bold ${style.twelve} rounded mb-2`}>{Year}</div>

        <Table responsive  className={`table  ${style.holy} custom-table rounded border`}>
          <tbody>
            {dataList.length > 0 &&
              dataList.map((item, i) => (
                <tr className="tr" key={item.i}>
                  <td className="ps-4">{item.date_of_Holiday}</td>
                  <td className="fw-bold pe-5">{item.day_of_holiday}</td>
                  <td className="fw-bold ps-4">{item.holiday_name}</td>
                </tr>
              ))}
            {dataList.length <= 0 && (
              <div className="text-center">{NORECORD}</div>
            )}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default HolidayListsComponent;
