import React from "react";
import { Card } from "reactstrap";
import noDataFound from '../../Assets/No Data found.png'
const NoDataFound = () => {
  return (
    <Card>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="rounded-1 p-4">
            <img src={noDataFound} alt="" className="width height rounded" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default NoDataFound;
