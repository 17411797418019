import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber } from 'antd';
import style from '../../../Styles/Attendance.module.css';
import { Input } from 'antd';
import { Table } from 'reactstrap';
import { PlusOutlined } from '@ant-design/icons';
import { GiConsoleController } from 'react-icons/gi';
import { NORECORD, PaginationRange } from '../../../constant/constant';
import TmPagination from '../../ReusableComponents/Pagination';

const columns = [
  {
    feildname: 'serialNumber',
    className: 'col-1',
    headerName: 'S. NO',
    title: '',
  },
  {
    feildname: 'employeeCode',
    className: 'col-3 text-nowrap',
    headerName: 'EMPLOYEE ID',
    title: 'EMPLOYEE ID',
  },
  {
    feildname: 'username',
    className: 'col-3 text-nowrap',
    headerName: 'EMPLOYEE NAME',
    title: 'EMPLOYEE NAME',
  },
  {
    feildname: 'date_of_joining',
    className: 'col-3 text-nowrap',
    headerName: 'DATE OF JOINING',
    title: 'DATE OF JOINING',
  },
  {
    feildname: 'department_name',
    className: 'col-2 text-nowrap',
    headerName: 'DESIGNATION',
    title: 'DESIGNATION',
  },
  {
    feildname: 'designation_name',
    className: 'col-2 text-nowrap ',
    headerName: 'DEPARTMENT',
    title: 'DEPARTMENT',
  },
  {
    feildname: 'weekOffDays',
    className: 'col-1',
    headerName: 'WEEK OFF',
    title: '',
  },
  {
    feildname: 'presentDays',
    className: 'col-1',
    headerName: 'PRESENT',
    title: '',
  },

  {
    feildname: 'publicHolidays',
    className: 'col-1',
    headerName: 'PUBLIC HOLIDAYS',
    title: '',
  },

  // { feildname: 'fh', className: 'col-1', headerName: 'FH', title: '' },
  // { feildname: 'nh', className: 'col-1', headerName: 'NH', title: '' },

  {
    feildname: 'compOffDays',
    className: 'col-1',
    headerName: 'COMPENSATARY OFF',
    title: '',
  },
  {
    feildname: 'onDutyDays',
    className: 'col-1',
    headerName: 'ON DUTY',
    title: '',
  },
  {
    feildname: 'extraWorkingHours',
    className: 'col-1',
    headerName: 'EXTRA WORKING',
    title: '',
  },
  {
    feildname: 'casualLeave',
    className: 'col-1',
    headerName: 'CASUAL LEAVE',
    title: '',
  },
  {
    feildname: 'sickLeave',
    className: 'col-1',
    headerName: 'SICK LEAVE',
    title: '',
  },
  {
    feildname: 'maternityLeave',
    className: 'col-1',
    headerName: 'MATERNITY LEAVE',
    title: '',
  },

  {
    feildname: 'paternityLeave',
    className: 'col-1',
    headerName: 'PATERNITY LEAVE',
    title: '',
  },
  {
    feildname: 'marriageLeave',
    className: 'col-1 text-nowrap',
    headerName: 'MARRIAGE LEAVE',
    title: '',
  },

  { feildname: 'LOPdays', className: 'col-1', headerName: 'LOP', title: '' },
  {
    feildname: 'noMoment',
    className: 'col-1',
    headerName: 'NMD',
    title: '',
  },
  {
    feildname: 'pending',
    className: 'col-1',
    headerName: 'PENDING',
    title: '',
  },
  {
    feildname: 'rejected',
    className: 'col-1',
    headerName: 'REJECTED',
    title: '',
  },
  {
    feildname: 'daysMissed',
    className: 'col-1',
    headerName: 'DAYS MISSED',
    title: '',
  },
  { feildname: 'total', className: 'col-1', headerName: 'TOTAL', title: '' },
  {
    feildname: 'Remarks',
    className: 'col-3',
    headerName: 'REMARKS',
    title: '',
  },
];

const AttendanceTable = ({ report, form }) => {
  const [paginationIndex, setPaginationIndex] = useState({ page: 1 });

  const handleTotal = async (index, type) => {
    let setTotalVal = 0;
    let reportsData = form.getFieldValue('report');
    let report = reportsData[index] || {};

    const ADDARR = [
      'weekOffDays',
      'presentDays',
      'publicHolidays',
      'compOffDays',
      'onDutyDays',
      'extraWorkingHours',
      'casualLeave',
      'sickLeave',
      'maternityLeave',
      'paternityLeave',
      'marriageLeave',
      'LOPdays',
      'noMoment',
      'daysMissed',
      'pending',
      'rejected',
    ];
    for (let obj of ADDARR) {
      setTotalVal += report[obj];
    }
    form.setFieldValue(['report', index, 'total'], setTotalVal);
  };

  const handlePagination = async (data, current = []) => {
    setPaginationIndex((pre) => ({ ...pre, page: data }));

    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : report;
    form.setFieldsValue({
      report: check.filter(
        (_, index) => index <= endRange && index >= startRange,
      ),
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, report);
  };
  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(paginationIndex.page, report);
  }, [report]);

  return (
    <>
      <div className={`${style.attendanceTable}`}>
        {
          <Table responsive bordered className="table">
            <thead>
              <tr>
                {columns.map((item, index) => (
                  <th key={index} className={`${item.className} text-nowrap`}>
                    {item.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <Form.List name="report">
                {(fields, { add, remove }) => (
                  <>
                    {fields.length > 0 &&
                      fields.map(({ key, name, ...restField }, index) => (
                        <tr key={key}>
                          <td>
                            <span>
                              {form?.getFieldValue('report')[index]
                                ?.serialNumber ?? ''}
                            </span>
                          </td>
                          <td>
                            <span
                              title={
                                form?.getFieldValue('report')[index]
                                  ?.employeeCode ?? ''
                              }
                            >
                              {form?.getFieldValue('report')[index]
                                ?.employeeCode ?? ''}
                            </span>
                          </td>
                          <td>
                            <span
                              title={
                                form?.getFieldValue('report')[index]
                                  ?.username ?? ''
                              }
                            >
                              {form?.getFieldValue('report')[index]?.username ??
                                ''}
                            </span>
                          </td>
                          <td>
                            <span
                              title={
                                form?.getFieldValue('report')[index]
                                  ?.date_of_joining ?? ''
                              }
                            >
                              {form?.getFieldValue('report')[index]
                                ?.date_of_joining ?? ''}
                            </span>
                          </td>
                          <td>
                            <span
                              title={
                                form?.getFieldValue('report')[index]
                                  ?.department_name ?? ''
                              }
                            >
                              {form?.getFieldValue('report')[index]
                                ?.department_name ?? ''}
                            </span>
                          </td>
                          <td>
                            <span
                              title={
                                form?.getFieldValue('report')[index]
                                  ?.designation_name ?? ''
                              }
                            >
                              {form?.getFieldValue('report')[index]
                                ?.designation_name ?? ''}
                            </span>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'weekOffDays']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input disabled={true} type="number" min={0} />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'presentDays']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input disabled={true} type="number" min={0} />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'publicHolidays']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input disabled={true} type="number" min={0} />
                            </Form.Item>
                          </td>
                          {/* <td>
                        <Form.Item
                          name={[name, 'fh']}
                          style={{ marginBottom: 0 }}
                        >
                          <Input type="number" min={0} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={[name, 'nh']}
                          style={{ marginBottom: 0 }}
                        >
                          <Input type="number" min={0} />
                        </Form.Item>
                      </td> */}
                          <td>
                            <Form.Item
                              name={[name, 'compOffDays']}
                              style={{ marginBottom: 0, width: '100%' }}
                            >
                              <InputNumber
                                onChange={(value) =>
                                  handleTotal(index, 'compOffDays')
                                }
                                style={{ width: '100%' }}
                                type="number"
                                min={0}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'onDutyDays']}
                              style={{ marginBottom: 0 }}
                            >
                              <InputNumber
                                onChange={(value) =>
                                  handleTotal(index, 'onDutyDays')
                                }
                                type="number"
                                min={0}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'extraWorkingHours']}
                              style={{ marginBottom: 0 }}
                            >
                              <InputNumber
                                style={{ width: '100%' }}
                                onChange={(value) =>
                                  handleTotal(index, 'extraWorkingHours')
                                }
                                type="number"
                                min={0}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'casualLeave']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input disabled={true} type="number" min={0} />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'sickLeave']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input disabled={true} type="number" min={0} />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'maternityLeave']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input disabled={true} type="number" min={0} />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'paternityLeave']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input disabled={true} type="number" min={0} />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'marriageLeave']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input disabled={true} type="number" min={0} />
                            </Form.Item>
                          </td>

                          <td>
                            <Form.Item
                              name={[name, 'LOPdays']}
                              style={{ marginBottom: 0 }}
                            >
                              <InputNumber
                                onChange={(value) =>
                                  handleTotal(index, 'LOPdays')
                                }
                                type="number"
                                min={0}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'noMoment']}
                              style={{ marginBottom: 0 }}
                            >
                              <InputNumber
                                onChange={(value) =>
                                  handleTotal(index, 'noMoment')
                                }
                                type="text"
                                min={0}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'pending']}
                              style={{ marginBottom: 0 }}
                            >
                              <InputNumber
                                onChange={(value) =>
                                  handleTotal(index, 'pending')
                                }
                                type="text"
                                min={0}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'rejected']}
                              style={{ marginBottom: 0 }}
                            >
                              <InputNumber
                                onChange={(value) =>
                                  handleTotal(index, 'rejected')
                                }
                                type="text"
                                min={0}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'daysMissed']}
                              style={{ marginBottom: 0 }}
                            >
                              <InputNumber
                                onChange={(value) =>
                                  handleTotal(index, 'daysMissed')
                                }
                                type="text"
                                min={0}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, 'total']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input readOnly type="number" min={0} />
                            </Form.Item>
                          </td>

                          <td>
                            <Form.Item
                              name={[name, 'Remarks']}
                              style={{ marginBottom: 0 }}
                            >
                              <Input type="text" min={0} />
                            </Form.Item>
                          </td>
                        </tr>
                      ))}
                    {fields.length <= 0 && (
                      <tr>
                        <td colSpan={100} className="text-center">
                          {NORECORD}
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                  <td colSpan={100} className="presentDays-1">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="col-5">
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button
                            type="default"
                            onClick={() => handleAdd(add)}
                            icon={<PlusOutlined />}
                          >
                            Add Log
                          </Button>
                        </Form.Item>
                      </div>
                      <div className="col-3"></div>
                    </div>
                  </td>
                </tr> */}
                  </>
                )}
              </Form.List>
            </tbody>
          </Table>
        }
      </div>
      <div className="mt-3">
        <TmPagination
          defaultCurrent={paginationIndex.page}
          total={report.length}
          handlePagination={handlePagination}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </>
  );
};

export default AttendanceTable;
