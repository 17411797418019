import TimeSheet from '../../Components/TimeSheet/MyTimeSheet/TimeSheet';

function TimeSheetPage() {
  return (
    <>
      <TimeSheet />
    </>
  );
}

export default TimeSheetPage;
