import React from 'react';
import '../../Styles/Login.css';
import LoginApi from '../../Api/LoginApi';
import { toastError, toastInfo, toastSuccess } from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { setSessionTime } from '../../Context/AuthSessionStorage';
import expandedImage from '../../Assets/Openwave Computing_Logo_Large.png';

function LoginForm({ handleToggle }) {
  const { login } = useAuth();
  const [form] = Form.useForm();

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {/* {required && <Mandatory />} */}
    </>
  );

  const handleLogin = async (reqData) => {
    try {
      let response = await LoginApi.login(reqData);
      if (response?.data?.success && response.data.result) {
        toastSuccess(response.data.message);
        await setSessionTime();
        await login(response.data.result);
      } else if (response?.data?.success) {
        toastInfo(response?.data?.message);
        return;
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  return (
    <div className="d-flex flex-column m-0 justify-content-center align-item-center">
      <div className="text-center ">
        <div style={{ marginBottom: 35 }}>
          {/* id="log_img" alt="poster.png" */}
          <img src={expandedImage} width={200} className="" />
        </div>
        <h4 className="fw-bold">LOGIN</h4>
      </div>
      <div className="mt-4">
        <div className="row justify-content-center">
          <div className="col col-md-8">
            <Form
              name="basic"
              form={form}
              requiredMark={customizeRequiredMark}
              layout="vertical"
              onFinish={handleLogin}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                ]}
              >
                <Input type="email" size="large" placeholder="Enter Email" />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 35 }}
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Please enter your password' },
                ]}
              >
                <Input.Password size="large" placeholder="Enter Password" />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }} style={{ marginBottom: 7 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: '100%' }}
                >
                  Login
                </Button>
              </Form.Item>
              <div className="text-end">
                <Link className="linkColor" onClick={handleToggle}>
                  Forgot Password?
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
