import React from 'react';
import ResetPassword from '../../Components/Login/ResetPassword';
import LoginImage from '../../Assets/LoginImage.png';
import { Spin } from 'antd';
const ResetPasswordPage = () => {
  return (
    <section className="login_main">
      <div id="tmoverlay" className="d-none">
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      </div>
      <div className="container-fluid p-0">
        <div
          className="row m-0 justify-content-center align-items-center"
          id="main_container"
        >
          <div
            className="col-md-6  d-none d-lg-flex align-items-center justify-content-center py-5"
            id="poster"
          >
            <img src={LoginImage} id="log_img" alt="poster.png" className="" />
          </div>

          <div className="col-md-6 col-sm-12-border justify-content-center">
            <ResetPassword />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordPage;
