import React, { Fragment, useEffect, useState } from 'react';

import { Table } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { NORECORD, PaginationRange } from '../../constant/constant';
import TmPagination from '../ReusableComponents/Pagination';

import ShowStatus from '../ReusableComponents/ShowStatus';
import { IoEyeOutline } from 'react-icons/io5';
import { Button, Checkbox, Dropdown } from 'antd';
import { collectKeyValueInArr } from '../../ReusableFn/reusableFn';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdLogIn } from 'react-icons/io';
import { useAuth } from '../../Context/AuthContext';
import TmTableImage from '../ReusableComponents/TmTableImage';

const UserManagementTable = ({
  rows,
  columns,
  handleClick,
  buttonPermission,
  sendCredentails,
  checkId,
  setCheckId,
}) => {
  const { authState } = useAuth();
  const inputHeader = ['action', 'checkbox'];
  const [pagination, setPagination] = useState([]);

  const [adminId, setAdminID] = useState(0);

  useEffect(() => {
    // console.log("authState",authState);
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setCheckId(() => []);

    let ArrayData = check.filter(
      (_, index) => index <= endRange && index >= startRange,
    );
    setPagination(ArrayData);

    let isAdmin = ArrayData?.filter(
      (element) => element?.id == 1 || element?.id == 2,
    );

    setAdminID(isAdmin?.length);
  };

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };

  const handleCheckbox = (e, id = -1) => {
    e.stopPropagation();

    let blockArr = checkId;
    let checked = e.target.checked;
    if (id === -1 && checked == true) {
      let idsArr = collectKeyValueInArr(pagination, 'id') || [];
      blockArr = idsArr;
    } else if (id === -1 && checked == false) {
      blockArr = [];
    } else if (id !== -1 && checked == true) {
      blockArr.push(id);
    } else if (id !== -1 && checked == false) {
      let filtered = blockArr.filter((item) => item !== id) || [];
      blockArr = filtered;
    }

    blockArr = blockArr.filter((element) => element !== 1 && element !== 2);

    setCheckId([...blockArr]);
  };

  const showActionMenu = (row) => {
    const editMenu = {
      key: '2',
      funcType: 'edit',
      label: (
        <span>
          <button
            className="btn m-0 p-0 w-100 d-flex align-items-center icon-hover"
            title="Edit"
            style={{ fontSize: '14px' }}
            onClick={(e) => {
              handleClick(row?.id, 'edit', e);
            }}
          >
            <span className="pb-1 pe-1">{<FiEdit size={15} />}</span>
            <span>Edit</span>
          </button>
        </span>
      ),
    };
    const viewMenu = {
      key: '1',
      funcType: 'view',
      label: (
        <span>
          <button
            title="View"
            style={{ fontSize: '14px' }}
            onClick={(e) => {
              handleClick(row?.id, 'View');
            }}
            className="btn d-flex w-100 align-items-center m-0  p-0 icon-hover"
          >
            <span className="pb-1 pe-1">{<IoEyeOutline size={17} />}</span>{' '}
            <span>View</span>
          </button>
        </span>
      ),
    };
    const deleteMenu = {
      key: '3',
      funcType: 'delete',
      label: (
        <span>
          <button
            title="Delete"
            style={{ fontSize: '14px' }}
            onClick={(e) => {
              handleClick(row?.id, 'delete', e);
            }}
            className="btn m-0 p-0 d-flex w-100  align-items-center icon-hover"
          >
            <span className=" pb-1">{<MdDeleteOutline size={18} />} </span>
            <span className="">Delete</span>
          </button>
        </span>
      ),
    };
    const loginAsAdmin = {
      key: '4',
      label: (
        <span>
          <button
            title={`Login as ${row?.employee_name}`}
            style={{ fontSize: '14px' }}
            onClick={(e) => {
              handleClick(row, 'login', e);
            }}
            className="btn m-0 p-0 d-flex  align-items-center icon-hover"
          >
            <span className="pb-1">
              {<IoMdLogIn size={18} />}
              {'  '}
            </span>
            <span className="ps-1">Login as {row?.employee_name}</span>
          </button>
        </span>
      ),
    };
    let allAvailableMenu = [];
    // let allAvailableMenu = [viewMenu, editMenu, deleteMenu];

    if (row?.id !== 1 && row?.id !== 2) {
      allAvailableMenu = [viewMenu, editMenu, deleteMenu];
    } else if (
      (row?.id === 1 || row?.id === 2) &&
      (authState?.userRoleID == 1 || authState?.userRoleID == 2)
    ) {
      allAvailableMenu = [viewMenu, editMenu];
    }
    let filteredMenu = allAvailableMenu.filter((item) => {
      let findRequiredBtn =
        buttonPermission === undefined || buttonPermission[item.funcType];
      //  &&
      // row?.id !== 1 &&
      // row?.id !== 2;
      if (findRequiredBtn) {
        delete item?.funcType;
        return item;
      }
    });
    if (
      (authState?.userRoleID == 1 || authState?.userRoleID == 2) &&
      row.id !== 1 &&
      row.id !== 2
    ) {
      filteredMenu = [...filteredMenu, loginAsAdmin];
    }
    return filteredMenu || [];
  };

  return (
    <div className="icon-btn">
      <Table className="border table-header-custom" responsive>
        <thead>
          <tr>
            {columns.map((column) => (
              <Fragment key={column.field}>
                {column.field === 'checkbox' && (
                  <th className={`${column.className}`} key={column.field}>
                    <Checkbox
                      checked={
                        checkId.length == pagination.length - adminId &&
                        pagination.length != 0 &&
                        checkId.length != 0
                      }
                      onClick={(e) => handleCheckbox(e, -1)}
                    />
                  </th>
                )}
                {column.headerName !== 'ACTION' &&
                  column.field !== 'checkbox' && (
                    <th className={`${column.className}`} key={column.field}>
                      {column.headerName}
                    </th>
                  )}

                {column.headerName === 'ACTION' &&
                  (buttonPermission == undefined ||
                    buttonPermission?.edit ||
                    buttonPermission?.delete ||
                    buttonPermission?.view) && (
                    <th className={`${column.className}`} key={column.field}>
                      {column.headerName}
                    </th>
                  )}
              </Fragment>
              // <>
              //   (column.headerName !== 'ACTION' ? (
              //   <th className={`${column.className}`} key={column.field}>
              //     {column.headerName}
              //   </th>
              //   ) : ( (buttonPermission == undefined || buttonPermission?.edit
              //   || buttonPermission?.delete || buttonPermission?.view  ) && (
              //   <th className={`${column.className}`} key={column.field}>
              //     {column.headerName}
              //   </th>
              //   ) ))
              // </>
            ))}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((row, idx) => (
              <tr
                key={22 + idx}
                onClick={(e) => {
                  handleClick(row.id, 'View');
                }}
                className="tmPointer hoverTr"
              >
                <td
                  title={row?.Select}
                  onClick={(e) => handleCheckbox(e, row.id)}
                >
                  {![1, 2].includes(row.id) && (
                    <Checkbox checked={checkId.includes(row.id)} />
                  )}
                </td>

                <td title={row?.serialNo}>{[row.serialNo]}</td>
                <td title={row?.employee_name}>
                  <span>
                    <TmTableImage
                      itm={row?.employee_name}
                      profilePicture={row?.profilePicture}
                    />
                  </span>
                  
                </td>
                <td title={row?.employee_code}>{row?.employee_code || '--'}</td>
                <td title={row?.email}>{row?.email || '--'}</td>
                <td title={row?.userRole_name}>{row?.userRole_name || '--'}</td>
                <td title={row?.department_name}>
                  {row?.department_name || '--'}
                </td>
                <td title={row?.designation_name}>
                  {row?.designation_name || '--'}
                </td>
                <td title={row?.first_approvers}>
                  {row?.first_approvers || '--'}
                </td>
                <td title={row?.second_approvers}>
                  {row?.second_approvers || '--'}
                </td>

                <td>
                  {/* {row?.status ? (
                    <span className="status-completed">Active</span>
                  ) : (
                    <span className="status-overdue">InActive</span>
                  )} */}
                  <ShowStatus status={row?.status ? 'working' : 'notworking'} />

                  {/* {<ShowStatus status={row?.status} />} */}
                </td>

                {(buttonPermission == undefined ||
                  buttonPermission?.edit ||
                  buttonPermission?.delete ||
                  buttonPermission?.view) && (
                  <td
                    className="text-center "
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* <span>
                      {(buttonPermission === undefined ||
                        buttonPermission?.view) &&
                        row?.id !== 1 &&
                        row?.id !== 2 && (
                          <button
                            title="View"
                            onClick={(e) => {
                              handleClick(row.id, 'View');
                            }}
                            className="btn m-0  p-0 icon-hover"
                          >
                            {<IoEyeOutline size={18} />}
                          </button>
                        )}
                    </span>
                    <span>
                      {(buttonPermission === undefined ||
                        buttonPermission?.edit) &&
                        row?.id !== 1 &&
                        row?.id !== 2 && (
                          <button
                            className="btn  m-0 p-0  icon-hover"
                            title="Edit"
                            onClick={(e) => {
                              handleClick(row.id, 'edit', e);
                            }}
                          >
                            {<FiEdit />}
                          </button>
                        )}
                    </span>
                    <span>
                      {(buttonPermission === undefined ||
                        buttonPermission?.delete) &&
                        row?.id !== 1 &&
                        row?.id !== 2 && (
                          <button
                            title="Delete"
                            onClick={(e) => {
                              handleClick(row.id, 'delete', e);
                            }}
                            className="btn m-0 p-0 icon-hover"
                          >
                            {<MdDeleteOutline size={18} />}
                          </button>
                        )}
                    </span> */}

                    {showActionMenu(row).length > 0 && (
                      <Dropdown
                        menu={{
                          items: showActionMenu(row) || [],
                        }}
                        placement="bottomRight"
                        arrow
                      >
                        <Button
                          type="text"
                          htmlType="button"
                          icon={<BsThreeDotsVertical />}
                        ></Button>
                      </Dropdown>
                    )}
                    {/* {row?.id !== 1 && row?.id !== 2 && (
                      <Dropdown
                        menu={{
                          items: showActionMenu(row) || [],
                        }}
                        placement="bottomRight"
                        arrow
                      >
                        <Button
                          type="text"
                          htmlType="button"
                          icon={<BsThreeDotsVertical />}
                        ></Button>
                      </Dropdown>
                    )} */}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      <div>
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default UserManagementTable;
