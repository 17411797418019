import React from 'react';
import { Button, Modal } from 'antd';
import { MODAL_HEIGHT } from '../../constant/constant';

const AntdModalComp = ({
  closable = true,
  maskClosable = true,
  width = 'auto',
  modalOpen,
  toggleModal,
  modalHeader,
  modalBody,
  height,
}) => {
  return (
    <>
      <Modal
        centered
        width={width}
        title={modalHeader}
        open={modalOpen}
        onCancel={toggleModal}
        destroyOnClose={true}
        maskClosable={maskClosable}
        footer={<></>}
        closable={closable}
        style={{ minWidth: 300 }}
      >
        <div
          style={{
            height: `${height || MODAL_HEIGHT.auto}`,
            maxHeight: '650px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {modalBody}
        </div>
      </Modal>
    </>
  );
};

export default AntdModalComp;
