import React from 'react';

import UserReport from '../../Components/Reports/DepartmentReport/UserReport';

const UserReportPage = () => {
  return (
    <>
      <UserReport />
    </>
  );
};

export default UserReportPage;
