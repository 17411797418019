import { notification } from "antd";

notification.config({
  placement: "topRight",
  bottom: 50,
  duration: 3,
});
// const openNotification = (data, type) => {
//   let caseChange = `${type}`.toLowerCase();
//   notification[caseChange]({
//     message: <b>{type}</b>,
//     description: data.msg,
//     style: {
//       width: 300,
//       background: data.color,
//       borderRadius: 6,
//     },
//   });
// };
export const toastWarn = (msg) => {
  // return message.warning(msg);
  // openNotification({ msg, color: "#fde1b5" }, "Warning");
   notification.warning({
     message: <b>{"Warning"}</b>,
     description: msg,
     style: {
       width: 300,
       background: "#fde1b5",
       borderRadius: 6,
     },
   });
};

export const toastSuccess = (msg) => {
  // return message.success(msg);
 // openNotification({ msg, color: "#c2f0c2" }, "Success");
  notification.success({
    message: <b>{"Success"}</b>,
    description: msg,
    style: {
      width: 300,
      background: "#c2f0c2",
      borderRadius: 6,
    },
  });
};

export const toastInfo = (msg) => {
  // return message.info(msg);
  // openNotification({ msg, color: "#b3d1ff" }, "Info");
    notification.info({
      message: <b>{"Info"}</b>,
      description: msg,
      style: {
        width: 300,
        background: "#b3d1ff",
        borderRadius: 6,
      },
    });
};

export const toastError = (msg) => {
  // return message.error(msg);
 // openNotification({ msg, color: "#ffb3b3" }, "Error");
      notification.error({
        message: <b>{"Error"}</b>,
        description: msg,
        style: {
          width: 300,
          background: "#ffb3b3",
          borderRadius: 6,
        },
      });
};
