import axiosClient from './axiosClient';

export const TimeSheetApi = {
  getProjectDropDown: (data) => {
    const url = `/api/project/userProject`;
    return axiosClient.post(url, data);
  },
  getModuleDropDown: (data) => {
    const url = `/api/module/userModule`;
    return axiosClient.post(url, data);
  },
  create: (data) => {
    const url = `/api/timesheet/create`;
    return axiosClient.post(url, data);
  },
  update: (data) => {
    const url = `/api/timesheet/edit`;
    return axiosClient.post(url, data);
  },
  getUserLogs: (data) => {
    const url = `/api/timesheet/get`;
    return axiosClient.post(url, data);
  },
  getLogData: (id) => {
    const url = `/api/timesheet/get/${id}`;
    return axiosClient.post(url);
  },
  userView: (data) => {
    const url = `/api/timesheet/getUsers`;
    return axiosClient.post(url, data);
  },
  approveUpdate: (data) => {
    const url = `/api/timesheet/edit/handleapprovers`;
    return axiosClient.post(url, data);
  },
  checkHoliday: (data) => {
    const url = `/api/timesheet/checkHoliday`;
    return axiosClient.post(url, data);
  },
  updateUnlock: (data) => {
    const url = `/api/timesheet/unlockTimesheet`;
    return axiosClient.post(url, data);
  },
  downloadData: (data) => {
    const url = `/api/timesheet/getFormat`;
    return axiosClient.post(url, data);
  },

  multiApprove: (data) => {
    const url = `/api/timesheet/multiApprove`;
    return axiosClient.post(url, data);
  },
  unlockAll: (data) => {
    const url = `/api/timesheet/unlockAll`;
    return axiosClient.post(url, data);
  },
  setBillable: (data) => {
    const url = `/api/timesheet/setBillable`;
    return axiosClient.post(url, data);
  },
  checkAppliedPermission: (data) => {
    const url = `/api/project/userProject`;
    return axiosClient.post(url, { id: 1, userRoleID: 1 });
  },
};
