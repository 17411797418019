import { Modal } from 'antd';

export const DeletePopup = (deleteIt) => {
  Modal.confirm({
    title: 'Confirm Delete',
    content: 'Are you sure you want to delete ?',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: () => deleteIt(true),
    onCancel: () => deleteIt(false),
  });
};

export const ApprovePopUp = (deleteIt) => {
  Modal.confirm({
    title: 'Confirm Action',
    content: 'Are you sure you want to proceed with this timesheet?',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: () => deleteIt(true),
    onCancel: () => deleteIt(false),
  });
};

export const UserRoleChangePopUp = (deleteIt, label) => {
  Modal.confirm({
    title: 'Confirm Change',
    content: `Are you sure? Changing the role to/from '${label}' will remove the user from their corresponding module and project.`,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: () => deleteIt(true),
    onCancel: () => deleteIt(false),
  });
};

export const DeleteApprovedPopup = (deleteIt) => {
  Modal.confirm({
    title: 'Confirm Delete',
    content:
      'Are you sure you want to delete? Deleting approved leave will also delete the corresponding timesheet data.',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: () => deleteIt(true),
    onCancel: () => deleteIt(false),
  });
};

export const projectCardDelete = (deleteIt, status) => {
  if (status) {
    Modal.confirm({
      title: 'Confirm Active',
      content: 'Are you sure you want to activate this project?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteIt(true),
      onCancel: () => deleteIt(false),
    });
  } else {
    Modal.confirm({
      title: 'Confirm Inactive',
      content: 'Are you sure you want to inactivate this project?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteIt(true),
      onCancel: () => deleteIt(false),
    });
  }
};

export const confirmationPopup = (deleteIt, label) => {
  Modal.confirm({
    title: label.title,
    content: label.content,
    okText: label.okText,
    okType: label.okType,
    okButtonProps: {
      className: 'custom-ok-button',
      style: { backgroundColor: '#134780', borderRadius: 3 },
    },
    cancelButtonProps: {
      className: 'custom-cancel-button',
      style: { backgroundColor: '#EEEEEE', borderRadius: 3 },
    },
    cancelText: label.cancelText,
    onOk: () => deleteIt(true),
    onCancel: () => deleteIt(false),
  });
};
