import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { act } from 'react';
import { Collapse, Experimental_CssVarsProvider } from '@mui/material';
import { Card, Col, Row } from 'react-bootstrap';
import { Checkbox, Input, Button, Switch } from 'antd';
import { RxCaretDown } from 'react-icons/rx';
import { Label } from 'reactstrap';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import UserRole from '../../Api/UserRole';
import { toastError, toastInfo, toastSuccess } from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { DOMAIN_HOST } from '../../config/appConfig';
import { MdExpand } from 'react-icons/md';
import { IoCaretDownCircleOutline } from 'react-icons/io5';
import style from '../../Styles/AddUser.module.css';
import { usePageAccess } from '../../helpers/handlePageAccess';
import { getUserRole } from '../../store/reducers/actionPermission';
import { useSelector } from 'react-redux';

const AddUserRole = () => {
  const { authState } = useAuth();
  const pageAccess = usePageAccess();
  const data = useSelector(getUserRole);

  const { edit_id } = useParams();
  const [user_permissions, setUserPermission] = useState([
    {
      label: 'Dashboard',
      checked: false,
      statename: 'dashboard',
      actions: [{ label: 'View', checked: false, statename: 'View' }],
    },
    {
      label: 'Time Sheet',
      checked: true,
      statename: 'timeSheet',
      children: [
        {
          label: 'My Timesheet',
          checked: true,
          statename: 'myTimesheet',
          actions: [
            { label: 'View', checked: true, statename: 'View' },
            { label: 'Add', checked: true, statename: 'Add' },
            { label: 'Edit', checked: true, statename: 'Edit' },
          ],
        },
        {
          label: 'Users Timesheet',
          checked: false,
          statename: 'usersTimeSheet',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Approve', checked: false, statename: 'Approve' },
            { label: 'Reject', checked: false, statename: 'Reject' },
            // { label: "Delete", checked: true, statename: "Delete" },
          ],
        },
      ],
    },
    {
      label: 'Leave Management',
      checked: false,
      statename: 'leaveManagements',
      children: [
        {
          label: 'My Requests',
          checked: false,
          statename: 'myRequest',
          actions: [
            { label: 'Apply', checked: false, statename: 'Apply' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
            { label: 'Comment', checked: false, statename: 'comment' },
          ],
        },
        {
          label: 'User Requests',
          checked: false,
          statename: 'userRequest',
          actions: [
            { label: 'Approve', checked: false, statename: 'Approve' },
            { label: 'Reject', checked: false, statename: 'Reject' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
            { label: 'Comment', checked: false, statename: 'comment' },
          ],
        },
      ],
    },
    // {
    //   label: 'Permission Management',
    //   checked: false,
    //   statename: 'permissionManagement',
    //   children: [
    //     {
    //       label: 'My Requests',
    //       checked: false,
    //       statename: 'myRequestPermission',
    //       actions: [
    //         { label: 'Apply', checked: false, statename: 'Apply' },
    //         { label: 'Edit', checked: false, statename: 'Edit' },
    //         { label: 'Delete', checked: false, statename: 'Delete' },
    //         { label: 'Comment', checked: false, statename: 'comment' },
    //       ],
    //     },
    //     {
    //       label: 'User Requests',
    //       checked: false,
    //       statename: 'userRequestPermission',
    //       actions: [
    //         { label: 'Approve', checked: false, statename: 'Approve' },
    //         { label: 'Reject', checked: false, statename: 'Reject' },
    //         { label: 'Edit', checked: false, statename: 'Edit' },
    //         { label: 'Delete', checked: false, statename: 'Delete' },
    //         { label: 'Comment', checked: false, statename: 'comment' },
    //       ],
    //     },
    //   ],
    // },
    {
      label: 'Projects',
      checked: false,
      statename: 'projects',
      children: [
        {
          label: 'Project',
          checked: false,
          statename: 'project',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
        {
          label: 'Module',
          checked: false,
          statename: 'module',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
        {
          label: 'Draft Module',
          checked: false,
          statename: 'draftModule',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
      ],
    },
    {
      label: 'User Management',
      checked: false,
      statename: 'userManagement',
      children: [
        {
          label: 'User',
          checked: false,
          statename: 'user',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
        {
          label: 'User Role',
          checked: false,
          statename: 'userRole',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
      ],
    },

    {
      label: 'Masters',
      checked: false,
      statename: 'master',
      children: [
        {
          label: 'Departments',
          checked: false,
          statename: 'department',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
        {
          label: 'Designation',
          checked: false,
          statename: 'designation',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
        {
          label: 'Public Holidays',
          checked: false,
          statename: 'leave',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
        {
          label: 'Email Notification',
          checked: false,
          statename: 'emailNotification',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Add', checked: false, statename: 'Add' },
            { label: 'Edit', checked: false, statename: 'Edit' },
            { label: 'Delete', checked: false, statename: 'Delete' },
          ],
        },
      ],
    },
    {
      label: 'Reports',
      checked: false,
      statename: 'report',
      children: [
        {
          label: 'Attendance Report',
          checked: false,
          statename: 'attendance',
          actions: [
            { label: 'View', checked: false, statename: 'View' },
            { label: 'Update', checked: false, statename: 'Edit' },
          ],
        },
        {
          label: 'Notification History',
          checked: false,
          statename: 'notificationHistory',
          actions: [{ label: 'View', checked: false, statename: 'View' }],
        },
        {
          label: 'Department Report',
          checked: false,
          statename: 'departmentReport',
          actions: [{ label: 'View', checked: false, statename: 'View' }],
        },
        {
          label: 'User Report',
          checked: false,
          statename: 'userReport',
          actions: [{ label: 'View', checked: false, statename: 'View' }],
        },
        // {
        //   label: 'Effort Variance',
        //   checked: false,
        //   statename: 'effortVariance',
        //   actions: [{ label: 'View', checked: false, statename: 'View' }],
        // },
      ],
    },
    {
      label: 'Settings',
      checked: false,
      statename: 'settings',
      actions: [
        { label: 'View', checked: false, statename: 'View' },
        { label: 'Update', checked: false, statename: 'Edit' },
      ],
    },
    {
      label: 'User Logs',
      checked: false,
      statename: 'userlog',
      actions: [{ label: 'View', checked: false, statename: 'View' }],
    },
  ]);

  const [roleName, setRoleName] = useState('');

  const [status, setStatus] = useState(true);

  const [selectAll, setSelectAll] = useState(false);

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState([]);

  const handleToggleAll = () => {
    if (expanded.length) {
      setExpanded([]);
    } else {
      let array = [];
      for (const [index, element] of user_permissions.entries()) {
        array.push(index);
        if (element?.children) {
          for (const [index2, element2] of element?.children.entries()) {
            array.push(`${index}-${index2}`);
          }
        }
      }
      setExpanded(array);
    }
  };

  const handleAccordionChange = (index) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded((prevExpanded) => [...prevExpanded, index]);
    } else {
      setExpanded((prevExpanded) =>
        prevExpanded.filter((item) => item !== index),
      );
    }
  };

  const handleChange = async (event, parentName) => {
    const { name } = event.target;

    let select = event.target.checked;

    const prevState = [...user_permissions];

    if (name == 'Select All') {
      for (const parentItem of prevState) {
        parentItem.checked = event.target.checked;
        if (parentItem?.statename == 'timeSheet') {
          parentItem.checked = true;
        }
        if (parentItem?.children) {
          for (const child of parentItem?.children) {
            child.checked = event.target.checked;
            if (child?.statename == 'myTimesheet') {
              child.checked = true;
            }
            for (const action of child?.actions) {
              action.checked = event.target.checked;
              if (child?.statename == 'myTimesheet') {
                action.checked = true;
              }
            }
          }
        } else {
          for (const action of parentItem?.actions) {
            action.checked = event.target.checked;
          }
        }
      }
    } else {
      select = false;
      for (const parentItem of prevState) {
        if (parentItem.statename == parentName) {
          if (parentItem.actions) {
            let check = [];
            parentItem.actions.forEach((action) => {
              if (action.statename == name) {
                action.checked = !action.checked;
                parentItem.checked = true;
              }
              check.push(action.checked);
            });
            let isFalse = check.includes(true);
            if (!isFalse) {
              parentItem.checked = false;
            }
            if (isFalse) {
              parentItem.actions.forEach((action) => {
                if (action.statename == 'View') {
                  action.checked = true;
                }
              });
            }
          } else if (parentItem.children) {
            parentItem.children.forEach((childItem) => {
              if (name == childItem.statename) {
                childItem.checked = !childItem.checked;
                childItem.actions.forEach((action) => {
                  action.checked = childItem.checked;
                  parentItem.checked = true;
                });
              }
            });
          }
        } else if (!parentName) {
          if (parentItem.statename == name && parentItem.children) {
            parentItem.checked = !parentItem.checked;
            parentItem.children.forEach((childItem) => {
              childItem.checked = parentItem.checked;
              childItem.actions.forEach((action) => {
                action.checked = parentItem.checked;
              });
            });
          } else if (parentItem.statename == name && parentItem.actions) {
            parentItem.checked = !parentItem.checked;
            parentItem.actions.forEach((action) => {
              action.checked = parentItem.checked;
            });
          }
        }
        if (parentItem.children) {
          let check = [];
          let check2 = [];
          parentItem.children.forEach((childItem) => {
            if (childItem.statename == parentName) {
              childItem.actions.forEach((action) => {
                if (action.statename == name) {
                  action.checked = !action.checked;
                  childItem.checked = true;
                  parentItem.checked = true;
                }
                check.push(action.checked);
              });
            }
            let isFalse = check.includes(true);
            if (!isFalse) {
              if (childItem.statename == parentName) {
                childItem.checked = false;
              }
            }
            if (isFalse) {
              if (childItem.statename == parentName) {
                childItem.actions.forEach((action) => {
                  if (action.statename == 'View') {
                    action.checked = true;
                  }
                });
              }
            }
            check2.push(childItem.checked);
          });

          let isFalse2 = check2.length ? check2.includes(true) : true;
          if (!isFalse2) {
            parentItem.checked = false;
          }
        }
      }
    }
    setSelectAll(select);
    setUserPermission(prevState);
    await getSelected(prevState);
  };

  const getSelected = async (Permissiondata) => {
    let array = [];
    for (const parentItem of Permissiondata) {
      array.push(parentItem.checked);
      if (parentItem?.children) {
        for (const child of parentItem?.children) {
          array.push(child.checked);
          for (const action of child?.actions) {
            array.push(action.checked);
          }
        }
      } else {
        for (const action of parentItem?.actions) {
          array.push(action.checked);
        }
      }
    }
    const isEvery = array.every((element) => element == true);
    if (isEvery) {
      setSelectAll(true);
    }
  };

  const handleSave = async () => {
    try {
      if (!roleName) {
        toastInfo('Role name is missing');
        return;
      }

      if (user_permissions.every((element) => !element.checked)) {
        toastInfo(
          'Permission is missing. Please select a permission to proceed.',
        );
        return;
      }

      const data = {
        userRole_name: roleName,
        status: status,
        created_By_ID: authState.id,
        user_permissions: JSON.stringify(user_permissions),
      };

      if (edit_id) {
        data.modified_By_ID = authState.id;
        delete data['created_By_ID'];
        data.id = edit_id;
      }

      const response = !edit_id
        ? await UserRole.create(data)
        : await UserRole.update(data);

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        navigate('/user_role');

        if (edit_id == authState?.userRoleID) {
          window.close();
          window.open(DOMAIN_HOST, '_self');
        }
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const getData = async () => {
    try {
      const response = await UserRole.getMenuPermission({ id: edit_id });
      if (response?.data?.success) {
        let Permissiondata = JSON.parse(response.data.result.user_permissions);
        setUserPermission(Permissiondata);
        setRoleName(response.data.result.userRole_name);
        setStatus(response.data.result.status);
        await getSelected(Permissiondata);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  useEffect(() => {
    if (edit_id) {
      getData();
    }
  }, [edit_id]);

  useEffect(() => {
    if (data) {
      pageAccess(data, edit_id);
    }
  }, [data]);

  const handleRoleName = (e) => {
    let value = e.target.value;
    if (value == ' ') {
      return;
    }
    value = value.replace(/\s\s+/g, ' ');
    setRoleName(value);
  };

  return (
    <>
      <div>
        <div className="d-flex  px-0 pb-1">
          <div
            className={`${style['arrow-icon']} border px-2 pb-1`}
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft />
          </div>
          <div className={`mx-3 ${style['add-user']}`}>
            {edit_id ? 'Edit User Role' : 'Add User Role'}
          </div>
        </div>

        <Card className="px-3 py-2">
          <Row>
            <Col sm="4">
              <div className="my-2 mb-4">
                <Label for="usertype">User Role Name</Label>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                <Input
                  placeholder="Enter Name"
                  value={roleName}
                  onChange={handleRoleName}
                />
              </div>
              <div>
                <div>
                  <Switch
                    value={status}
                    name="status"
                    onChange={(value) => setStatus(value)}
                  />
                  <span className="ps-2">Active</span>
                </div>
              </div>
            </Col>

            {/* <Col className="mt-3" sm="4"></Col> */}

            <div className="w-100 d-flex align-items-center mt-3 justify-content-between justify-content-md-start">
              <div className="d-flex w-25 align-items-center mb-2">
                <h6 className="mb-0">Permissions</h6>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                <Button
                  type="success"
                  onClick={handleToggleAll}
                  style={{ color: '#f8a013' }}
                  size="medium"
                  className="tm_button33 p-0 m-0 ms-2"
                  htmlType="button"
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      className={`d-inline-block arrow ${
                        expanded?.length ? 'rotateArrow' : ''
                      }`}
                    >
                      <IoCaretDownCircleOutline size={20} />
                    </span>
                    {/* {expanded?.length ? "Collapse All" : "Expand All"} */}
                  </div>
                </Button>
              </div>
              <div className="d-flex justify-content-between">
                <span>
                  <Checkbox
                    checked={selectAll}
                    name="Select All"
                    style={{ marginRight: 10 }}
                    onChange={handleChange}
                  />
                  Select All
                </span>
              </div>
            </div>
            <Col md={4}>
              {user_permissions.map((parentItem, parentIndex) => (
                <Accordion
                  key={parentIndex}
                  expanded={expanded.includes(parentIndex)}
                  onChange={handleAccordionChange(parentIndex)}
                >
                  <AccordionSummary>
                    <div className="w-100 d-flex justify-content-between">
                      <span>
                        <Checkbox
                          style={{ marginRight: 10 }}
                          name={parentItem.statename}
                          onChange={handleChange}
                          checked={parentItem?.checked}
                          disabled={parentItem?.statename == 'timeSheet'}
                        />
                        {parentItem.label}
                      </span>
                      <span className="carethandler">
                        <RxCaretDown size={20} />
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {parentItem.children?.map((childItem, childIndex) => (
                      <Accordion
                        key={childIndex}
                        expanded={expanded.includes(
                          `${parentIndex}-${childIndex}`,
                        )}
                        onChange={handleAccordionChange(
                          `${parentIndex}-${childIndex}`,
                        )}
                      >
                        <AccordionSummary>
                          <div className="w-100 d-flex justify-content-between">
                            <span>
                              <Checkbox
                                name={childItem.statename}
                                style={{ marginRight: 10 }}
                                checked={childItem.checked}
                                onChange={(e) =>
                                  handleChange(e, parentItem.statename)
                                }
                                disabled={childItem.statename == 'myTimesheet'}
                              />
                              {childItem.label}
                            </span>
                            <span className="carethandler">
                              <RxCaretDown size={20} />
                            </span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {childItem.actions?.map((actionItem, actionIndex) => (
                            <div
                              key={actionIndex}
                              className="rounded-1"
                              style={{
                                marginLeft: 25,
                                padding: '8px',
                                marginBottom: 3,
                                border: '1px solid #DADCDF',
                                borderRadius: '0.15rem',
                              }}
                            >
                              <div className="w-100 d-flex justify-content-between">
                                <span>
                                  <Checkbox
                                    style={{ marginRight: 10 }}
                                    name={actionItem.statename}
                                    checked={actionItem.checked}
                                    onChange={(e) =>
                                      handleChange(e, childItem.statename)
                                    }
                                    disabled={
                                      childItem.statename == 'myTimesheet'
                                    }
                                  />
                                  {actionItem.label}
                                </span>
                              </div>
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    {parentItem.actions?.map((actionItem, actionIndex) => (
                      <div
                        className="rounded-1"
                        key={actionIndex}
                        style={{
                          marginLeft: 25,
                          padding: '8px',
                          marginBottom: 3,
                          border: '1px solid #DADCDF',
                          borderRadius: '0.15rem',
                        }}
                      >
                        <div className="w-100 d-flex justify-content-between">
                          <span>
                            <Checkbox
                              name={actionItem.statename}
                              style={{ marginRight: 10 }}
                              type="checkbox"
                              checked={actionItem.checked}
                              onChange={(e) =>
                                handleChange(e, parentItem.statename)
                              }
                            />
                            {actionItem.label}
                          </span>
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Col>
          </Row>

          <div className="text-center mt-2 mt-md-0 antdBtn">
            <Button
              type="default"
              size="medium"
              htmlType="button"
              onClick={() => navigate('/user_role')}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="medium"
              htmlType="submit"
              onClick={handleSave}
            >
              {edit_id ? 'Update' : 'Save'}
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddUserRole;
