import axiosClient from './axiosClient';

const AttendanceReport = {
  get: (data) => {
    const url = `/api/attendance/get`;
    return axiosClient.post(url, data);
  },
  update: (data) => {
    const url = `/api/attendance/edit`;
    return axiosClient.post(url, data);
  },
  approveMonth: (data) => {
    const url = `/api/attendance/approveMonth`;
    return axiosClient.post(url, data);
  },

  //   dropDown: () => {
  //     const url = `/api/department/dropDown`;
  //     return axiosClient.post(url);
  //   },
  //   getDepartmentList: () => {
  //     const url = `/api/department/get`;
  //     return axiosClient.post(url);
  //   },
  //   create: (data) => {
  //     const url = `/api/department/create`;
  //     return axiosClient.post(url, data);
  //   },
  //   update: (data) => {
  //     const url = `/api/department/edit`;
  //     return axiosClient.post(url, data);
  //   },
  //   delete: (id) => {
  //     const url = `/api/department/delete`;
  //     return axiosClient.post(url, { id });
  //   },
};

export default AttendanceReport;
