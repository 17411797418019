import React from "react";
import DesignationComp from "../../Components/Designation/DesignationComp";

export const Designation = () => {
  return (
    <>
    <DesignationComp />
    </>
  );
};
