const pdfViewer = (dataurl) => {
  let pdfWindow = window.open('');
  pdfWindow.document.write(
    `<html<head><title>
        View Pdf
    </title><style>body{margin: 0px;}</style></head>`,
  );
  pdfWindow.document.write(
    `<body>
      <div>
        <embed width='100%' height='100%' src=${dataurl}></embed>
      </div>
    </body>
    </html>`,
  );
  pdfWindow.document.close();
  return;
};

export default pdfViewer;
